import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "primereact/checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import { ScrollPanel } from "primereact/scrollpanel";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function Traslados(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowTraslado, setSelectedRowTraslado] = useState(null);
  const [visibleModificar, setVisibleModificar] = useState(false);
  const [open, setOpen] = useState(false);

  const exportColumns = [
    { title: "Llegada", dataKey: "llegada" },
    { title: "Nombre", dataKey: "afiliadoNombre" },
    { title: "Domicilio", dataKey: "domicilioOrigen" },
    { title: "Destino", dataKey: "domicilioDestino" },
  ];

  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  //Context

  const serviciosContext = useContext(ServiciosContext);
  const {
    traslados,
    notas,
    direcciones,
    obtenerTraslados,
    guardarServicio,
    eliminarTrasladoProgramado,
    obtenerNotasPendientes,
    obtenerDirecciones,
    modificarTrasladoPendiente,
  } = serviciosContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { statusAcceso, obtenerAcceso } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Traslados" });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerTraslados();
  }, []);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 0);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputBlurOrigen = (event) => {
    obtenerDirecciones({
      domicilio:
        selectedRowTraslado.domicilioOrigen !== undefined
          ? selectedRowTraslado.domicilioOrigen.toLowerCase()
          : "",
      provincia:
      selectedRowTraslado.provincia !== undefined
          ? selectedRowTraslado.provincia.toLowerCase()
          : "Buenos Aires",
    });
  };

  const handleInputBlurDestino = (event) => {
    obtenerDirecciones({
      domicilio:
      selectedRowTraslado.domicilioDestino !== undefined
          ? selectedRowTraslado.domicilioDestino.toLowerCase()
          : "",
      provincia:
      selectedRowTraslado.provincia !== undefined
          ? selectedRowTraslado.provincia.toLowerCase()
          : "Buenos Aires",
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const llegadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {rowData.llegada}
      </Fragment>
    );
  };

  const afiliadoIdTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID Afiliado</span>
        {rowData.afiliadoId}
      </Fragment>
    );
  };

  const afiliadoNombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.afiliadoNombre}
      </Fragment>
    );
  };

  const domicilioOrigenTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio Origen</span>
        {rowData.domicilioOrigen}
      </Fragment>
    );
  };

  const domicilioDestinoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio Destino</span>
        {rowData.domicilioDestino}
      </Fragment>
    );
  };

  const afiliadoTitularTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Titular</span>
        {rowData.afiliadoTitular}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, traslados);
        doc.save("traslados.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(traslados);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "traslados");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Traslados"></Tag>
      </div>
    </Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          onClick={() => {
            consultaSocio(rowData);
            obtenerNotasPendientes({ servicioId: rowData.id });
          }}
          tooltip="Datos del Traslado"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-replay"
          className="p-button-secondary p-button-text"
          onClick={(e) => {
            setSelectedRow(rowData)
            modificarTraslado(rowData)}}
          tooltip="Modificar Traslado"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-trash"
          className="p-button-danger p-button-text"
          onClick={() => confirm(rowData)}
          tooltip="Eliminar Traslado"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const modificarTraslado = (data) => {
    let a_date = data.llegada.substring(0, 10);
    let a_time = data.llegada.substring(11, 16);
    let newdate = a_date.split("/").reverse().join("-");
    let newDateLlegada = newdate + "T" + a_time;

    setSelectedRowTraslado({
      id: data.id,
      llegada: newDateLlegada,
      domicilioOrigen: data.domicilioOrigen,
      domicilioDestino: data.domicilioDestino,
      provincia: "Buenos Aires",
      latOrigen: data.lat,
      lonOrigen: data.lon,
      latDestino: data.latDestino,
      lonDestino: data.lonDestino,
      trasladoId: data.trasladoId,
    });
    setVisibleModificar(true);
  };

  const consultaSocio = (rowData) => {
    guardarServicio({
      ...rowData,
      origen: "pendientes",
    });
    setVisible(true);
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Datos del Traslado"></Tag>
      </div>
    );
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="Cerrar" icon="pi pi-times" onClick={() => onHide()} />
      </div>
    );
  };

  const confirm = (rowData) => {
    confirmDialog({
      message: "Esta seguro de eliminar el traslado programado ?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptFunc(rowData),
    });
  };

  const acceptFunc = (rowData) => {
    eliminarTrasladoProgramado({
      id: rowData.id,
      usuario: state.usuario.usuario,
    });

    setTimeout(() => {
      obtenerTraslados();
    }, 2000);
  };

  const handleCloseModificar = () => {
    setSelectedRow(null);
    setVisibleModificar(false);
  };

  const handleModificar = () => {
    if (!selectedRowTraslado.llegada) {
      toast.current.show({
        severity: "warn",
        summary: "Llegada",
        detail: `Debe indicar la llegada al Origen.`,
        life: 3000,
      });
      return;
    }

    if (
      !selectedRowTraslado ||
      selectedRowTraslado.domicilioOrigen === undefined ||
      selectedRowTraslado.domicilioOrigen === null ||
      selectedRowTraslado.domicilioOrigen === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El domicilio orígen es necesario !.",
        life: 3000,
      });
      return;
    }

    if (
      !selectedRowTraslado ||
      selectedRowTraslado.domicilioDestino === undefined ||
      selectedRowTraslado.domicilioDestino === null ||
      selectedRowTraslado.domicilioDestino === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El domicilio destino es necesario !.",
        life: 3000,
      });
      return;
    }

    modificarTrasladoPendiente({
      id: selectedRowTraslado.id,
      trasladoId: selectedRowTraslado.trasladoId,
      origen: selectedRowTraslado.origenGeo
        ? selectedRowTraslado.origenGeo
        : selectedRowTraslado.domicilioOrigen,
      destino: selectedRowTraslado.destinoGeo
        ? selectedRowTraslado.destinoGeo
        : selectedRowTraslado.domicilioDestino,
      latOrigen: selectedRowTraslado.latOrigen,
      lonOrigen: selectedRowTraslado.lonOrigen,
      latDestino: selectedRowTraslado.latDestino,
      lonDestino: selectedRowTraslado.lonDestino,
      llegada: selectedRowTraslado.llegada,
    });

    handleCloseModificar();
    setTimeout(() => {
      obtenerTraslados();
    }, 1000);
  };

  const renderHeaderModificar = () => {
    return (
      <div>
        <Tag value="Módificacion del Traslado"></Tag>
      </div>
    );
  };

  const renderFooterModificar = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleModificar()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Salir"
          icon="pi pi-times"
          onClick={() => handleCloseModificar()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderMapa = () => {
    return (
      <div>
        <Tag value="Ubicación en el Mapa"></Tag>
      </div>
    );
  };

  const renderFooterMapa = () => {
    return (
      <div>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleClose()}
          className="p-button-text"
        />
      </div>
    );
  };

  //console.log(selectedRow);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {traslados ? (
            <Fragment>
              <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={traslados}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                        //style={{ display: "none" }}
                      ></Column>
                      <Column
                        field="llegada"
                        header="Llegada"
                        body={llegadaTemplate}
                        sortable
                        //style={{ width: "10%" }}
                      ></Column>
                      <Column
                        field="afiliadoId"
                        header="ID Afiliado"
                        body={afiliadoIdTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="afiliadoNombre"
                        header="Nombre"
                        body={afiliadoNombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="domicilioOrigen"
                        header="Domicilio Origen"
                        body={domicilioOrigenTemplate}
                        sortable
                        //style={{ width: "20%" }}
                      ></Column>
                      <Column
                        field="domicilioDestino"
                        header="Domicilio Destino"
                        body={domicilioDestinoTemplate}
                        sortable
                        //style={{ width: "20%" }}
                      ></Column>
                      <Column
                        field="Titular"
                        header="Titular"
                        body={afiliadoTitularTemplate}
                        sortable
                        //style={{ width: "15%" }}
                      ></Column>
                      <Column
                        className="colBotones"
                        body={actionBodyTemplate}
                        //style={{ width: "8%" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
      <div className="dialog-demo">
        <div className="card">
          <Dialog
            header={renderHeader}
            visible={visible}
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHide()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "100vw",
              paddingBottom: "0",
              overflowY: "visible",
            }}
            position="top-right"
          >
            <Grid container spacing={1} style={{ padding: "0" }}>
              <Grid item xs={12} md={8}>
                <ScrollPanel style={{ width: "100%", height: "100%" }}>
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <ConsultaServicio />
                  </div>
                </ScrollPanel>
              </Grid>
              <Grid item xs={12} md={4}>
                <ScrollPanel
                  style={{ width: "100%", height: "100%" }}
                  className="custombar1"
                >
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <NotasServicio />
                  </div>
                </ScrollPanel>
              </Grid>
            </Grid>
          </Dialog>

          <Dialog
            header={renderHeaderModificar()}
            visible={visibleModificar}
            footer={renderFooterModificar()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw", paddingBottom: "0" }}
            closable={false}
          >
            {selectedRow ? (
              <Grid
                container
                spacing={3}
                style={{ padding: "0", marginTop: "1vw" }}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    id="llegada"
                    label="Llegada"
                    type="datetime-local"
                    //noOptionsText={"No hay opciones"}
                    value={selectedRowTraslado ? selectedRowTraslado.llegada : null}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setSelectedRowTraslado({
                        ...selectedRowTraslado,
                        llegada: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="provincia"
                    label="Provincia"
                    value={
                      selectedRowTraslado && selectedRowTraslado.provincia
                        ? selectedRowTraslado.provincia
                        : "Buenos Aires"
                    }
                    required
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSelectedRowTraslado({
                        ...selectedRowTraslado,
                        provincia: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    id="origen"
                    label="Domicilio Origen"
                    value={selectedRowTraslado ? selectedRowTraslado.domicilioOrigen : null}
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => handleInputBlurOrigen(e)}
                    onChange={(e) =>
                      setSelectedRowTraslado({
                        ...selectedRowTraslado,
                        domicilioOrigen: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={11}>
                  <Autocomplete
                    id="origenGeo"
                    fullWidth
                    disablePortal
                    noOptionsText={"No hay opciones"}
                    options={direcciones}
                    getOptionLabel={(option) => option.label}
                    openOnFocus={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Origen Geolocalizado"
                        variant="standard"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedRowTraslado({
                          ...selectedRowTraslado,
                          origenGeo: value.label,
                          latOrigen: value.lat,
                          lonOrigen: value.lon,
                        });
                      } else {
                        setSelectedRow({
                          ...selectedRowTraslado,
                          origenGeo: null,
                          latOrigen: null,
                          lonOrigen: null,
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    id="destino"
                    label="Destino"
                    value={selectedRowTraslado ? selectedRowTraslado.domicilioDestino : null}
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //onBlur={(e) => handleInputBlurDestino(e)}
                    onChange={(e) =>
                      setSelectedRowTraslado({
                        ...selectedRowTraslado,
                        domicilioDestino: e.target.value,
                      })
                    }
                  />
                </Grid>

                {/*    
                <Grid item xs={12} md={11}>
                  <Autocomplete
                    id="destinoGeo"
                    fullWidth
                    disablePortal
                    noOptionsText={"No hay opciones"}
                    options={direcciones}
                    getOptionLabel={(option) => option.label}
                    openOnFocus={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destino Geolocalizado"
                        variant="standard"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedRowTraslado({
                          ...selectedRowTraslado,
                          destinoGeo: value.label,
                          latDestino: value.lat,
                          lonDestino: value.lon,
                        });
                      } else {
                        setSelectedRow({
                          ...selectedRowTraslado,
                          destinoGeo: null,
                          latDestino: null,
                          lonDestino: null,
                        });
                      }
                    }}
                  />
                </Grid>
                */}

                <Grid item xs={12} md={12}></Grid>
              </Grid>
            ) : null}
          </Dialog>
        </div>
      </div>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
