import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import { ServiciosContext } from "../../../../context/ServiciosContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../../Menu";
import { Dropdown } from "primereact/dropdown";
import SignatureCanvas from "react-signature-canvas";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import axios from "axios";
import logo from "../../../../assets/images/masteredlogo.png";
import { useParams } from "react-router-dom";

export default function RegistrosMedicos(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Apellido y Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "Mat. Nacional", dataKey: "mn" },
    { title: "Mat. Provincial", dataKey: "mp" },
    { title: "D.N.I.", dataKey: "numeroDocumento" },
    { title: "C.U.I.T./C.U.I.L.", dataKey: "cuit" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const titulos = [
    { name: "Dr", dataKey: "1" },
    { name: "Dra", dataKey: "2" },
  ];

  const [medico, guardarMedico] = useState(null);
  const [medicoElegido, setMedicoElegido] = useState(null);
  const [provinciaElegida, setProvinciaElegida] = useState(null);
  const [tituloElegido, setTituloElegido] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const toastWarm = useRef(null);
  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [medicoUpload, setMedicoUpload] = useState(null);
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);
  const [clave, setClave] = useState(null);
  const [clave2, setClave2] = useState(null);
  const [bandFirst, setBandFirst] = useState(true);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    configuracion,
    medicos,
    provincias,
    obtenerMedicos,
    registroMedico,
    obtenerProvincias,
    obtenerConfiguracion,
  } = configuracionContext;

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      obtenerMedicos({ todos: false });
      obtenerProvincias({ todos: true });
      obtenerConfiguracion();
    }
  }, [id]);

  const editClick = (e) => {
    if (selectedRow) {
      guardarMedico(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un médico.",
        life: 3000,
      });
    }
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const onHide = (e) => {
    setMedicoElegido(null);
    //window.location.reload();
    window.location.href = "https://mastered.com.ar";
  };

  const aceptarClick = async (e) => {
    e.preventDefault();

    //Validar

    if (
      !medico ||
      medico.apellido === undefined ||
      medico.apellido === null ||
      medico.apellido.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El apellido es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      medico.nombre === undefined ||
      medico.nombre === null ||
      medico.nombre.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El nombre es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      medico.domicilio === undefined ||
      medico.domicilio === null ||
      medico.domicilio.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El domicilio es necesario !.", "error");
      return;
    }

    if (!provinciaElegida) {
      mensajeAlerta("Registro Médico", "La provincia es necesaria !.", "error");
      return;
    }

    if (
      !medico ||
      medico.telefono === undefined ||
      medico.telefono === null ||
      medico.telefono.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El teléfono es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      medico.email === undefined ||
      medico.email === null ||
      medico.email.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El email es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      ((medico.mn === undefined ||
        medico.mn === null ||
        medico.mn.trim() === "") &&
        (medico.mp === undefined ||
          medico.mp === null ||
          medico.mp.trim() === ""))
    ) {
      mensajeAlerta("Registro Médico", "La matrícula es necesaria !.", "error");
      return;
    }

    if (
      !medico ||
      medico.numeroDocumento === undefined ||
      medico.numeroDocumento === null ||
      medico.numeroDocumento.trim() === ""
    ) {
      mensajeAlerta(
        "Registro Médico",
        "El Nº de Documento es necesario !.",
        "error"
      );
      return;
    }

    if (!tituloElegido) {
      mensajeAlerta("Registro Médico", "El título es necesario !.", "error");
      return;
    }

    if (!signature) {
      mensajeAlerta("Registro Médico", "La firma es necesaria !.", "error");
      return;
    }

    if (medico && !medico.clave) {
      if (!clave || clave === undefined || clave.trim() === "") {
        mensajeAlerta("Registro Médico", "La clave es necesaria !.", "error");
        return;
      }

      if (clave && clave.trim().length < 4) {
        mensajeAlerta(
          "Registro Médico",
          "La clave debe tener al menos 4 caracteres !.",
          "error"
        );
        return;
      }

      if (!clave2 || clave2 === undefined || clave2.trim() === "") {
        mensajeAlerta(
          "Registro Médico",
          "La repetición de clave es necesaria !.",
          "error"
        );
        return;
      }

      if (clave !== clave2) {
        mensajeAlerta("Registro Médico", "Las claves no coinciden !.", "error");
        return;
      }
    }

    // Llamar al context

    const blob = dataURLtoBlob(signature);
    var formdata = new FormData();
    formdata.append("doctorWithLicense", "");
    formdata.append("license", "");
    formdata.append("signature", blob);
    formdata.append("province", provinciaElegida.nombre);
    formdata.append("address", medico.domicilio);
    formdata.append("workPhone", medico.telefono);
    formdata.append("title", tituloElegido.name);
    formdata.append("specialty", "");
    if (medico.mn) {
      formdata.append("licenseNumber", Number(medico.mn));
      formdata.append("licenseType", "nacional");
    } else {
      formdata.append("licenseNumber", Number(medico.mp));
      formdata.append("licenseType", "provincial");
    }
    formdata.append("documentNumber", Number(medico.numeroDocumento));
    formdata.append("surname", medico.apellido);
    formdata.append("name", medico.nombre);
    if (medicoElegido.clave) {
      formdata.append("password", medicoElegido.clave);
    } else {
      formdata.append("password", clave);
    }
    formdata.append("email", medico.email);
    formdata.append("healthCenterId", Number(configuracion.institucionId));
    formdata.append("id_medico", medicoElegido.id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_RECETARIO_TOKEN}`,
      },
    };

    let datos = null;

    try {
      let response = await fetch(
        "https://external-api.recetario.com.ar/users",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      if (response.ok) {
        toast.current.show({
          severity: "success",
          summary: "Registro",
          detail: "Se ha registrado correctamente.",
          life: 3000,
        });

        const json = await response.json();
        //console.log(json);

        datos = {
          recetarioId: json.id,
          medicoId: medicoElegido.id,
          clave,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios
          .post(
            process.env.REACT_APP_BACKEND_URL +
              "/api/configuracion/actualizarmedicosrecetario",
            datos,
            config
          )
          .then(function (response) {
            console.log(response);
          });

        setTimeout(() => {
          onHide();
        }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Registro",
          detail: "Hubo un error registrando el médico.",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };

  //INICIO

  if (id && medicos && medicos.length > 0 && !medicoElegido && bandFirst) {
    setBandFirst(false);
    let index = medicos.findIndex((item) => item.id === Number(id));
    setMedicoElegido(medicos[index]);
  }

  // FIN  


  //console.log(configuracion)

  return (
    <Fragment>
      <img
        src={logo}
        alt="Logo"
        height="50"
        style={{ marginLeft: "0em", marginTop: "0em" }}
      />

      <Toast ref={toast} />
      <Toast ref={toastWarm} position="top-right" />
      <Fragment>
        {medicos ? (
          <Fragment>
            <Grid
              container
              spacing={3}
              sx={{ marginTop: { xs: "10vw", md: "1vw" }, marginLeft: "3vw" }}
            >
              <Grid item xs={10} md={11} style={{ textAlign: "center" }}>
                <h3>{medicoElegido ? medicoElegido.nombre : null}</h3>
                <h4>{medicoElegido && medicoElegido.recetarioId ? "YA ESTA REGISTRADO" : null}</h4>
              </Grid>
              {medicoElegido &&
              configuracion &&
              configuracion.institucionId &&
              !medicoElegido.recetarioId ? (
                <Fragment>
                  <Grid item xs={10} md={11}>
                    <TextField
                      value={medico ? medico.apellido : null}
                      required
                      id="apellido"
                      label="Apellido"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          apellido: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={10} md={11}>
                    <TextField
                      value={medico ? medico.nombre : null}
                      required
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={10} md={8}>
                    <TextField
                      value={medico ? medico.domicilio : null}
                      required
                      id="domicilio"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Dropdown
                      value={provinciaElegida}
                      onChange={(e) => setProvinciaElegida(e.value)}
                      options={provincias}
                      optionLabel="nombre"
                      placeholder="Seleccione una provincia"
                      className="w-full md:w-14rem"
                      showClear
                    />
                  </Grid>

                  <Grid item xs={10} md={4}>
                    <TextField
                      value={medico ? medico.telefono : null}
                      required
                      id="telefono"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          telefono: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={10} md={7}>
                    <TextField
                      value={medico ? medico.email : null}
                      required
                      id="email"
                      label="Email"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={10} md={3}>
                    <TextField
                      value={medico ? medico.mn : null}
                      required
                      id="mn"
                      label="Matrícula nacional"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          mn: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={10} md={3}>
                    <TextField
                      value={medico ? medico.mp : null}
                      required
                      id="mp"
                      label="Matrícula provincial"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          mp: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={10} md={3}>
                    <TextField
                      value={medico ? medico.numeroDocumento : null}
                      required
                      id="numeroDocumento"
                      label="D.N.I."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          numeroDocumento: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={10} md={3}>
                    <Dropdown
                      value={tituloElegido}
                      onChange={(e) => setTituloElegido(e.value)}
                      options={titulos}
                      optionLabel="name"
                      placeholder="Seleccione un título"
                      className="w-full md:w-14rem"
                      showClear
                    />
                  </Grid>

                  {/*      
                      <Grid item size xs={12} md={12}>
                        <div className="file-select" id="src-file1">
                          <input
                            type="file"
                            name="src-file1"
                            aria-label="Archivo"
                            accept="image/*"
                            onChange={onChange}
                          />
                        </div>

                        <div
                          style={{ marginLeft: "1vw", display: "inline-block" }}
                        >
                          <label htmlFor="src-file1">{filename}</label>
                        </div>
                      </Grid>
                      <Grid item size xs={12} md={12}>
                        <Button
                          type="button"
                          icon="pi pi-upload"
                          onClick={uploadFile}
                          className="p-button-warning p-mr-2"
                          style={{ marginRight: "1em" }}
                          label="Upload"
                        />
                      </Grid>
                      */}

                  <Grid item xs={12} md={3}>
                    <h3>
                      Dibuje la firma
                      <button
                        style={{ marginLeft: "1vw" }}
                        onClick={clearSignature}
                      >
                        Limpiar
                      </button>
                    </h3>

                    <div
                      style={{
                        width: 300,
                        height: 200,
                        border: "1px solid black",
                      }}
                    >
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: 300,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={sigRef}
                        onEnd={handleSignatureEnd}
                      />
                    </div>
                  </Grid>

                  {!medicoElegido.clave ? (
                    <Fragment>
                      <Grid item xs={10} md={3}>
                        <TextField
                          value={clave ? clave : null}
                          required
                          id="clave"
                          label="Ingrese Clave"
                          fullWidth
                          variant="standard"
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          onChange={(e) => setClave(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={10} md={3}>
                        <TextField
                          value={clave2 ? clave2 : null}
                          required
                          id="repetirclave"
                          label="Repita Clave"
                          fullWidth
                          variant="standard"
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          onChange={(e) => setClave2(e.target.value)}
                        />
                      </Grid>
                    </Fragment>
                  ) : null}
                  <Grid item xs={12} md={12} />

                  <Grid item xs={12} md={12}>
                    <div>
                      <ButtonMui
                        onClick={aceptarClick}
                        color="primary"
                        type="button"
                        //fullWidth
                        variant="contained"
                        startIcon={<CheckIcon />}
                      >
                        Aceptar
                      </ButtonMui>
                      <ButtonMui
                        onClick={onHide}
                        color="secondary"
                        type="button"
                        //fullWidth
                        variant="contained"
                        startIcon={<CloseIcon />}
                        style={{ marginLeft: "1vw" }}
                        autoFocus
                      >
                        Cancelar
                      </ButtonMui>
                    </div>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </Fragment>
        ) : (
          <Spinner />
        )}
        <Grid item xs={12} md={12} />
      </Fragment>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
