import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import imageToBase64 from "image-to-base64/browser";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function Medicos(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Apellido y Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "Mat. Nacional", dataKey: "mn" },
    { title: "Mat. Provincial", dataKey: "mp" },
    { title: "D.N.I.", dataKey: "numeroDocumento" },
    { title: "C.U.I.T./C.U.I.L.", dataKey: "cuit" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [medico, guardarMedico] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const toastWarm = useRef(null);
  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [medicoUpload, setMedicoUpload] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    medicos,
    configuracion,
    obtenerMedicos,
    obtenerAcceso,
    obtenerAcceso2,
    agregarMedico,
    actualizarMedico,
    firmaMedico,
    resetPasswordMedico,
    guardarFirmaMedico,
    enviarLinkRegistroMedico,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Medicos" });
      obtenerAcceso2({
        usuarioId: state.usuario.id,
        layout: "ResetPasswordTripulacion",
      });
    }
    obtenerMedicos({ todos: true });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarMedico(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un médico.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //navigate(`/base/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarMedico({
      domicilio: null,
      telefono: null,
      email: null,
      mn: null,
      mp: null,
      numeroDocumento: null,
      cuit: null,
      habilitado: true,
    });
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 2);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar

    if (
      !medico ||
      medico.nombre === undefined ||
      medico.nombre === null ||
      medico.nombre.trim() === ""
    ) {
      mensajeAlerta("Médicos", "El nombre es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarMedico({ medico });
    } else {
      agregarMedico({ medico });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMedicos({ todos: true });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };
  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Teléfono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const emailTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Email</span>
        {rowData.email}
      </Fragment>
    );
  };

  const mnTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Matrícula Nacional</span>
        {rowData.mn}
      </Fragment>
    );
  };

  const mpTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Matrícula Provincial</span>
        {rowData.mp}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const cuitTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">C.U.I.T.</span>
        {rowData.cuit}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-undo"
          className="p-button-success p-button-text"
          onClick={() => resetPassword(rowData)}
          tooltip="Reset Password"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        {configuracion.institucionId && configuracion.whapi ? (
          <Button
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
            icon="pi pi-send"
            className="p-button-danger p-button-text"
            onClick={() => enviarLink(rowData)}
            //disabled={configuracion.destinatario !== 4}
            tooltip="link Recetas "
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
          />
        ) : null}
        {/*
         <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-id-card"
          className="p-button-success p-button-text"
          onClick={() => setFirmaMedico(rowData)}
          tooltip="Registro Firma"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: classes.tooltip,
          }}
        />
        */}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Médicos", 60, 8);
        doc.autoTable(exportColumns, medicos);
        //doc.save("medicos.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(medicos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "medicos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Médicos"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Médico"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function opcionSiUpload(rowData) {
    setMedicoUpload(rowData.id);
    setFile(null);
    setFilename(null);
    setVisibleUpload(true);
    toastWarm.current.clear();
  }

  function opcionNo() {
    toastWarm.current.clear();
  }

  const resetPassword = (medico) => {
    if (statusAcceso2 !== 200) {
      mensajeAlerta(
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }
    toastWarm.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Confirma reset Password ${medico.nombre} ?`}</h4>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => {
                  resetPasswordMedico({ medicoId: medico.id });
                  opcionNo();
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={opcionNo}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const enviarLink = (medico) => {
    if (!medico.telefono) {
      mensajeAlerta(
        "Médicos",
        "El médico no tiene cargado el celular !.",
        "error"
      );
      return;
    }
    enviarLinkRegistroMedico({ id: medico.id, telefono: medico.telefono });
  };

  const renderHeaderUpload = () => {
    return (
      <div>
        <Tag value="Upload de Archivos"></Tag>
      </div>
    );
  };

  const setFirmaMedico = (rowData) => {
    if (statusAcceso2 !== 200) {
      mensajeAlerta(
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }
    if (rowData.firma !== null) {
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`El médico ${rowData.nombre} ya tiene fiema guardada.`}</h4>
              <p>{`Desea modificarla ?`}</p>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => opcionSiUpload(rowData)}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    } else {
      opcionSiUpload(rowData);
    }
  };

  const onHideUpload = (e) => {
    setVisibleUpload(false);
  };

  const onChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setFilename(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    if (file !== null) {
      imageToBase64(file) // Path to the image
        .then((response) => {
          guardarFirmaMedico({ firma: response, medicoId: medicoUpload });
        })
        .catch((error) => {
          mensajeAlerta("Hubo un error !.", "error");
        });
      onHideUpload();
    } else {
      mensajeAlerta("El necesario seleccionar un archivo !.", "error");
      return;
    }
  };

  //console.log(file);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      <Toast ref={toastWarm} position="top-right" />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {medicos ? (
            <Fragment>
              <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={medicos}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "xx-small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //style={{ width: "5%" }}
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Apellido y Nombre"
                        //style={{ width: "20%" }}
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="domicilio"
                        header="Domicilio"
                        //style={{ width: "20%" }}
                        body={domicilioTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="telefono"
                        header="Teléfono"
                        //style={{ width: "10%" }}
                        body={telefonoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="email"
                        header="Email"
                        //style={{ width: "10%" }}
                        body={emailTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="mn"
                        header="Matrícula Nacional"
                        body={mnTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="mp"
                        header="Matrícula Provincial"
                        body={mpTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="D.N.I."
                        body={documentoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="cuit"
                        header="C.U.I.T./C.U.I.L."
                        body={cuitTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                      <Column
                        className="colBotones"
                        body={actionBodyTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={medico ? medico.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={medico ? medico.nombre : null}
                      required
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={medico ? medico.domicilio : null}
                      id="domicilio"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      value={medico ? medico.telefono : null}
                      id="telefono"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          telefono: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      value={medico ? medico.email : null}
                      id="email"
                      label="Email"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.mn : null}
                      id="mn"
                      label="Matrícula nacional"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          mn: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.mp : null}
                      id="mp"
                      label="Matrícula provincial"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          mp: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.numeroDocumento : null}
                      id="numeroDocumento"
                      label="D.N.I."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          numeroDocumento: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.cuit : null}
                      id="cuit"
                      label="C.U.I.T./C.U.I.L."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          cuit: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.valorGuardia : null}
                      id="valorGuardia"
                      label="Valor Guardia"
                      fullWidth
                      variant="standard"
                      type="number"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          valorGuardia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.nocturno : null}
                      id="nocturno"
                      label="% Nocturno"
                      fullWidth
                      variant="standard"
                      type="number"
                      error={
                        medico &&
                        medico.nocturno !== null &&
                        medico.nocturno !== "" &&
                        (medico.nocturno < 0 || medico.nocturno > 100)
                      }
                      helperText={
                        medico &&
                        medico.nocturno !== null &&
                        medico.nocturno !== "" &&
                        (medico.nocturno < 0 || medico.nocturno > 100)
                          ? "Error "
                          : " "
                      }
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          nocturno: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.sadofe : null}
                      id="sadofe"
                      label="% Sab. Dom. Fer."
                      fullWidth
                      variant="standard"
                      type="number"
                      error={
                        medico &&
                        medico.sadofe !== null &&
                        medico.sadofe !== "" &&
                        (medico.sadofe < 0 || medico.sadofe > 100)
                      }
                      helperText={
                        medico &&
                        medico.sadofe !== null &&
                        medico.sadofe !== "" &&
                        (medico.sadofe < 0 || medico.sadofe > 100)
                          ? "Error "
                          : " "
                      }
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          sadofe: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.presentismo : null}
                      id="presentismo"
                      label="Adicional Presentismo"
                      fullWidth
                      variant="standard"
                      type="number"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          presentismo: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.pediatrico : null}
                      id="pediatrico"
                      label="Adicional Pediátrico"
                      fullWidth
                      variant="standard"
                      type="number"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          pediatrico: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 0, marginTop: "1vw" }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={medico ? medico.habilitado : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarMedico({
                              ...medico,
                              habilitado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitado"
                      labelPlacement="start"
                    />
                  </Grid>
                  {/*
                  <Grid item xs={12} md={4} style={{ paddingLeft: 0 }}>
                    <input
                      accept=".jpg"
                      className={classes.input}
                      id="firma-file"
                      type="file"
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <label htmlFor="firma-file">
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Foto Firma
                      </ButtonMui>
                    </label>
                  </Grid>
                  */}
                </Grid>
              </Dialog>
              <Dialog
                header={renderHeaderUpload}
                visible={visibleUpload}
                className="p-dialog-despacho"
                icon="pi pi-external-link"
                closable={true}
                onHide={() => onHideUpload()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{
                  width: "55vw",
                  padding: "0",
                  overflowY: "visible",
                }}
                position="center-left"
              >
                <Grid
                  container
                  spacing={3}
                  style={{ padding: "0", marginTop: "1vw" }}
                >
                  <Grid item xs={12} md={12}>
                    <div className="file-select" id="src-file1">
                      <input
                        type="file"
                        name="src-file1"
                        aria-label="Archivo"
                        accept="image/*"
                        onChange={onChange}
                      />
                    </div>

                    <div style={{ marginLeft: "1vw", display: "inline-block" }}>
                      <label htmlFor="src-file1">{filename}</label>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      type="button"
                      icon="pi pi-upload"
                      onClick={uploadFile}
                      className="p-button-warning p-mr-2"
                      style={{ marginRight: "1em" }}
                      label="Upload"
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
