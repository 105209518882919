import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
//import "./example-styles.css";
//import "./styles.css";
//import { Responsive, WidthProvider } from "react-grid-layout";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
//import NewWindow from "react-new-window";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import detectZoom from "detect-zoom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import AddIcon from "@mui/icons-material/Add";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import ResumenServicio from "./ResumenServicio";
import ResumenMoviles from "./ResumenMoviles";
import ResumenEstadoMoviles from "./ResumenEstadoMoviles";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
//import classNames from "classnames";
import { classNames } from "primereact/utils";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import SpeedDialIcon from "../SpeedDialIcon";
import HouseIcon from "@mui/icons-material/House";
import AdjustIcon from "@mui/icons-material/Adjust";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import FlagIcon from "@mui/icons-material/Flag";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import { MultiSelect } from "primereact/multiselect";
import { saveAs } from "file-saver";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Barra from "../Barra";
import TrasladosServicios from "./TrasladosServicios";

const PREFIX = "Despachos";
const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
}));

export default function Despachos(props) {
  //const classes = useStyles();
  //const theme = useTheme();
  const [id, setId] = useState(null);
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState(null);
  const [loop, setLoop] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [bandStart, setBandStart] = useState(false);
  const [confirmarApp, setConfirmarApp] = useState(false);
  const [tripulacionApp, setTripulacionApp] = useState(null);
  const [bandNuevoServicio, setBandNuevoServicio] = useState(false);
  const [first, setFirst] = useState(true);
  const toast = useRef(null);
  const toastTC = useRef(null);
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta, mensajeAlertaTR, mensajeAlertaTL, mensajeAlertaBL } =
    alertContext;
  const [selectedColors, setSelectedColors] = useState(null);
  const [selectedZona, setSelectedZona] = useState(null);
  const [selectedMovils, setSelectedMovils] = useState(null);
  const [liberado, setLiberado] = useState(false);
  const [celular, setCelular] = useState(null);
  const [linkMp, setLinkMp] = useState(null);

  let navigate = useNavigate();

  const items = [
    { label: "Zona", value: "ZONA" },
    { label: "Fuera de Zona", value: "NOZONA" },
  ];

  const movils = [
    { label: "Propios", value: "PROPIOS" },
    { label: "Derivados", value: "DERIVADOS" },
    { label: "Pendientes", value: "PENDIENTES" },
  ];

  const colors = [
    { label: "Verde", value: "VERDE" },
    { label: "Amarillo", value: "AMARILLO" },
    { label: "Rojo", value: "ROJO" },
    { label: "Celeste", value: "CELESTE" },
    { label: "Azul", value: "AZUL" },
    { label: "Naranja", value: "NARANJA" },
    { label: "Fuccia", value: "FUCCIA" },
    { label: "Blanco", value: "BLANCO" },
    { label: "Negro", value: "NEGRO" },
  ];

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    colores,
    moviles,
    configuracion,
    obtenerAcceso,
    obtenerColores,
    obtenerMoviles,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    resumen,
    selectedRow,
    despachos,
    drawer,
    openDrawer,
    demorados,
    tripulaciones,
    servicioSeleccionado,
    estadoTomado,
    refrescar,
    notas,
    openMp,
    setSelectedRow,
    obtenerServiciosPendientes,
    obtenerServiciosPendientesColor,
    setEstadoTomado,
    guardarServicio,
    guardarDemorados,
    obtenerTripulacionesDespacho,
    obtenerTripulaciones,
    guardarServicioSeleccionado,
    setDrawer,
    setOpenDrawer,
    setNota,
    setNotaVisible,
    setRefrescar,
    obtenerNotas,
    cancelarNotificationApp,
    confirmarMovilLiberado,
    confirmarNuevoServicioPrestador,
    setOpenMp,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Despachos" });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerServiciosPendientes();
    obtenerTripulaciones({ estado: false });

    setTimeout(() => {
      //let elem = document.getElementById("inicioControl");
      //elem.click();
      setBandStart(true);
    }, 1000);

    if (
      localStorage.getItem("zona") !== null &&
      localStorage.getItem("zona") !== ""
    ) {
      let col = localStorage.getItem("zona");
      let arr = col.split(",");
      setSelectedZona(arr);
    } else {
      setSelectedZona(null);
    }
    if (
      localStorage.getItem("colores") !== null &&
      localStorage.getItem("colores") !== ""
    ) {
      let col = localStorage.getItem("colores");
      let arr = col.split(",");
      setSelectedColors(arr);
    } else {
      setSelectedColors(null);
    }
    if (
      localStorage.getItem("moviles") !== null &&
      localStorage.getItem("moviles") !== ""
    ) {
      let col = localStorage.getItem("moviles");
      let arr = col.split(",");
      setSelectedMovils(arr);
    } else {
      setSelectedMovils(null);
    }

    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, [refrescar]);

  useEffect(() => {
    let interval = setInterval(() => {
      obtenerServiciosPendientes();
      obtenerTripulaciones({ estado: false });
      /*
      setTimeout(() => {
        let elem = document.getElementById("inicioControl");
        elem.click();
      }, 2000);
      */
      if (
        localStorage.getItem("zona") !== null &&
        localStorage.getItem("zona") !== ""
      ) {
        let col = localStorage.getItem("zona");
        let arr = col.split(",");
        setSelectedZona(arr);
      } else {
        setSelectedZona(null);
      }

      if (
        localStorage.getItem("colores") !== null &&
        localStorage.getItem("colores") !== ""
      ) {
        let col = localStorage.getItem("colores");
        let arr = col.split(",");
        setSelectedColors(arr);
      } else {
        setSelectedColors(null);
      }

      if (
        localStorage.getItem("moviles") !== null &&
        localStorage.getItem("moviles") !== ""
      ) {
        let col = localStorage.getItem("moviles");
        let arr = col.split(",");
        setSelectedMovils(arr);
      } else {
        setSelectedMovils(null);
      }
    }, 30000);
    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    obtenerColores();
  }, []);

  /*
  if (!bandStart) {
    setTimeout(() => {
      handleStart();
    }, 30000);
    setBandStart(true);
  }
  */

  const exitClick = (e) => {
    navigate(`/`);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  /*
  if (refrescar) {
    obtenerServiciosPendientes();
    setRefrescar(false);
  }
  */

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "refrescar") {
      setRefrescar(newValue);
    }
  };

  const handleStart = () => {
    let interval = setInterval(() => {
      //obtenerTripulaciones({ estado: false });
      //console.log('handle')
      obtenerServiciosPendientes();
      obtenerTripulaciones({ estado: false });
    }, 30000);
    setLoop(interval);
  };

  const handleReset = () => {
    clearInterval(loop);
  };

  /*
  if (!loop) {
    handleStart();
  }
  */

  const handleZoom = () => {
    if (detectZoom.device() * 100 !== 100) {
      mensajeAlerta(
        "Pantalla",
        "Tiene zoom diferente a 100%. Por favor cambiela para poder ver la pantalla correctamente !.",
        "error"
      );
    }
  };

  const handleDrawerOpenResumen = () => {
    setDrawer("resumen");
    setOpenDrawer(true);
  };

  const handleDrawerOpenMoviles = () => {
    setDrawer("moviles");
    setOpenDrawer(true);
  };

  const handleDrawerOpenEstadoMoviles = () => {
    setDrawer("estado");
    setOpenDrawer(true);
  };

  if (!timer) {
    setTimer(new Date());
  }

  const confirmApp = () => {
    confirmDialog({
      message:
        "El móvil " +
        despachos.movilId +
        " solicita el cierre del servicio. Confirma cierre ? ",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };
  const accept = () => {
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: "You have accepted",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  /* INICIO */

  if (servicioSeleccionado && servicioSeleccionado.linkMp && !linkMp) {
    setCelular(servicioSeleccionado.celular);
    setLinkMp(servicioSeleccionado.linkMp);
  }

  if (
    !estadoTomado &&
    despachos &&
    despachos.length > 0 &&
    colores &&
    bandStart
  ) {
    // Servicios tomados

    let indicativosDemoraLlegada = "";
    let indicativosAlertaLlegada = "";
    let indicativosDemoraSalida = "";
    let indicativosAlertaSalida = "";
    let indicativosPendiente = "";
    let infoAppRecepcionado = "";

    let rojoDemorados = 0;
    let amarilloDemorados = 0;
    let verdeDemorados = 0;
    let azulDemorados = 0;
    let fucciaDemorados = 0;
    let naranjaDemorados = 0;
    let blancoDemorados = 0;
    let negroDemorados = 0;
    let celesteDemorados = 0;

    //console.log(new Date().getTime())

    //console.log(tripulaciones)

    let bandServicio = false;
    let mensajeAlerta = null;
    let bandAlerta = false;
    let idAlerta = null;

    for (var i = 0; i < despachos.length; i++) {
      if (despachos[i].nuevoServicio) {
        bandServicio = true;
      }

      if (
        (despachos[i].estado === "TOMADO" ||
          despachos[i].estado === "PASADO" ||
          despachos[i].estado === "SALIDA") &&
        despachos[i].movilId < 1000
      ) {
        // Alerta y Demora de Llegadas

        let cantidadCaracteresTomado = despachos[i].tomado.length;
        let ubicacionEspacioTomado = despachos[i].tomado.indexOf(" ", 11);

        if (ubicacionEspacioTomado !== -1) {
          let tiempoTomado = despachos[i].tomado.substring(
            ubicacionEspacioTomado + 1,
            cantidadCaracteresTomado
          );
          let alertaLlegada;
          let demoraLlegada;
          for (var j = 0; j < colores.length; j++) {
            if (colores[j].nombre === despachos[i].color) {
              alertaLlegada = colores[j].alertaLlegada;
              demoraLlegada = colores[j].demoraLlegada;
              break;
            }
          }

          if (
            Number(tiempoTomado) >= Number(demoraLlegada) &&
            Number(demoraLlegada) !== 0
          ) {
            if (indicativosDemoraLlegada === "") {
              indicativosDemoraLlegada = String(despachos[i].id);
            } else {
              indicativosDemoraLlegada =
                indicativosDemoraLlegada + " - " + String(despachos[i].id);
            }
            switch (despachos[i].estado) {
              case "TOMADO":
                despachos[i].estado = "TOMADO / DEMORADO";
                break;
              case "PASADO":
                despachos[i].estado = "PASADO / DEMORADO";
                break;
              case "SALIDA":
                despachos[i].estado = "SALIDA / DEMORADO";
                break;
            }
            if (despachos[i].color === "ROJO") {
              rojoDemorados = rojoDemorados + 1;
            }
            if (despachos[i].color === "AMARILLO") {
              amarilloDemorados = amarilloDemorados + 1;
            }
            if (despachos[i].color === "VERDE") {
              verdeDemorados = verdeDemorados + 1;
            }
            if (despachos[i].color === "AZUL") {
              azulDemorados = azulDemorados + 1;
            }
            if (despachos[i].color === "CELESTE") {
              celesteDemorados = celesteDemorados + 1;
            }
            if (despachos[i].color === "FUCCIA") {
              fucciaDemorados = fucciaDemorados + 1;
            }
            if (despachos[i].color === "NARANJA") {
              naranjaDemorados = naranjaDemorados + 1;
            }
            if (despachos[i].color === "BLANCO") {
              blancoDemorados = blancoDemorados + 1;
            }
            if (despachos[i].color === "NEGRO") {
              negroDemorados = negroDemorados + 1;
            }
          }
          if (
            Number(tiempoTomado) >= Number(alertaLlegada) &&
            Number(tiempoTomado) < Number(demoraLlegada) &&
            Number(alertaLlegada) !== 0
          ) {
            if (indicativosAlertaLlegada === "") {
              indicativosAlertaLlegada = String(despachos[i].id);
            } else {
              indicativosAlertaLlegada =
                indicativosAlertaLlegada + " - " + String(despachos[i].id);
            }

            switch (despachos[i].estado) {
              case "TOMADO":
                despachos[i].estado = "TOMADO / ALERTA";
                break;
              case "PASADO":
                despachos[i].estado = "PASADO / ALERTA";
                break;
              case "SALIDA":
                despachos[i].estado = "SALIDA / ALERTA";
                break;
            }
          }

          //Pendientes

          let alertaPendiente;
          for (var j = 0; j < colores.length; j++) {
            if (colores[j].nombre === despachos[i].color) {
              alertaPendiente = colores[j].alertaPendiente;
              break;
            }
          }

          if (despachos[i].estado.substring(0, 6) === "TOMADO") {
            if (alertaPendiente) {
              if (indicativosPendiente === "") {
                indicativosPendiente = String(despachos[i].id);
              } else {
                indicativosPendiente =
                  indicativosPendiente + " - " + String(despachos[i].id);
              }
            }
          }
          // Alerta y Demora de Salidas

          if (
            despachos[i].estado.substring(0, 6) === "PASADO" &&
            despachos[i].pasado !== null
          ) {
            let cantidadCaracteresPasado = despachos[i].pasado.length;
            let ubicacionEspacioPasado = despachos[i].pasado.indexOf(" ", 11);

            if (ubicacionEspacioPasado !== -1) {
              let tiempoPasado = despachos[i].pasado.substring(
                ubicacionEspacioPasado + 1,
                cantidadCaracteresPasado
              );
              let alertaSalida;
              let demoraSalida;
              for (var j = 0; j < colores.length; j++) {
                if (colores[j].nombre === despachos[i].color) {
                  alertaSalida = colores[j].alertaSalida;
                  demoraSalida = colores[j].demoraSalida;
                  break;
                }
              }
              if (
                Number(tiempoPasado) >= Number(demoraSalida) &&
                Number(demoraSalida) !== 0
              ) {
                if (indicativosDemoraSalida === "") {
                  indicativosDemoraSalida = String(despachos[i].id);
                } else {
                  indicativosDemoraSalida =
                    indicativosDemoraSalida + " - " + String(despachos[i].id);
                }
                despachos[i].estado = "PASADO / DEMORADO";
              }

              if (
                Number(tiempoPasado) >= Number(alertaSalida) &&
                Number(tiempoPasado) < Number(demoraSalida) &&
                Number(alertaSalida) !== 0
              ) {
                if (indicativosAlertaSalida === "") {
                  indicativosAlertaSalida = String(despachos[i].id);
                } else {
                  indicativosAlertaSalida =
                    indicativosAlertaSalida + " - " + String(despachos[i].id);
                }
              }

              if (
                despachos[i].estado.substring(0, 6) === "PASADO" &&
                despachos[i].recepcionApp !== null
              ) {
                if (infoAppRecepcionado === "") {
                  infoAppRecepcionado = String(despachos[i].id);
                } else {
                  infoAppRecepcionado =
                    infoAppRecepcionado + " - " + String(despachos[i].id);
                }
              }
            }
          }
        }
      }

      if (despachos[i].alertaCode === 1) {
        bandAlerta = true;
        mensajeAlerta =
          "El servicio ID " +
          despachos[i].id +
          " del Afiliado " +
          despachos[i].nombre +
          " ha abonado el coseguro.";
        idAlerta = despachos[i].id;
      }

      if (despachos[i].alertaCode === 2) {
        bandAlerta = true;
        mensajeAlerta =
          "El móvil " +
          despachos[i].movilId + "-" + despachos[i].detalleDerivado + 
          " confirmó recepción del servicio ID " +
          despachos[i].id;
        idAlerta = despachos[i].id;
      }
    }

    if (
      rojoDemorados > 0 ||
      amarilloDemorados > 0 ||
      verdeDemorados > 0 ||
      azulDemorados > 0 ||
      celesteDemorados > 0 ||
      fucciaDemorados > 0 ||
      naranjaDemorados > 0 ||
      blancoDemorados > 0 ||
      negroDemorados > 0
    ) {
      guardarDemorados({
        rojoDemorados,
        amarilloDemorados,
        verdeDemorados,
        azulDemorados,
        celesteDemorados,
        fucciaDemorados,
        naranjaDemorados,
        blancoDemorados,
        negroDemorados,
      });
    } else {
      guardarDemorados({
        rojoDemorados: 0,
        amarilloDemorados: 0,
        verdeDemorados: 0,
        azulDemorados: 0,
        celesteDemorados: 0,
        fucciaDemorados: 0,
        naranjaDemorados: 0,
        blancoDemorados: 0,
        negroDemorados: 0,
      });
    }

    let d = new Date(timer);
    let d2 = new Date();
    d.setSeconds(d.getSeconds() + 60);

    if (tripulaciones) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (tripulaciones[i].confirmaApp && !confirmarApp) {
          setConfirmarApp(true);
          setTripulacionApp(tripulaciones[i]);
          break;
        }
      }
    }
    if (d2 > d || first) {
      setFirst(false);
      if (configuracion && configuracion.alertas) {
        if (indicativosDemoraSalida !== "") {
          mensajeAlertaTL(
            "Alertas",
            "Id/s " +
              indicativosDemoraSalida +
              " están demorados en la salida del móvil !.",
            "error"
          );
        }

        if (indicativosAlertaSalida !== "") {
          setTimeout(() => {
            mensajeAlertaTL(
              "Alertas",
              "Id/s " +
                indicativosAlertaSalida +
                " están en alerta en la salida del móvil !.",
              "warn"
            );
          }, 1000);
        }

        if (indicativosDemoraLlegada !== "") {
          setTimeout(() => {
            mensajeAlertaTL(
              "Alertas",
              "Id/s " +
                indicativosDemoraLlegada +
                " están demorados en la llegada al destino !.",
              "error"
            );
          }, 2000);
        }

        if (indicativosAlertaLlegada !== "") {
          setTimeout(() => {
            mensajeAlertaTL(
              "Alertas",
              "Id/s " +
                indicativosAlertaLlegada +
                " están en alerta en la llegada al destino !.",
              "warn"
            );
          }, 3000);
        }

        if (indicativosPendiente !== "") {
          mensajeAlertaBL(
            "Alertas",
            "Id/s " +
              indicativosPendiente +
              " están pendientes de asignar móvil !.",
            "info"
          );
        }

        if (infoAppRecepcionado !== "") {
          setTimeout(() => {
            mensajeAlertaBL(
              "Aviso",
              "Id/s " +
                infoAppRecepcionado +
                " se han recepcionado correctamente en la App !.",
              "success"
            );
          }, 1000);
        }
      }

      if (bandServicio && toast.current !== null) {
        mostrarMensajeNuevoServicio(despachos);
      }

      if (bandAlerta && toastTC.current !== null) {
        mostrarAlertas(idAlerta, mensajeAlerta);
      }

      setTimer(new Date());
    }
    setEstadoTomado(true);
  }
  /* FIN */

  function formatDateTime(dateString) {
    var dateSplit = dateString.split("/");
    var newDate = dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2];
    return newDate;
  }

  const rowClass = (rowData) => {
    if (rowData.color === "NEGRO" && rowData.estado === "LLEGADA") {
      let che = new Date(formatDateTime(rowData.llegada));
      let currentTime = new Date();
      const diffTime = (currentTime.getTime() - che.getTime()) / 1000 / 60;
      return {
        "row-negro":
          configuracion &&
          configuracion.escudero &&
          Number(diffTime) > Number(configuracion.escudero),
      };
    }
    if (
      rowData.color === "AZUL" &&
      rowData.estado !== "TOMADO" &&
      configuracion.destinatario === 4
    ) {
      return "row-celeste";
    }

    const index = rowData.estado.indexOf("DEMORADO");
    return {
      "row-bordo":
        rowData.color === "ROJO" &&
        rowData.motivoConsulta === "PAMI.SUPER ROJO" &&
        index !== -1,
      "row-rojo":
        rowData.color === "ROJO" &&
        rowData.motivoConsulta !== "PAMI.SUPER ROJO" &&
        index !== -1,
      //"row-rojo": rowData.color === "ROJO" && index !== -1,
      //"row-amarillo": rowData.color === "AMARILLO" && index !== -1,
      /*"row-verde": rowData.color === "VERDE" && index !== -1,*/
    };
  };

  const indicativoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">
          {configuracion.destinatario !== 2 ? "Indicativo" : "Ind. - Id"}
        </span>
        {configuracion.destinatario !== 2 ? rowData.id : rowData.indicativoId}
        {rowData.reclamado ? (
          <MoodBadIcon style={{ fontSize: "small", color: "red" }} />
        ) : null}
      </Fragment>
    );
  };

  const colorBodyTemplate = (rowData) => {
    const colorClassName = classNames({
      bordo:
        rowData.color === "ROJO" &&
        rowData.motivoConsulta === "PAMI.SUPER ROJO",
      rojo:
        rowData.color === "ROJO" &&
        rowData.motivoConsulta !== "PAMI.SUPER ROJO",
      amarillo: rowData.color === "AMARILLO",
      verde: rowData.color === "VERDE",
      azul: rowData.color === "AZUL",
      celeste: rowData.color === "CELESTE",
      fuccia: rowData.color === "FUCCIA",
      naranja: rowData.color === "NARANJA",
      blanco: rowData.color === "BLANCO",
      negro: rowData.color === "NEGRO",
    });

    return (
      <Fragment>
        <span className="p-column-title">Color</span>
        <div className={colorClassName}>
          {rowData.color + " / " + rowData.motivoConsulta}
        </div>
      </Fragment>
    );
  };

  const grupoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Grupo</span>
        {rowData.grupo}
      </Fragment>
    );
  };

  const domicilioBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const localidadBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Localidad</span>
        {!rowData.fueraZona
          ? rowData.localidad
          : rowData.localidad + " " + "(FZ)"}
      </Fragment>
    );
  };

  const nombreBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const edadBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Edad</span>
        {rowData.edad}
      </Fragment>
    );
  };

  const tripula = (movil) => {
    return tripulaciones.find((item) => item.movil === movil).servicioId;
  };

  const simultaneo = (color) => {
    return colores.find((item) => item.nombre === color).simultaneo;
  };

  const movilBodyTemplate = (rowData) => {
    const colorClassName = classNames({
      rojo: rowData.color === "ROJO",
      amarillo: rowData.color === "AMARILLO",
      verde: rowData.color === "VERDE",
      azul: rowData.color === "AZUL",
      celeste: rowData.color === "CELESTE",
      fuccia: rowData.color === "FUCCIA",
      naranja: rowData.color === "NARANJA",
      blanco: rowData.color === "BLANCO",
      negro: rowData.color === "NEGRO",
    });

    let base = rowData.base !== null ? " - " + rowData.base : "";

    return (
      <Fragment>
        {rowData.recepcionApp !== null ? (
          <Fragment>
            <div className={colorClassName}>
              <span className="p-column-title">Móvil</span>
              {rowData.movilId !== null
                ? Number(rowData.movilId) >= 1000
                  ? rowData.detalleDerivado !== null &&
                    rowData.detalleDerivado.trim() !== ""
                    ? "Móvil" +
                      " " +
                      rowData.detalleDerivado +
                      "-" +
                      rowData.movilId
                    : "Móvil" + " " + rowData.movilId
                  : rowData.movilDetalle !== null &&
                    rowData.movilDetalle.trim() !== ""
                  ? "Móvil" +
                    " " +
                    rowData.movilDetalle +
                    "-" +
                    rowData.movilId +
                    base
                  : "Móvil" + " " + rowData.movilId + base
                : null}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <span className="p-column-title">Móvil</span>
            {rowData.movilId !== null
              ? Number(rowData.movilId) >= 1000
                ? rowData.detalleDerivado !== null &&
                  rowData.detalleDerivado.trim() !== ""
                  ? "Móvil" +
                    " " +
                    rowData.detalleDerivado +
                    "-" +
                    rowData.movilId
                  : "Móvil" + " " + rowData.movilId
                : rowData.movilDetalle !== null &&
                  rowData.movilDetalle.trim() !== ""
                ? "Móvil" +
                  " " +
                  rowData.movilDetalle +
                  "-" +
                  rowData.movilId +
                  base
                : "Móvil" + " " + rowData.movilId + base
              : null}
          </Fragment>
        )}
      </Fragment>
    );
  };

  const movilBodyTemplateBak = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId} {"   "}
        {tripulaciones &&
        colores &&
        rowData.movilId !== null &&
        rowData.id === tripula(rowData.movilId) &&
        simultaneo(rowData.color) ? (
          <Fragment>
            <AdjustIcon style={{ fontSize: "small" }} />
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  const estadoBodyTemplate = (rowData) => {
    const colorClassName = classNames({
      rojo: rowData.color === "ROJO",
      amarillo: rowData.color === "AMARILLO",
      verde: rowData.color === "VERDE",
      azul: rowData.color === "AZUL",
      celeste: rowData.color === "CELESTE",
      fuccia: rowData.color === "FUCCIA",
      naranja: rowData.color === "NARANJA",
      blanco: rowData.color === "BLANCO",
      negro: rowData.color === "NEGRO",
    });

    return (
      <Fragment>
        {rowData.estado.substring(0, 8) === "TOMADO /" ? (
          <HouseIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 6) === "TOMADO" ? (
          <HouseIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 8) === "PASADO /" ? (
          <PhoneForwardedIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 6) === "PASADO" ? (
          <PhoneForwardedIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 8) === "SALIDA /" ? (
          <LocalShippingIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 6) === "SALIDA" ? (
          <LocalShippingIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 9) === "LLEGADA /" ? (
          <FlagIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 7) === "LLEGADA" ? (
          <FlagIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 8) === "TRASLADO" ? (
          <ImportExportIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        <span className="p-column-title">Estado</span>
        {rowData.estado}
      </Fragment>
    );
  };

  const programadoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Programado</span>
        {rowData.llegadaProgramada}
      </Fragment>
    );
  };

  const tomadoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tomado</span>
        {rowData.tomado}
      </Fragment>
    );
  };

  const pasadoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pasado</span>
        {rowData.pasado}
      </Fragment>
    );
  };

  const salidaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Salida</span>
        {rowData.salida}
      </Fragment>
    );
  };

  const llegadaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {rowData.llegada}
      </Fragment>
    );
  };

  const consultaSocio = (rowData) => {
    guardarServicio({
      ...rowData,
      origen: "despachos",
    });
    setVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          onClick={(e) => {
            setSelectedRow(rowData);
            guardarServicioSeleccionado(null);
            consultaSocio(rowData);
            obtenerNotas({ servicioId: rowData.id });
          }}
          tooltip="Datos del servicio"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />

        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-plus-circle"
          className="p-button-success p-button-text"
          onClick={(e) => {
            setSelectedRow(rowData);
            guardarServicioSeleccionado(rowData);
          }}
          tooltip="Acciones"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <Fragment>
        <div className="p-grid">
          <Button
            icon="pi pi-align-justify"
            className="p-button-rounded p-button-secondary"
            tooltip="Resumen"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            onClick={handleDrawerOpenResumen}
          />

          <Button
            icon="pi pi-map-marker"
            className="p-button-rounded p-button-primary"
            tooltip="Mapa"
            style={{ marginLeft: "0.5em" }}
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            onClick={() => window.open("/mapa", "_blank")}
          />

          <Button
            icon="pi pi-ban"
            className="p-button-rounded p-button-warning"
            tooltip="Estado Móviles"
            style={{ marginLeft: "0.5em" }}
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            onClick={handleDrawerOpenEstadoMoviles}
          />

          <Button
            icon="pi pi-truck"
            className="p-button-rounded p-button-danger"
            tooltip="Móviles"
            style={{ marginLeft: "0.5em" }}
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            onClick={handleDrawerOpenMoviles}
          />
        </div>
        <div className="p-grid">
          <MultiSelect
            style={{ marginLeft: "1vw", fontSize: "xx-small" }}
            value={selectedZona}
            options={items}
            onChange={(e) => {
              if (e.value) {
                if (e.value.length !== 0 && e.value.length !== 2) {
                  localStorage.setItem("zona", e.value);
                } else {
                  localStorage.setItem("zona", "");
                }
                setSelectedZona(e.value);
                setTimeout(() => {
                  obtenerServiciosPendientes();
                }, 1000);
              }
            }}
            optionLabel="label"
            placeholder="Seleccione Zona"
            display="chip"
          />

          <MultiSelect
            style={{ marginLeft: "1vw", fontSize: "xx-small" }}
            value={selectedColors}
            options={colors}
            onChange={(e) => {
              if (e.value) {
                localStorage.setItem("colores", e.value);
                setSelectedColors(e.value);
                setTimeout(() => {
                  obtenerServiciosPendientes();
                }, 1000);
              }
            }}
            optionLabel="label"
            placeholder="Seleccione Colores"
            display="chip"
          />

          <MultiSelect
            style={{ marginLeft: "1vw", fontSize: "xx-small" }}
            value={selectedMovils}
            options={movils}
            onChange={(e) => {
              if (e.value) {
                if (e.value.length !== 0 && e.value.length !== 3) {
                  localStorage.setItem("moviles", e.value);
                } else {
                  localStorage.setItem("moviles", "");
                }
                setSelectedMovils(e.value);
                setTimeout(() => {
                  obtenerServiciosPendientes();
                }, 1000);
              }
            }}
            optionLabel="label"
            placeholder="Seleccione Móviles"
            display="chip"
          />
        </div>
      </Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Fragment>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={(e) => exportExcel()}
        />
        <span className="p-input-icon-left" style={{ marginLeft: ".5em" }}>
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
      </Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div align="left">
          <Tag value="Datos del Servicio"></Tag>
        </div>
      </Fragment>
    );
  };

  const renderHeaderResumen = () => {
    return (
      <Fragment>
        <div align="left">
          {drawer === "resumen" ? (
            <Tag value="Total/Pendientes/Demorados"></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideResumen = (e) => {
    setOpenDrawer(false);
    guardarServicioSeleccionado(null);
  };

  const confirmarAppDialogFooter = (
    <Fragment>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={(e) => aceptarAppNotificacion()}
      />
      {/*
      <Button
        label="No"
        icon="pi pi-clock"
        className="p-button-text"
        onClick={(e) => hideConfirmarAppNotificacion()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={(e) => cancelarAppNotificacion()}
      />
      */}
    </Fragment>
  );

  const confirmarAppDialogHeader = () => {
    return (
      <div>
        <Tag value="Aviso de Móvil Liberado"></Tag>
      </div>
    );
  };

  const aceptarAppNotificacion = () => {
    setTripulacionApp(null);
    setConfirmarApp(false);

    setTimeout(() => {
      confirmarMovilLiberado({ tripulacionId: tripulacionApp.id });
    }, 20000);
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(despachos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "serviciosPendientes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  function mostrarMovilLiberado() {
    toast.current.show({
      severity: "info",
      summary: "Información",
      detail: `El móvil ${tripulacionApp.movilId} con tripulación ${
        tripulacionApp.medico ? tripulacionApp.medico : ""
      }-${tripulacionApp.chofer ? tripulacionApp.chofer : ""}-${
        tripulacionApp.enfermero ? tripulacionApp.enfermero : ""
      } quedó liberado.`,
      life: 5000,
      sticky: true,
    });
    setTripulacionApp(null);
    setTimeout(() => {
      confirmarMovilLiberado({ tripulacionId: tripulacionApp.id });
    }, 10000);
  }

  function mostrarMensajeNuevoServicio(desp) {
    let newElement = null;

    for (var i = desp.length - 1; i >= 0; i--) {
      if (desp[i].nuevoServicio && !desp[i].generaNuevo) {
        newElement = {
          id: desp[i].id,
          nombreTitular: desp[i].nombreTitular,
          color: desp[i].color,
          titularId: desp[i].titularId,
          domicilio: desp[i].domicilio,
          status: 0,
          generaNuevo: false,
        };
        break;
      }
      if (desp[i].nuevoServicio && desp[i].generaNuevo) {
        newElement = {
          id: desp[i].id,
          nombreTitular: desp[i].nombreTitular,
          color: desp[i].color,
          titularId: desp[i].titularId,
          domicilio: desp[i].domicilio,
          status: 0,
          generaNuevo: true,
        };
        break;
      }
    }

    const index = desp.findIndex(
      (item) =>
        item.titularId === newElement.titularId && item.id !== newElement.id
    );

    if (configuracion && configuracion.pami === newElement.titularId) {
      if (newElement && newElement.status === 0) {
        let id = newElement.id;

        toast.current.show({
          severity: "warn",
          summary: "Nuevo Servicio",
          detail: `La empresa ${newElement.nombreTitular} ha agregado un servicio ${newElement.color} con el ID. ${newElement.id} en domicilio ${newElement.domicilio}.`,
          life: 5000,
          sticky: true,
        });

        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 30000);
      }
    }

    if (configuracion && configuracion.pami !== newElement.titularId) {
      if (
        newElement &&
        newElement.status === 0 &&
        !newElement.generaNuevo &&
        index !== -1
      ) {
        let id = newElement.id;
        let indexId = desp[index].id;

        toast.current.show({
          severity: "warn",
          summary: "Nuevo Servicio",
          detail: `Ya existe un servicio pendiente del Afiliado ${newElement.nombreTitular} con el ID. ${indexId}.`,
          life: 5000,
          sticky: false,
        });

        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 30000);
      }
    }

    if (configuracion && configuracion.pami !== newElement.titularId) {
      if (newElement && newElement.status === 0 && newElement.generaNuevo) {
        let id = newElement.id;

        toast.current.show({
          severity: "warn",
          summary: "Nuevo Servicio",
          detail: `Nuevo servicio generado desde la app del afiliado ${newElement.nombreTitular} con el ID. ${id}.`,
          life: 5000,
          sticky: true,
        });

        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 30000);
      } else {
        let id = newElement.id;
        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 1000);
      }
    }
  }

  function mostrarAlertas(id, mensaje) {
    toastTC.current.show({
      severity: "info",
      summary: "Info",
      detail: mensaje,
      life: 5000,
      sticky: false,
    });

    setTimeout(() => {
      confirmarNuevoServicioPrestador({ id: id });
    }, 30000);
  }

  const handleCloseMp = () => {
    setOpenMp(false);
    setCelular(null);
    setLinkMp(null);
    window.close();
  };

  const onClose = (mywindow) => {
    let mw = mywindow;
    console.log(mw);
    setTimeout(() => {
      console.log(mw);
      mywindow.close();
    }, 10000);
  };

  const renderHeaderMp = () => {
    return (
      <div>
        <Tag value="Link Mercado Pago"></Tag>
      </div>
    );
  };

  const renderFooterMp = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleCloseMp()}
          className="p-button-text"
          autoFocus
        />

        <Button
          label="Enviar"
          icon="pi pi-send"
          onClick={(event) => {
            event.preventDefault();
            window.open(
              "https://wa.me/" +
                "549" +
                celular +
                "?text=Le enviamos el link de Mercado Pago para poder abonar el coseguro " +
                linkMp,
              "_blank"
            );
          }}
          className="p-button-text"
          autoFocus
        />

        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleCloseMp()}
          className="p-button-text"
        />
      </div>
    );
  };

  //console.log(visible)
  //console.log(despachos);
  //console.log(trasladosServicios);

  return (
    <Root className={classes.root}>
      <Barra />
      <Fragment>
        <Toast ref={toast} />
        <Toast ref={toastTC} position="top-center" />
        {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
        {statusAcceso === 400 ? accesoDenegado() : null}
        {statusAcceso === 200 ? (
          despachos && bandStart ? (
            <Fragment>
              <CssBaseline />
              <div className="datatable-responsive-demo pdatatable">
                <div className="card">
                  <Toolbar
                    className="ptoolbar p-mb-4"
                    //style={{ padding: "0.3em", marginTop: "5vw", marginBottom: "10vw" }}
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}
                  ></Toolbar>
                  <DataTable
                    value={despachos}
                    selection={selectedRow}
                    rowClassName={rowClass}
                    onSelectionChange={(e) => {
                      if (selectedRow !== null) {
                        if (selectedRow.id == e.value.id) {
                          setSelectedRow(null);
                        } else {
                          setSelectedRow(e.value);
                        }
                      } else {
                        setSelectedRow(e.value);
                      }
                    }}
                    onRowClick={(e) => {
                      if (e.originalEvent.target.nodeName !== "SPAN") {
                        guardarServicioSeleccionado(null);
                      }
                    }}
                    selectionMode="single"
                    showGridlines
                    dataKey="id"
                    //header={header}
                    className="p-datatable-sm p-datatable-responsive-demo pdatatable"
                    //tableStyle={{ fontSize: "xx-small", marginTop: "4.5vw" }}
                    globalFilter={globalFilter}
                    emptyMessage="No hay datos."
                    paginator
                    size="small"
                    //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={100}
                    rowsPerPageOptions={[10, 20, 50, 100, 500]}
                    //paginatorLeft={paginatorLeft}
                    //paginatorRight={paginatorRight}
                  >
                    <Column
                      field={
                        configuracion && configuracion.destinatario !== 2
                          ? "id"
                          : "indicativoId"
                      }
                      header={
                        configuracion && configuracion.destinatario !== 2
                          ? "ind."
                          : "Ind. - Id"
                      }
                      className="colIndicativo"
                      body={indicativoBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="color"
                      header="Color"
                      className="colColor"
                      body={colorBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="grupo"
                      header="Grupo"
                      className="colGrupo"
                      body={grupoBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="domicilio"
                      header="Domicilio"
                      className="colDomicilio"
                      body={domicilioBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="localidad"
                      header="Localidad"
                      className="colLocalidad"
                      body={localidadBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="edad"
                      header="Edad"
                      className="colEdad"
                      body={edadBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="nombre"
                      header="Nombre"
                      className="colNombre"
                      body={nombreBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="movilId"
                      header="Móvil"
                      className="colMovil"
                      body={movilBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="llegadaProgramada"
                      header="Programado"
                      className="colHorarios"
                      body={programadoBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="estado"
                      header="Estado"
                      className="colEstado"
                      body={estadoBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="tomado"
                      header="Tomado"
                      className="colHorarios"
                      body={tomadoBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="pasado"
                      header="Pasado"
                      className="colHorarios"
                      body={pasadoBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="salida"
                      header="Salida"
                      className="colHorarios"
                      body={salidaBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="llegada"
                      header="Llegada"
                      className="colHorarios"
                      body={llegadaBodyTemplate}
                      sortable
                    ></Column>
                    <Column
                      className="colBotones"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </div>
              </div>
              {servicioSeleccionado ? (
                <SpeedDialIcon desde="despachos" />
              ) : null}
            </Fragment>
          ) : null
        ) : null}
        <div className="dialog-demo p-dialog-despacho">
          <div className="card">
            <Dialog
              header={renderHeader}
              visible={visible}
              icon="pi pi-external-link"
              closable={true}
              maximizable={false}
              maximized={false}
              onHide={() => onHide()}
              //footer={renderFooter()}
              breakpoints={{ "960px": "75vw" }}
              style={{
                width: "100vw",
                padding: "0",
                overflowY: "visible",
              }}
              position="top-right"
            >
              <Grid container spacing={1} style={{ padding: "0" }}>
                <Grid item xs={12} md={8}>
                  <ScrollPanel style={{ width: "100%", height: "100%" }}>
                    <div style={{ padding: "0em", lineHeight: "1.5" }}>
                      <ConsultaServicio />
                    </div>
                  </ScrollPanel>
                </Grid>

                <Grid item xs={12} md={4}>
                  <ScrollPanel
                    style={{ width: "100%", height: "100%" }}
                    className="custombar1"
                  >
                    <div style={{ padding: "0em", lineHeight: "1.5" }}>
                      {notas &&
                      notas.length > 0 &&
                      selectedRow &&
                      notas[0].servicioId === selectedRow.id ? (
                        <NotasServicio />
                      ) : (
                        <Typography variant="h6" gutterBottom>
                          Cargando notas ...
                        </Typography>
                      )}
                    </div>
                  </ScrollPanel>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              header={renderHeaderResumen}
              visible={openDrawer}
              icon="pi pi-external-link"
              closable={true}
              onHide={() => onHideResumen()}
              //footer={renderFooter()}
              breakpoints={{ "900px": "90vw" }}
              style={{
                width: drawer === "resumen" ? "30vw" : "30vw",
                padding: "0",
                overflowY: "visible",
              }}
              position="top-left"
            >
              <Grid container spacing={1} style={{ padding: "0" }}>
                {drawer === "resumen" ? <ResumenServicio /> : null}
                {drawer === "moviles" ? <ResumenMoviles /> : null}
                {drawer === "estado" ? <ResumenEstadoMoviles /> : null}
              </Grid>
            </Dialog>

            {tripulacionApp
              ? mostrarMovilLiberado()
              : /*
            <Dialog
              header={confirmarAppDialogHeader}
              visible={confirmarApp}
              closable={false}
              footer={confirmarAppDialogFooter}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "50vw", paddingBottom: "0" }}
              position="center"
            >
              <h4
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >{`El móvil ${tripulacionApp.movilId} con tripulación ${
                tripulacionApp.medico ? tripulacionApp.medico : ""
              }-${tripulacionApp.chofer ? tripulacionApp.chofer : ""}-${
                tripulacionApp.enfermero ? tripulacionApp.enfermero : ""
              } quedó liberado.`}</h4>
            </Dialog>
            */
                null}
          </div>
        </div>

        <Dialog
          header={renderHeaderMp()}
          visible={openMp}
          footer={renderFooterMp()}
          closable={false}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "75vw", paddingBottom: "0" }}
        >
          <Grid item xs={12} md={12}>
            <TextField
              id="celular"
              label="Celular"
              value={celular ? celular : null}
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={(e) => setCelular(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="link"
              label="Link"
              value={linkMp ? linkMp : null}
              fullWidth
              multiline
              disabled
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              //onChange={(e) => setCelular(e.target.value)}
            />
          </Grid>
        </Dialog>
      </Fragment>
    </Root>
  );
}
