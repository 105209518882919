import React, { useState, Fragment, useContext, useRef } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";
import { v4 as uuidv4 } from "uuid";

const ConfiguracionContext = createContext();
const { Provider, Consumer } = ConfiguracionContext;

function ConfiguracionProvider({ children }) {
  const [numeroTelefono, guardarNumeroTelefono] = useState(null);
  const [valBusqueda, guardarValBusqueda] = useState(null);
  const [rows, setRows] = useState(null);
  const [afiliado, guardarAfiliado] = useState(null);
  const [titular, guardarTitular] = useState(null);
  const [antecedentes, guardarAntecedentes] = useState([]);
  const [antecedente, guardarAntecedente] = useState(null);
  const [tratamientos, guardarTratamientos] = useState([]);
  const [tratamiento, guardarTratamiento] = useState(null);
  const [tiposServicios, guardarTiposServicios] = useState([]);
  const [tipoServicio, guardarTipoServicio] = useState(null);
  const [motivosConsultas, guardarMotivosConsultas] = useState([]);
  const [motivoConsulta, guardarMotivoConsulta] = useState(null);
  const [usuarios, guardarUsuarios] = useState([]);
  const [pantallas, guardarPantallas] = useState([]);
  const [colores, guardarColores] = useState([]);
  const [coloresM, guardarColoresM] = useState([]);
  const [color, guardarColor] = useState(null);
  const [configuracion, guardarConfiguracion] = useState(null);
  const [statusAcceso, setStatusAcceso] = useState(0);
  const [statusAcceso2, setStatusAcceso2] = useState(0);
  const [statusAcceso3, setStatusAcceso3] = useState(0);
  const [localidades, guardarLocalidades] = useState([]);
  const [provincias, guardarProvincias] = useState([]);
  const [medicos, guardarMedicos] = useState([]);
  const [medicosRecetas, guardarMedicosRecetas] = useState([]);
  const [medico, guardarMedico] = useState(null);
  const [choferes, guardarChoferes] = useState([]);
  const [chofer, guardarChofer] = useState(null);
  const [enfermeros, guardarEnfermeros] = useState([]);
  const [enfermero, guardarEnfermero] = useState(null);
  const [tiposMoviles, guardarTiposMoviles] = useState([]);
  const [tipoMovil, guardarTipoMovil] = useState(null);
  const [moviles, guardarMoviles] = useState([]);
  const [movil, guardarMovil] = useState(null);
  const [telefonos, guardarTelefonos] = useState([]);
  const [maletin, guardarMaletin] = useState([]);
  const [telefono, guardarTelefono] = useState(null);
  const [diagnosticos, guardarDiagnosticos] = useState([]);
  const [diagnostico, guardarDiagnostico] = useState(null);
  const [almacenesMoviles, guardarAlmacenesMoviles] = useState([]);
  const [almacenMovil, guardarAlmacenMovil] = useState(null);
  const [almacenes, guardarAlmacenes] = useState([]);
  const [almacen, guardarAlmacen] = useState(null);
  const [bases, guardarBases] = useState([]);
  const [base, guardarBase] = useState(null);
  const [medicamentos, guardarMedicamentos] = useState([]);
  const [medicamento, guardarMedicamento] = useState(null);
  const [alertas, guardarAlertas] = useState(null);
  const [movimientoMedicamento, guardarMovimientoMedicamento] = useState(null);
  const [movimientosMedicamentos, guardarMovimientosMedicamentos] = useState(
    []
  );
  const [stocksMedicamentos, guardarStocksMedicamentos] = useState([]);
  const [ajustesStocksMedicamentos, guardarAjustesStocksMedicamentos] =
    useState([]);
  const [grupos, guardarGrupos] = useState([]);
  const [rubros, guardarRubros] = useState([]);
  const [planes, guardarPlanes] = useState([]);
  const [coseguros, guardarCoseguros] = useState([]);
  const [coseguro, guardarCoseguro] = useState(null);
  const [coseguroServicio, guardarCoseguroServicio] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [parametros, setParametros] = useState(null);
  /*
  const [parametros, setParametros] = useState({
    edadPediatrico: 12,
    diurnoDesde: "07:00",
    diurnoHasta: "17:00",
    nocturnoDesde: "17:00",
    nocturnoHasta: "07:00",
    medicamentos: false,
  });
  */

  const [coseguroAbonar, guardarCoseguroAbonar] = useState(0);
  const [zonasMovilesDerivados, guardarZonasMovilesDerivados] = useState([]);
  const [feriados, guardarFeriados] = useState([]);
  const [valorizaciones, guardarValorizaciones] = useState([]);
  const [valorizar, guardarValorizar] = useState(null);
  const [topes, guardarTopes] = useState([]);
  const [tope, guardarTope] = useState(null);
  const [delay, setDelay] = useState(1000);
  const [adicionales, guardarAdicionales] = useState(null);
  const [empresas, guardarEmpresas] = useState(null);
  const [tickets, guardarTickets] = useState([]);
  const [diagnosticosPami, guardarDiagnosticosPami] = useState([]);
  const [urgenciasPami, guardarUrgenciasPami] = useState([]);
  const [destinosPami, guardarDestinosPami] = useState([]);
  const [convenios, guardarConvenios] = useState([]);
  const [convenio, guardarConvenio] = useState(null);
  const [obrasSocialesMedicas, guardarObrasSocialesMedicas] = useState(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  //Tickets

  const obtenerTicketsAll = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerticketsall",
        datos
      );

      let data = respuesta.data;

      guardarTickets(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTickets = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertickets",
        datos
      );

      let data = respuesta.data;

      guardarTickets(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTicket = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarticket",
        datos
      );

      mensajeAlerta("Actualizar Ticket", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Actualizar Ticket", error.response.data, "error");
    }
  };

  const agregarTicket = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarticket",
        datos
      );

      mensajeAlerta("Agregar Ticket", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Agregar Ticket", error.response.data, "error");
    }
  };

  //Antecedentes

  const obtenerAntecedentes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerantecedentes",
        datos
      );

      let data = respuesta.data;

      guardarAntecedentes(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAntecedente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerantecedente",
        datos
      );

      let data = respuesta.data;

      guardarAntecedente(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Antecedentes", error.response.data, "error");
    }
  };

  const actualizarAntecedente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarantecedente",
        datos
      );

      mensajeAlerta("Antecedesntes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Antecedesntes", error.response.data, "error");
    }
  };

  const agregarAntecedente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarantecedente",
        datos
      );

      mensajeAlerta("Antecedesntes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Antecedesntes", error.response.data, "error");
    }
  };

  //Tratamientos

  const obtenerTratamientos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertratamientos",
        datos
      );

      let data = respuesta.data;

      guardarTratamientos(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTratamiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertratamiento",
        datos
      );

      let data = respuesta.data;

      guardarTratamiento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tratamientos", error.response.data, "error");
    }
  };

  const actualizarTratamiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartratamiento",
        datos
      );

      mensajeAlerta("Tratamientos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tratamientos", error.response.data, "error");
    }
  };

  const agregarTratamiento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartratamiento",
        datos
      );

      mensajeAlerta("Tratamientos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tratamientos", error.response.data, "error");
    }
  };

  //Tipos de servicios

  const obtenerTiposServicios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposservicios",
        datos
      );

      let data = respuesta.data;

      guardarTiposServicios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Servicios", error.response.data, "error");
    }
  };

  const obtenerTipoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposervicio",
        datos
      );

      let data = respuesta.data;

      guardarTipoServicio(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Servicios", error.response.data, "error");
    }
  };

  const actualizarTipoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartiposervicio",
        datos
      );

      mensajeAlerta("Tipos de Servicios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Servicios", error.response.data, "error");
    }
  };

  const agregarTipoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartiposervicio",
        datos
      );

      mensajeAlerta("Tipos de Servicios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Servicios", error.response.data, "error");
    }
  };

  //Motivos de consultas

  const obtenerMotivosConsultas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermotivosconsultas",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          nombre: data[i].nombre,
          color: data[i].colores.nombre,
          colorId: data[i].id_color,
          servicio: data[i].tiposServicios.nombre,
          servicioId: data[i].id_servicio,
          cuestionario: data[i].cuestionario,
          habilitado: data[i].habilitado,
          codColor: data[i].colores.codigo,
          traslado: data[i].colores.traslado,
        });
      }

      guardarMotivosConsultas(local);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermotivoconsulta",
        datos
      );

      let data = respuesta.data;

      guardarMotivoConsulta(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Consultas", error.response.data, "error");
    }
  };

  const actualizarMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmotivoconsulta",
        datos
      );

      mensajeAlerta("Motivos de Consultas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Consultas", error.response.data, "error");
    }
  };

  const agregarMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmotivoconsulta",
        datos
      );

      mensajeAlerta("Motivos de Consultas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Consultas", error.response.data, "error");
    }
  };

  //Usuarios

  const obtenerUsuarios = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerusuarios"
      );
      let data = respuesta.data;

      guardarUsuarios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Usuarios", error.response.data, "error");
    }
  };

  //Pantallas

  const obtenerAccesos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneraccesos",
        datos
      );
      let data = respuesta.data;

      const local = [];
      const rows = [];

      //console.log(data)

      if (data.response2.length === 0) {
        for (var i = 0; i < data.response.length; i++) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }

        guardarPantallas(local);
        return;
      }

      for (var i = 0; i < data.response.length; i++) {
        let band = false;
        for (var j = 0; j < data.response2.length; j++) {
          if (data.response[i].nombre === data.response2[j].layout) {
            band = true;
          }
        }
        if (band) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          rows.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          setRows(rows);
        } else {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }
      }

      guardarPantallas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pantallas", error.response.data, "error");
    }
  };

  const agregarAccesos = async (datos) => {
    const { rows, usuario } = datos;

    const local = [];

    if (rows !== null) {
      for (var i = 0; i < rows.length; i++) {
        local.push({
          id: 0,
          id_usuario: usuario.id,
          layout: rows[i].nombre,
          sistema: 1,
        });
      }
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaraccesos",
        { local, usuario }
      );

      mensajeAlerta("Accesos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Accesos", error.response.data, "error");
    }
  };

  const obtenerAcceso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso(200);
    } catch (error) {
      setStatusAcceso(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso2 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso2(200);
    } catch (error) {
      setStatusAcceso2(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso3 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;

      setStatusAcceso3(200);
    } catch (error) {
      setStatusAcceso3(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  //Colores

  const obtenerColores = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercolores"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          codigo: data[i].codigo,
          nombre: data[i].nombre,
          demoraLlegada: data[i].demoraLlegada,
          alertaLlegada: data[i].alertaLlegada,
          demoraSalida: data[i].demoraSalida,
          alertaSalida: data[i].alertaSalida,
          medico: data[i].medico,
          chofer: data[i].chofer,
          enfermero: data[i].enfermero,
          simultaneo: data[i].simultaneo,
          alertaPendiente: data[i].alertaPendiente,
          traslado: data[i].traslado,
        });
      }
      guardarColores(local);
      guardarColoresM(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Colores", error.response.data, "error");
    }
  };

  const obtenerColor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercolor",
        datos
      );

      let data = respuesta.data;

      guardarColor(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Colores", error.response.data, "error");
    }
  };

  const actualizarColor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcolor",
        datos
      );

      mensajeAlerta("Colores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Colores", error.response.data, "error");
    }
  };

  //Configuracion

  const obtenerConfiguracion = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconfiguracion"
      );
      let data = respuesta.data;

      //console.log(data)
      guardarConfiguracion(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarConfiguracion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconfiguracion",
        datos
      );

      mensajeAlerta("Configuración", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Configuración", error.response.data, "error");
    }
  };

  //Medicos

  const obtenerMedicosLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicos",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandMedico;
      for (var i = 0; i < data.length; i++) {
        bandMedico = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].medicoId) {
            bandMedico = true;
            break;
          }
        }
        if (!bandMedico) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarMedicos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Médicos", error.response.data, "error");
    }
  };

  const obtenerMedicos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicos",
        datos
      );

      let data = respuesta.data;

      guardarMedicos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Médicos", error.response.data, "error");
    }
  };

  const obtenerMedicosRecetas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicosrecetas",
        datos
      );

      let data = respuesta.data;

      guardarMedicosRecetas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Médicos", error.response.data, "error");
    }
  };

  const obtenerMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedico",
        datos
      );

      let data = respuesta.data;

      guardarMedico(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmedico",
        datos
      );

      mensajeAlerta("Médicos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Médicos", error.response.data, "error");
    }
  };

  const agregarMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmedico",
        datos
      );

      mensajeAlerta("Médicos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Médicos", error.response.data, "error");
    }
  };

  const guardarFirmaMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/firmamedico",
        datos
      );

      mensajeAlerta("Firma Médico", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Firma Médico", error.response.data, "error");
    }
  };

  const resetPasswordMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/resetpasswordmedico",
        datos
      );

      mensajeAlerta("Reset Password", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Reset Password", error.response.data, "error");
    }
  };

  const registroMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/registromedico",
        datos
      );

      mensajeAlerta("Registro Médico", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Firma Médico", error.response.data, "error");
    }
  };

  //Choferes

  const obtenerChoferesLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerchoferes",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandChofer;
      for (var i = 0; i < data.length; i++) {
        bandChofer = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].choferId) {
            bandChofer = true;
            break;
          }
        }

        if (!bandChofer) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarChoferes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Choferes", error.response.data, "error");
    }
  };

  const obtenerChoferes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerchoferes",
        datos
      );

      let data = respuesta.data;

      if (datos && datos.liquidacion === undefined) {
        guardarChoferes(data);
      } else {
        data.push({
          id: 9999,
          nombre: "TODOS",
        });
        guardarChoferes(data);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Choferes", error.response.data, "error");
    }
  };

  const obtenerChofer = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerchofer",
        datos
      );

      let data = respuesta.data;

      guardarChofer(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarChofer = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarchofer",
        datos
      );

      mensajeAlerta("Choferes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Choferes", error.response.data, "error");
    }
  };

  const agregarChofer = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarchofer",
        datos
      );

      mensajeAlerta("Choferes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Choferes", error.response.data, "error");
    }
  };

  //Enfermeros

  const obtenerEnfermerosLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerenfermeros",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandEnfermero;
      for (var i = 0; i < data.length; i++) {
        bandEnfermero = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].enfermeroId) {
            bandEnfermero = true;
            break;
          }
        }

        if (!bandEnfermero) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarEnfermeros(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Enfermeros", error.response.data, "error");
    }
  };

  const obtenerEnfermeros = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerenfermeros",
        datos
      );

      let data = respuesta.data;
      if (datos && datos.liquidacion === undefined) {
        guardarEnfermeros(data);
      } else {
        data.push({
          id: 9999,
          nombre: "TODOS",
        });
        guardarEnfermeros(data);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Enfermeros", error.response.data, "error");
    }
  };

  const obtenerEnfermero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerenfermero",
        datos
      );

      let data = respuesta.data;

      guardarEnfermero(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarEnfermero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarenfermero",
        datos
      );

      mensajeAlerta("Enfermeros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Enfermeros", error.response.data, "error");
    }
  };

  const agregarEnfermero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarenfermero",
        datos
      );

      mensajeAlerta("Enfermeros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Enfermeros", error.response.data, "error");
    }
  };

  // Empresas

  const obtenerEmpresas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerempresas",
        datos
      );

      let data = respuesta.data;

      guardarEmpresas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Empresas", error.response.data, "error");
    }
  };

  //Tipos de móviles

  const obtenerTiposMoviles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposmoviles",
        datos
      );

      let data = respuesta.data;

      guardarTiposMoviles(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Móviles", error.response.data, "error");
    }
  };

  const obtenerTipoMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertipomovil",
        datos
      );

      let data = respuesta.data;

      guardarTipoMovil(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Móviles", error.response.data, "error");
    }
  };

  const actualizarTipoMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartipomovil",
        datos
      );

      mensajeAlerta("Tipos de Móviles", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Móviles", error.response.data, "error");
    }
  };

  const agregarTipoMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartipomovil",
        datos
      );

      mensajeAlerta("Tipos de Móviles", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Móviles", error.response.data, "error");
    }
  };

  //Moviles

  const agregarTokenMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartokenmovil",
        datos
      );

      mensajeAlerta("Móviles", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const obtenerMoviles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermoviles",
        datos
      );

      let data = respuesta.data;

      const local = [];

      const { propios } = datos;

      for (var i = 0; i < data.length; i++) {
        if (propios) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
            propio: data[i].propio,
            detalle: data[i].detalle,
            matricula: data[i].matricula,
            marca: data[i].marca,
            modelo: data[i].modelo,
            seguro: data[i].seguro,
            poliza: data[i].poliza,
            tipoMovil: data[i].tiposMoviles.nombre,
            telefono: data[i].telefono,
            contacto: data[i].contacto,
            nocturnoDesde: data[i].nocturnoDesde,
            nocturnoHasta: data[i].nocturnoHasta,
            pediatrico: data[i].pediatrico,
            habilitado: data[i].habilitado,
            tipoId: data[i].tipoId,
          });
        } else {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
            propio: data[i].propio,
            detalle: data[i].detalle,
            matricula: null,
            marca: null,
            modelo: null,
            seguro: null,
            poliza: null,
            tipoMovil: null,
            telefono: data[i].telefono,
            contacto: data[i].contacto,
            nocturnoDesde: data[i].nocturnoDesde,
            nocturnoHasta: data[i].nocturnoHasta,
            pediatrico: data[i].pediatrico,
            habilitado: data[i].habilitado,
            token: data[i].token,
            usuario: data[i].usuario,
            diaLimite: data[i].diaLimite,
            //clave: data[i].clave,
          });
        }
      }

      guardarMoviles(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const obtenerMovilesPropiosLiberados = async (datos) => {
    const { tripulaciones } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovilespropiosliberados"
      );

      let data = respuesta.data;

      const local = [];

      let bandMovil;
      for (var i = 0; i < data.length; i++) {
        bandMovil = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].movil) {
            bandMovil = true;
            break;
          }
        }

        if (!bandMovil) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarMoviles(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const obtenerMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovil",
        datos
      );

      let data = respuesta.data;

      guardarMovil(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const actualizarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmovil",
        datos
      );

      mensajeAlerta("Móviles", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const agregarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovil",
        datos
      );

      mensajeAlerta("Móviles", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const obtenerNuevoNumeroMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenernuevonumeromovil",
        datos
      );

      let data = respuesta.data;
      const { propio } = datos;
      let proximo = Number(data) + 1;
      let nombre = "";
      if (propio) {
        nombre = "MOVIL " + proximo;
      }

      guardarMovil({
        ...movil,
        id: proximo,
        nombre,
        propio,
        detalle: "",
        matricula: "",
        marca: "",
        modelo: "",
        seguro: "",
        poliza: "",
        id_tipo: null,
        telefono: "",
        contacto: "",
        nocturnoDesde: "",
        nocturnoHasta: "",
        pediatrico: "",
        habilitado: true,
      });
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  const obtenerZonasMovilesDerivados = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerzonasmovilesderivados"
      );

      let data = respuesta.data;
      guardarZonasMovilesDerivados(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Móviles", error.response.data, "error");
    }
  };

  //Telefonos

  const obtenerTelefonos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertelefonos",
        datos
      );

      let data = respuesta.data;

      guardarTelefonos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Teléfonos", error.response.data, "error");
    }
  };

  const obtenerTelefonosLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertelefonos",
        datos
      );

      let data = respuesta.data;
      const local = [];

      let bandTelefono;
      for (var i = 0; i < data.length; i++) {
        bandTelefono = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].telefonoId) {
            bandTelefono = true;
            break;
          }
        }
        if (!bandTelefono && data[i].registrado) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      guardarTelefonos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Teléfonos", error.response.data, "error");
    }
  };

  const obtenerTelefono = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertelefono",
        datos
      );

      let data = respuesta.data;

      guardarTelefono(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTelefono = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartelefono",
        datos
      );

      mensajeAlerta("Teléfonos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Teléfonos", error.response.data, "error");
    }
  };

  const agregarTelefono = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartelefono",
        datos
      );

      mensajeAlerta("Teléfonos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Teléfonos", error.response.data, "error");
    }
  };

  //Diagnosticos

  const obtenerDiagnosticosPami = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdiagnosticospami"
      );

      let data = respuesta.data;

      guardarDiagnosticosPami(data.diagnosticos);
      guardarUrgenciasPami(data.urgencias);
      guardarDestinosPami(data.destinos);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const obtenerDiagnosticos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdiagnosticos",
        datos
      );

      let data = respuesta.data;

      guardarDiagnosticos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const obtenerDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdiagnostico",
        datos
      );

      let data = respuesta.data;

      guardarDiagnostico(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const actualizarDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizardiagnostico",
        datos
      );

      mensajeAlerta("Diagnósticos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  const agregarDiagnostico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregardiagnostico",
        datos
      );

      mensajeAlerta("Diagnósticos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Diagnósticos", error.response.data, "error");
    }
  };

  //Grupos

  const obtenerGrupos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenergrupos"
      );

      let data = respuesta.data;

      guardarGrupos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Grupos", error.response.data, "error");
    }
  };

  //Rubros

  const obtenerRubros = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrubros"
      );

      let data = respuesta.data;

      guardarRubros(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rubros", error.response.data, "error");
    }
  };

  //Bases

  const obtenerBases = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerbases",
        datos
      );

      let data = respuesta.data;

      guardarBases(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bases Operativas", error.response.data, "error");
    }
  };

  const obtenerBase = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerbase",
        datos
      );

      let data = respuesta.data;

      guardarBase(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bases Operativas", error.response.data, "error");
    }
  };

  const actualizarBase = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarbase",
        datos
      );

      mensajeAlerta("Bases Operativas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bases Operativas", error.response.data, "error");
    }
  };

  const agregarBase = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarbase",
        datos
      );

      mensajeAlerta("Bases Operativas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bases Operativas", error.response.data, "error");
    }
  };

  // Localidades

  const obtenerLocalidades = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerlocalidades",
        datos
      );

      let data = respuesta.data;
      guardarLocalidades(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  // Localidades

  const obtenerProvincias = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerprovincias",
        datos
      );

      let data = respuesta.data;
      guardarProvincias(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  //Convenios

  const obtenerConvenios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconvenios",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      let local = [];
      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          numerodocumento: data[i].numerodocumento,
          domicilio: data[i].domicilio,
          localidadId: data[i].localidades.id,
          localidad: data[i].localidades.nombre,
          telefono1: data[i].telefono1,
          habilitado: data[i].habilitado,
        });
      }
      guardarConvenios(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Convenios", error.response.data, "error");
    }
  };

  const obtenerConvenio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconvenio",
        datos
      );

      let data = respuesta.data;

      guardarConvenio(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Convenios", error.response.data, "error");
    }
  };

  const actualizarConvenio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconvenio",
        datos
      );

      mensajeAlerta("Convenios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Convenios", error.response.data, "error");
    }
  };

  const agregarConvenio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarconvenio",
        datos
      );

      mensajeAlerta("Convenios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Convenios", error.response.data, "error");
    }
  };

  //Topes

  const obtenerTopes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertopes",
        datos
      );

      let data = respuesta.data;

      guardarTopes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Topes de Servicios", error.response.data, "error");
    }
  };

  const obtenerTope = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertope",
        datos
      );

      let data = respuesta.data;

      guardarTope(data);
    } catch (error) {
      console.log(error.response);
      guardarTope(null);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTope = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartope",
        datos
      );

      mensajeAlerta("Topes de Servicios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Topes de Servicios", error.response.data, "error");
    }
  };

  const agregarTope = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartope",
        datos
      );

      mensajeAlerta("Topes de Servicios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Topes de Servicios", error.response.data, "error");
    }
  };

  //Adicionales

  const obtenerAdicionales = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneradicionales",
        datos
      );

      let data = respuesta.data;
      guardarAdicionales(data);
    } catch (error) {
      guardarAdicionales(null);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  //Feriados

  const obtenerFeriados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerferiados",
        datos
      );
      let data = respuesta.data;
      guardarFeriados(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Feriados", error.response.data, "error");
    }
  };

  const actualizarFeriados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarferiados",
        datos
      );

      mensajeAlerta("Feriados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Feriados", error.response.data, "error");
    }
  };

  const eliminarFeriado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminarferiado",
        datos
      );

      mensajeAlerta("Feriados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Feriados", error.response.data, "error");
    }
  };

  //Alertas

  const obtenerAlertas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralertas"
      );

      let data = respuesta.data;

      guardarAlertas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Alertas", error.response.data, "error");
    }
  };

  const actualizarAlertas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizaralertas",
        datos
      );

      mensajeAlerta("Alertas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Alertas", error.response.data, "error");
    }
  };

  const obtenerCoseguros = async (datos) => {
    const { grupo } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercoseguros",
        datos
      );

      let dato = [];
      let band;
      let uid;
      let indexH;

      if (grupo === 1) {
        let planes = respuesta.data["response"];
        let tiposServicios = respuesta.data["response2"];
        let coseguros = respuesta.data["response3"];

        for (var i = 0; i < planes.length; i++) {
          for (var j = 0; j < tiposServicios.length; j++) {
            band = false;
            for (var h = 0; h < coseguros.length; h++) {
              if (
                planes[i].id_codigo === coseguros[h].planId &&
                tiposServicios[j].id === coseguros[h].servicioId
              ) {
                band = true;
                indexH = h;
              }
            }
            if (!band) {
              uid = uuidv4();
              dato.push({
                id: uid,
                grupoId: 1,
                AfiliadoId: null,
                planId: planes[i].id_codigo,
                planNombre: planes[i].nombre,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurnoPediatrico: 0,
                valorNocturnoPediatrico: 0,
                valorDiurnoAdulto: 0,
                valorNocturnoAdulto: 0,
              });
            } else {
              dato.push({
                id: coseguros[indexH].id,
                grupoId: 1,
                AfiliadoId: null,
                planId: planes[i].id_codigo,
                planNombre: planes[i].nombre,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurnoPediatrico: coseguros[indexH].valorDiurnoPediatrico,
                valorNocturnoPediatrico:
                  coseguros[indexH].valorNocturnoPediatrico,
                valorDiurnoAdulto: coseguros[indexH].valorDiurnoAdulto,
                valorNocturnoAdulto: coseguros[indexH].valorNocturnoAdulto,
              });
            }
          }
        }
        guardarCoseguros(dato);
      }
      if (grupo > 1) {
        let socios = respuesta.data["response"];
        let tiposServicios = respuesta.data["response2"];
        let coseguros = respuesta.data["response3"];
        for (var i = 0; i < socios.length; i++) {
          for (var j = 0; j < tiposServicios.length; j++) {
            band = false;
            for (var h = 0; h < coseguros.length; h++) {
              if (
                socios[i].id === coseguros[h].afiliadoId &&
                tiposServicios[j].id === coseguros[h].servicioId
              ) {
                band = true;
                indexH = h;
              }
            }
            if (!band) {
              uid = uuidv4();
              dato.push({
                id: uid,
                grupoId: grupo,
                afiliadoId: socios[i].id,
                planId: socios[i].id_plan_facturacion,
                afiliadoNombre: socios[i].nombre_razonsocial,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurnoPediatrico: 0,
                valorNocturnoPediatrico: 0,
                valorDiurnoAdulto: 0,
                valorNocturnoAdulto: 0,
              });
            } else {
              dato.push({
                id: coseguros[indexH].id,
                grupoId: 2,
                afiliadoId: socios[i].id,
                planId: socios[i].id_plan_facturacion,
                afiliadoNombre: socios[i].nombre_razonsocial,
                servicioId: tiposServicios[j].id,
                servicioNombre: tiposServicios[j].nombre,
                valorDiurnoPediatrico: coseguros[indexH].valorDiurnoPediatrico,
                valorNocturnoPediatrico:
                  coseguros[indexH].valorNocturnoPediatrico,
                valorDiurnoAdulto: coseguros[indexH].valorDiurnoAdulto,
                valorNocturnoAdulto: coseguros[indexH].valorNocturnoAdulto,
              });
            }
          }
        }
        guardarCoseguros(dato);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  const obtenerCoseguroServicio = async (datos) => {
    const { afiliado, titular, nombre } = datos;

    let d2 = new Date();

    let h2 = d2.getHours() + ":" + d2.getMinutes() + ":" + d2.getSeconds();

    /*
    let h2 = d2.toLocaleString("es-AR", {
      hour: "numeric",
      minute: "numeric",
    });
    */
    let edad = Number(afiliado.edad);

    if (
      afiliado.coseguroDiurnoPediatrico !== null &&
      parseFloat(afiliado.coseguroDiurnoPediatrico) !== 0 &&
      afiliado.coseguroNocturnoPediatrico !== null &&
      parseFloat(afiliado.coseguroNocturnoPediatrico) !== 0 &&
      afiliado.coseguroDiurnoAdulto !== null &&
      parseFloat(afiliado.coseguroDiurnoAdulto) !== 0 &&
      afiliado.coseguroNocturnoAdulto !== null &&
      parseFloat(afiliado.coseguroNocturnoAdulto) !== 0
    ) {
      if (h2 > configuracion.diurnoDesde && h2 <= configuracion.diurnoHasta) {
        if (edad > configuracion.edadPediatrico) {
          guardarCoseguroServicio({
            valor: Number(parseFloat(afiliado.coseguroDiurnoAdulto).toFixed(2)),
            cartel: parseFloat(afiliado.coseguroDiurnoAdulto).toFixed(2),
            afiliado: titular.id_titular,
            plan:
              titular.planes.nombre.charAt(0).toUpperCase() +
              titular.planes.nombre.slice(1).toLowerCase(),
            servicio:
              nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
            mensaje:
              afiliado.id === null
                ? titular.planes.nombre.charAt(0).toUpperCase() +
                  titular.planes.nombre.slice(1).toLowerCase()
                : titular.nombre_razonsocial,
          });
          guardarCoseguroAbonar(
            Number(parseFloat(afiliado.coseguroDiurnoAdulto).toFixed(2))
          );
        } else {
          guardarCoseguroServicio({
            valor: Number(
              parseFloat(afiliado.coseguroDiurnoPediatrico).toFixed(2)
            ),
            cartel: parseFloat(afiliado.coseguroDiurnoPediatrico).toFixed(2),
            afiliado: titular.id_titular,
            plan:
              titular.planes.nombre.charAt(0).toUpperCase() +
              titular.planes.nombre.slice(1).toLowerCase(),
            servicio:
              nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
            mensaje:
              afiliado.id === null
                ? titular.planes.nombre.charAt(0).toUpperCase() +
                  titular.planes.nombre.slice(1).toLowerCase()
                : titular.nombre_razonsocial,
          });
          guardarCoseguroAbonar(
            Number(parseFloat(afiliado.coseguroDiurnoPediatrico).toFixed(2))
          );
        }
      } else {
        if (edad > configuracion.edadPediatrico) {
          guardarCoseguroServicio({
            valor: Number(
              parseFloat(afiliado.coseguroNocturnoAdulto).toFixed(2)
            ),
            cartel: parseFloat(afiliado.coseguroNocturnoAdulto).toFixed(2),
            afiliado: titular.id_titular,
            plan:
              titular.planes.nombre.charAt(0).toUpperCase() +
              titular.planes.nombre.slice(1).toLowerCase(),
            servicio:
              nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
            mensaje:
              afiliado.id === null
                ? titular.planes.nombre.charAt(0).toUpperCase() +
                  titular.planes.nombre.slice(1).toLowerCase()
                : titular.nombre_razonsocial,
          });
          guardarCoseguroAbonar(
            Number(parseFloat(afiliado.coseguroNocturnoAdulto).toFixed(2))
          );
        } else {
          guardarCoseguroServicio({
            valor: Number(
              parseFloat(afiliado.coseguroNocturnoPediatrico).toFixed(2)
            ),
            cartel: parseFloat(afiliado.coseguroNocturnoPediatrico).toFixed(2),
            afiliado: titular.id_titular,
            plan:
              titular.planes.nombre.charAt(0).toUpperCase() +
              titular.planes.nombre.slice(1).toLowerCase(),
            servicio:
              nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
            mensaje:
              afiliado.id === null
                ? titular.planes.nombre.charAt(0).toUpperCase() +
                  titular.planes.nombre.slice(1).toLowerCase()
                : titular.nombre_razonsocial,
          });
          guardarCoseguroAbonar(
            Number(parseFloat(afiliado.coseguroNocturnoPediatrico).toFixed(2))
          );
        }
      }
      return;
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercoseguroservicio",
        datos
      );

      let data = respuesta.data;

      if (data !== null) {
        console.log(configuracion.diurnoDesde);
        if (h2 > configuracion.diurnoDesde && h2 <= configuracion.diurnoHasta) {
          if (edad > configuracion.edadPediatrico) {
            guardarCoseguroServicio({
              valor: Number(parseFloat(data.valorDiurnoAdulto).toFixed(2)),
              cartel: parseFloat(data.valorDiurnoAdulto).toFixed(2),
              afiliado: titular.id_titular,
              plan:
                titular.planes.nombre.charAt(0).toUpperCase() +
                titular.planes.nombre.slice(1).toLowerCase(),
              servicio:
                nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
              mensaje:
                data.afiliadoId === null
                  ? titular.planes.nombre.charAt(0).toUpperCase() +
                    titular.planes.nombre.slice(1).toLowerCase()
                  : titular.nombre_razonsocial,
            });
            guardarCoseguroAbonar(
              Number(parseFloat(data.valorDiurnoAdulto).toFixed(2))
            );
          } else {
            guardarCoseguroServicio({
              valor: Number(parseFloat(data.valorDiurnoPediatrico).toFixed(2)),
              cartel: parseFloat(data.valorDiurnoPediatrico).toFixed(2),
              afiliado: titular.id_titular,
              plan:
                titular.planes.nombre.charAt(0).toUpperCase() +
                titular.planes.nombre.slice(1).toLowerCase(),
              servicio:
                nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
              mensaje:
                data.afiliadoId === null
                  ? titular.planes.nombre.charAt(0).toUpperCase() +
                    titular.planes.nombre.slice(1).toLowerCase()
                  : titular.nombre_razonsocial,
            });
            guardarCoseguroAbonar(
              Number(parseFloat(data.valorDiurnoPediatrico).toFixed(2))
            );
          }
        } else {
          if (edad > configuracion.edadPediatrico) {
            guardarCoseguroServicio({
              valor: Number(parseFloat(data.valorNocturnoAdulto).toFixed(2)),
              cartel: parseFloat(data.valorNocturnoAdulto).toFixed(2),
              afiliado: titular.id_titular,
              plan:
                titular.planes.nombre.charAt(0).toUpperCase() +
                titular.planes.nombre.slice(1).toLowerCase(),
              servicio:
                nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
              mensaje:
                data.afiliadoId === null
                  ? titular.planes.nombre.charAt(0).toUpperCase() +
                    titular.planes.nombre.slice(1).toLowerCase()
                  : titular.nombre_razonsocial,
            });
            guardarCoseguroAbonar(
              Number(parseFloat(data.valorNocturnoAdulto).toFixed(2))
            );
          } else {
            guardarCoseguroServicio({
              valor: Number(
                parseFloat(data.valorNocturnoPediatrico).toFixed(2)
              ),
              cartel: parseFloat(data.valorNocturnoPediatrico).toFixed(2),
              afiliado: titular.id_titular,
              plan:
                titular.planes.nombre.charAt(0).toUpperCase() +
                titular.planes.nombre.slice(1).toLowerCase(),
              servicio:
                nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
              mensaje:
                data.afiliadoId === null
                  ? titular.planes.nombre.charAt(0).toUpperCase() +
                    titular.planes.nombre.slice(1).toLowerCase()
                  : titular.nombre_razonsocial,
            });
            guardarCoseguroAbonar(
              Number(parseFloat(data.valorNocturnoPediatrico).toFixed(2))
            );
          }
        }
      } else {
        guardarCoseguroServicio({
          valor: 0,
          cartel: "No está valorizado",
          afiliado: titular.id_titular,
          plan:
            titular.planes.nombre.charAt(0).toUpperCase() +
            titular.planes.nombre.slice(1).toLowerCase(),
          servicio:
            nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase(),
          mensaje:
            titular.id_grupo === 1
              ? titular.planes.nombre.charAt(0).toUpperCase() +
                titular.planes.nombre.slice(1).toLowerCase()
              : titular.nombre_razonsocial,
        });
        guardarCoseguroAbonar(Number(0));
      }
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerCoseguro = async (datos) => {
    const { idCoseguro } = datos;
    let index = coseguros.findIndex((item) => item.id === idCoseguro);
    guardarCoseguro(coseguros[index]);
  };

  const actualizarCoseguro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcoseguro",
        datos
      );

      mensajeAlerta("Coseguros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  const eliminarCoseguro = async (id) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminarcoseguro",
        { id }
      );

      mensajeAlerta("Coseguros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Coseguros", error.response.data, "error");
    }
  };

  //Medicamentos

  const obtenerMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicamentos",
        datos
      );

      let data = respuesta.data;

      guardarMedicamentos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicamentos", error.response.data, "error");
    }
  };

  const obtenerMedicamentosSku = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicamentos",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          sku: data[i].sku,
          descripcion: data[i].sku + " - " + data[i].nombre,
        });
      }

      guardarMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicamentos", error.response.data, "error");
    }
  };

  const obtenerMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermedicamento",
        datos
      );

      let data = respuesta.data;

      guardarMedicamento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicamentos", error.response.data, "error");
    }
  };

  const actualizarMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmedicamento",
        datos
      );

      mensajeAlerta("Medicamentos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicamentos", error.response.data, "error");
    }
  };

  const agregarMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmedicamento",
        datos
      );

      mensajeAlerta("Medicamentos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Medicamentos", error.response.data, "error");
    }
  };

  //Almacenes

  const obtenerAlmacenesLiberados = async (datos) => {
    const { tripulaciones } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacenes",
        datos
      );

      let data = respuesta.data;

      const local = [];

      let bandAlmacen;
      for (var i = 0; i < data.length; i++) {
        bandAlmacen = false;
        for (var j = 0; j < tripulaciones.length; j++) {
          if (data[i].id === tripulaciones[j].almacenId) {
            bandAlmacen = true;
            break;
          }
        }
        if (!bandAlmacen && data[i].id !== 1) {
          local.push({
            id: data[i].id,
            nombre: data[i].nombre,
          });
        }
      }

      //console.log(local)

      guardarAlmacenes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  const obtenerAlmacenesMoviles = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacenesmoviles"
      );

      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          almacen: data[i].almacenes.nombre,
          almacenId: data[i].almacenId,
          movil: data[i].moviles.nombre,
          movilId: data[i].movilId,
        });
      }

      guardarAlmacenesMoviles(local);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAlmacenMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacenmovil",
        datos
      );

      let data = respuesta.data;
      guardarAlmacenMovil(data);
    } catch (error) {
      console.log(error.response);
      guardarAlmacenMovil(null);

      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAlmacenes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacenes",
        datos
      );

      let data = respuesta.data;

      guardarAlmacenes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  const obtenerAlmacen = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneralmacen",
        datos
      );

      let data = respuesta.data;

      guardarAlmacen(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  const actualizarAlmacen = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizaralmacen",
        datos
      );

      mensajeAlerta("Almacenes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  const agregarAlmacen = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaralmacen",
        datos
      );

      mensajeAlerta("Almacenes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  const agregarAlmacenMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaralmacenmovil",
        datos
      );

      mensajeAlerta("Almacenes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  const eliminarAlmacenMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminaralmacenmovil",
        datos
      );

      mensajeAlerta("Almacenes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Almacenes", error.response.data, "error");
    }
  };

  //Movimientos medicamentos

  const obtenerMovimientosMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientosmedicamentos",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          sku: data[i].sku,
          fecha: formatDateTime(data[i].fecha),
          nombre: data[i].medicamentos.nombre,
          cantidad: data[i].cantidad,
          almacen: data[i].almacenes.nombre,
          tipo: data[i].tipo === "E" ? "ENTRADA" : "SALIDA",
          descripcion: data[i].descripcion,
        });
      }

      guardarMovimientosMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(
        "Movimientos de Medicamentos",
        error.response.data,
        "error"
      );
    }
  };

  const obtenerStocksMedicamentos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerstocksmedicamentos",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          sku: data[i].sku,
          nombre: data[i].medicamentos.nombre,
          stock: data[i].stock,
          almacen: data[i].almacenes.nombre,
          almacenId: data[i].almacenId,
        });
      }

      guardarStocksMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Stock de Medicamentos", error.response.data, "error");
    }
  };

  const obtenerAjustesStocksMedicamentos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerajustesstocksmedicamentos"
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          sku: data[i].sku,
          nombre: data[i].medicamentos.nombre,
          stockAnterior: data[i].stockAnterior,
          stockPosterior: data[i].stockPosterior,
          almacen: data[i].almacenes.nombre,
          almacenId: data[i].almacenId,
          usuario: data[i].usuario,
        });
      }

      guardarAjustesStocksMedicamentos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Stock de Medicamentos", error.response.data, "error");
    }
  };

  const ajustarStockMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/ajustarstockmedicamento",
        datos
      );

      mensajeAlerta("Stock de Medicamentos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Stock de Medicamentos", error.response.data, "error");
    }
  };

  const obtenerMovimientoMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientomedicamento",
        datos
      );

      let data = respuesta.data;

      guardarMovimientoMedicamento(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(
        "Movimientos de Medicamentos",
        error.response.data,
        "error"
      );
    }
  };

  const actualizarMovimientoMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmovimientomedicamento",
        datos
      );

      mensajeAlerta("Movimientos de Medicamentos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(
        "Movimientos de Medicamentos",
        error.response.data,
        "error"
      );
    }
  };

  const agregarMovimientoMedicamento = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovimientomedicamento",
        datos
      );

      mensajeAlerta("Movimientos de Medicamentos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(
        "Movimientos de Medicamentos",
        error.response.data,
        "error"
      );
    }
  };

  //Convenios

  const obtenerValorizacionesServicios = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenervalorizacionesservicios"
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          afiliadoId: data[i].afiliadoId,
          nombre: data[i].afiliados.nombre_razonsocial,
          verde: data[i].verde,
          verde_FueraZona: data[i].verdeFz,
          verde_SabDomFer: data[i].verdeSdf,
          verde_SabDomFer_FueraZona: data[i].verdeSdfFz,
          verde_Nocturno: data[i].verdeN,
          verde_Nocturno_FueraZona: data[i].verdeNFz,
          verde_Nocturno_SabDomFer: data[i].verdeNSdf,
          verde_Nocturno_SabDomFer_FueraZona: data[i].verdeNSdfFz,
          verde_Pediatrico: data[i].verdeP,
          verde_Pediatrico_FueraZona: data[i].verdePFz,
          verde_Pediatrico_SabDomFer: data[i].verdePSdf,
          verde_Pediatrico_SabDomFer_FueraZona: data[i].verdePSdfFz,
          verde_Pediatrico_Nocturno: data[i].verdePN,
          verde_Pediatrico_Nocturno_FueraZona: data[i].verdePNFz,
          verde_Pediatrico_Nocturno_SabDomFer: data[i].verdePNSdf,
          verde_Pediatrico_Nocturno_SabDomFer_FueraZona: data[i].verdePNSdfFz,
          amarillo: data[i].amarillo,
          amarillo_FueraZona: data[i].amarilloFz,
          amarillo_SabDomFer: data[i].amarilloSdf,
          amarillo_SabDomFer_FueraZona: data[i].amarilloSdfFz,
          amarillo_Nocturno: data[i].amarilloN,
          amarillo_Nocturno_FueraZona: data[i].amarilloNFz,
          amarillo_Nocturno_SabDomFer: data[i].amarilloNSdf,
          amarillo_Nocturno_SabDomFer_FueraZona: data[i].amarilloNSdfFz,
          amarillo_Pediatrico: data[i].amarilloP,
          amarillo_Pediatrico_FueraZona: data[i].amarilloPFz,
          amarillo_Pediatrico_SabDomFer: data[i].amarilloPSdf,
          amarillo_Pediatrico_SabDomFer_FueraZona: data[i].amarilloPSdfFz,
          amarillo_Pediatrico_Nocturno: data[i].amarilloPN,
          amarillo_Pediatrico_Nocturno_FueraZona: data[i].amarilloPNFz,
          amarillo_Pediatrico_Nocturno_SabDomFer: data[i].amarilloPNSdf,
          amarillo_Pediatrico_Nocturno_SabDomFer_FueraZona:
            data[i].amarilloPNSdfFz,
          rojo: data[i].rojo,
          rojo_FueraZona: data[i].rojoFz,
          rojo_SabDomFer: data[i].rojoSdf,
          rojo_SabDomFer_FueraZona: data[i].rojoSdfFz,
          rojo_Nocturno: data[i].rojoN,
          rojo_Nocturno_FueraZona: data[i].rojoNFz,
          rojo_Nocturno_SabDomFer: data[i].rojoNSdf,
          rojo_Nocturno_SabDomFer_FueraZona: data[i].rojoNSdfFz,
          rojo_Pediatrico: data[i].rojoP,
          rojo_Pediatrico_FueraZona: data[i].rojoPFz,
          rojo_Pediatrico_SabDomFer: data[i].rojoPSdf,
          rojo_Pediatrico_SabDomFer_FueraZona: data[i].rojoPSdfFz,
          rojo_Pediatrico_Nocturno: data[i].rojoPN,
          rojo_Pediatrico_Nocturno_FueraZona: data[i].rojoPNFz,
          rojo_Pediatrico_Nocturno_SabDomFer: data[i].rojoPNSdf,
          rojo_Pediatrico_Nocturno_SabDomFer_FueraZona: data[i].rojoPNSdfFz,
          fuccia: data[i].fuccia,
          fuccia_FueraZona: data[i].fucciaFz,
          fuccia_SabDomFer: data[i].fucciaSdf,
          fuccia_SabDomFer_FueraZona: data[i].fucciaSdfFz,
          fuccia_Nocturno: data[i].fucciaN,
          fuccia_Nocturno_FueraZona: data[i].fucciaNFz,
          fuccia_Nocturno_SabDomFer: data[i].fucciaNSdf,
          fuccia_Nocturno_SabDomFer_FueraZona: data[i].fucciaNSdfFz,
          fuccia_Pediatrico: data[i].fucciaP,
          fuccia_Pediatrico_FueraZona: data[i].fucciaPFz,
          fuccia_Pediatrico_SabDomFer: data[i].fucciaPSdf,
          fuccia_Pediatrico_SabDomFer_FueraZona: data[i].fucciaPSdfFz,
          fuccia_Pediatrico_Nocturno: data[i].fucciaPN,
          fuccia_Pediatrico_Nocturno_FueraZona: data[i].fucciaPNFz,
          fuccia_Pediatrico_Nocturno_SabDomFer: data[i].fucciaPNSdf,
          fuccia_Pediatrico_Nocturno_SabDomFer_FueraZona: data[i].fucciaPNSdfFz,
        });
      }
      guardarValorizaciones(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Valorización de Servicios", error.response.data, "error");
    }
  };

  const obtenerValorizacionesServiciosConvenio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenervalorizacionesserviciosconvenio",
        datos
      );
      let data = respuesta.data;
      guardarValorizar(data);
    } catch (error) {
      guardarValorizar(null);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerValorizacionesServiciosDerivado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenervalorizacionesserviciosderivado",
        datos
      );
      let data = respuesta.data;
      guardarValorizar(data);
    } catch (error) {
      guardarValorizar(null);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarValorizaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarvalorizaciones",
        datos
      );

      mensajeAlerta("Valorización de Servicios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Valorización de Servicios", error.response.data, "error");
    }
  };

  const actualizarValorizacionesDerivado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarvalorizacionesderivado",
        datos
      );

      mensajeAlerta("Valorización de Servicios", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Valorización de Servicios", error.response.data, "error");
    }
  };

  const uploadFileFirma = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/uploadfilefirma",
        datos
      );
      mensajeAlerta("Subir Archivos", respuesta.data.msg, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Subir Archivos", error.response.data, "error");
    }
  };

  const enviarLinkRegistroMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/enviarlinkregistromedico",
        datos
      );
      mensajeAlerta(
        "Link Registro Médico",
        "El link se ha enviado correctamente",
        "success"
      );
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Link Registro Médico", "Hubo un error", "error");
    }
  };

  const activarMedico = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/activarmedicoreceta",
        datos
      );

      mensajeAlerta("Médicos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Médicos", error.response.data, "error");
    }
  };

  const obtenerObrasSocialesRecetario = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerobrassocialesrecetario",
        datos
      );

      let data = respuesta.data;

      data.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      guardarObrasSocialesMedicas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Obras Sociales", error.response.data, "error");
    }
  };

  return (
    <Fragment>
      <Provider
        value={{
          antecedentes,
          antecedente,
          tratamientos,
          tratamiento,
          tiposServicios,
          tipoServicio,
          motivosConsultas,
          motivoConsulta,
          usuarios,
          pantallas,
          configuracion,
          rows,
          statusAcceso,
          statusAcceso2,
          statusAcceso3,
          colores,
          color,
          medicos,
          medico,
          choferes,
          chofer,
          enfermeros,
          enfermero,
          tiposMoviles,
          tipoMovil,
          moviles,
          movil,
          telefonos,
          telefono,
          diagnosticos,
          diagnostico,
          almacenes,
          almacenesMoviles,
          almacenMovil,
          bases,
          base,
          almacen,
          alertas,
          medicamentos,
          medicamento,
          movimientosMedicamentos,
          movimientoMedicamento,
          planes,
          coseguro,
          coseguros,
          activeIndex,
          grupos,
          rubros,
          coseguroServicio,
          parametros,
          coseguroAbonar,
          stocksMedicamentos,
          ajustesStocksMedicamentos,
          zonasMovilesDerivados,
          coloresM,
          feriados,
          valorizaciones,
          valorizar,
          topes,
          tope,
          delay,
          adicionales,
          empresas,
          tickets,
          diagnosticosPami,
          urgenciasPami,
          destinosPami,
          convenios,
          localidades,
          provincias,
          medicosRecetas,
          obrasSocialesMedicas,
          obtenerAntecedentes,
          obtenerAntecedente,
          agregarAntecedente,
          actualizarAntecedente,
          guardarAntecedente,
          obtenerTratamientos,
          obtenerTratamiento,
          agregarTratamiento,
          actualizarTratamiento,
          guardarTratamiento,
          obtenerTiposServicios,
          obtenerTipoServicio,
          agregarTipoServicio,
          actualizarTipoServicio,
          guardarTipoServicio,
          obtenerMotivosConsultas,
          obtenerMotivoConsulta,
          agregarMotivoConsulta,
          actualizarMotivoConsulta,
          guardarMotivoConsulta,
          obtenerUsuarios,
          agregarAccesos,
          obtenerAccesos,
          obtenerConfiguracion,
          setRows,
          obtenerAcceso,
          obtenerAcceso2,
          obtenerAcceso3,
          obtenerColores,
          obtenerColor,
          actualizarColor,
          guardarColor,
          obtenerMedicos,
          obtenerMedicosRecetas,
          obtenerMedicosLiberados,
          obtenerMedico,
          agregarMedico,
          actualizarMedico,
          guardarMedico,
          obtenerChoferes,
          obtenerChoferesLiberados,
          obtenerChofer,
          agregarChofer,
          actualizarChofer,
          guardarChofer,
          obtenerEnfermeros,
          obtenerEnfermerosLiberados,
          obtenerEnfermero,
          agregarEnfermero,
          actualizarEnfermero,
          guardarEnfermero,
          obtenerTiposMoviles,
          obtenerTipoMovil,
          agregarTipoMovil,
          actualizarTipoMovil,
          guardarTipoMovil,
          obtenerMoviles,
          obtenerMovil,
          agregarMovil,
          actualizarMovil,
          guardarMovil,
          obtenerMovilesPropiosLiberados,
          obtenerNuevoNumeroMovil,
          obtenerTelefonos,
          obtenerTelefono,
          agregarTelefono,
          actualizarTelefono,
          guardarTelefono,
          obtenerDiagnosticos,
          obtenerDiagnostico,
          agregarDiagnostico,
          actualizarDiagnostico,
          guardarDiagnostico,
          obtenerMedicamentos,
          obtenerMedicamentosSku,
          obtenerMedicamento,
          agregarMedicamento,
          actualizarMedicamento,
          guardarMedicamento,
          obtenerAlmacenesMoviles,
          guardarAlmacenesMoviles,
          agregarAlmacenMovil,
          eliminarAlmacenMovil,
          obtenerAlmacenes,
          obtenerAlmacen,
          agregarAlmacen,
          actualizarAlmacen,
          guardarAlmacen,
          obtenerMovimientosMedicamentos,
          obtenerMovimientoMedicamento,
          agregarMovimientoMedicamento,
          actualizarMovimientoMedicamento,
          guardarMovimientoMedicamento,
          guardarMovimientosMedicamentos,
          obtenerAlertas,
          guardarAlertas,
          actualizarAlertas,
          actualizarConfiguracion,
          guardarConfiguracion,
          obtenerBases,
          obtenerBase,
          agregarBase,
          actualizarBase,
          guardarBase,
          obtenerConvenios,
          obtenerConvenio,
          agregarConvenio,
          actualizarConvenio,
          guardarMoviles,
          obtenerTelefonosLiberados,
          obtenerCoseguros,
          obtenerCoseguro,
          guardarCoseguro,
          actualizarCoseguro,
          guardarCoseguros,
          setActiveIndex,
          obtenerGrupos,
          obtenerRubros,
          obtenerCoseguroServicio,
          setParametros,
          guardarCoseguroServicio,
          eliminarCoseguro,
          guardarCoseguroAbonar,
          guardarStocksMedicamentos,
          obtenerStocksMedicamentos,
          ajustarStockMedicamento,
          guardarAjustesStocksMedicamentos,
          obtenerAjustesStocksMedicamentos,
          guardarAlmacenMovil,
          obtenerAlmacenMovil,
          obtenerZonasMovilesDerivados,
          guardarZonasMovilesDerivados,
          guardarTelefonos,
          guardarMaletin,
          guardarMedicos,
          guardarChoferes,
          guardarEnfermeros,
          guardarAlmacenes,
          guardarFirmaMedico,
          resetPasswordMedico,
          obtenerFeriados,
          actualizarFeriados,
          eliminarFeriado,
          obtenerValorizacionesServicios,
          actualizarValorizaciones,
          guardarValorizaciones,
          guardarValorizar,
          obtenerValorizacionesServiciosConvenio,
          agregarTokenMovil,
          obtenerTopes,
          obtenerTope,
          agregarTope,
          actualizarTope,
          guardarTope,
          obtenerAdicionales,
          obtenerEmpresas,
          obtenerTickets,
          guardarTickets,
          agregarTicket,
          actualizarTicket,
          obtenerTicketsAll,
          obtenerAlmacenesLiberados,
          obtenerValorizacionesServiciosDerivado,
          actualizarValorizacionesDerivado,
          obtenerDiagnosticosPami,
          obtenerLocalidades,
          obtenerProvincias,
          uploadFileFirma,
          registroMedico,
          enviarLinkRegistroMedico,
          activarMedico,
          obtenerObrasSocialesRecetario,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export {
  ConfiguracionProvider,
  Consumer as ConfiguracionConsumer,
  ConfiguracionContext,
};
