import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ServiciosContext } from "../../../../context/ServiciosContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Navigate, useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import SignatureCanvas from "react-signature-canvas";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import axios from "axios";
import Barra from "../../Barra";
import { saveAs } from "file-saver";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";

export default function MedicosRecetas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "M.N", dataKey: "mn" },
    { title: "M.P", dataKey: "mp" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [medico, guardarMedico] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [exit, setExit] = useState(false);
  const [band, setBand] = useState(true);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    medicos,
    medicosRecetas,
    obtenerMedicosRecetas,
    obtenerAcceso,
    configuracion,
    agregarMedico,
    actualizarMedico,
    activarMedico,
    obtenerMedicos,
    obtenerConfiguracion,
  } = configuracionContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "RecetasElectronicas",
      });
    }
  }, [state]);

  useEffect(() => {
    obtenerMedicos({ todos: false });
    obtenerConfiguracion();
  }, []);

  useEffect(() => {
    if (configuracion) {
      obtenerMedicosRecetas({
        institucionId: configuracion.institucionId,
      });
    }
  }, [configuracion]);

  const activarClick = (e) => {
    if (selectedRow) {
      toast.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              {!selectedRow.active ? (
                <h4>{`Confirma activar médico para recetas ?`}</h4>
              ) : (
                <h4>{`Confirma desactivar médico para recetas ?`}</h4>
              )}
              <p>{`Médico: ${selectedRow.surname} ${selectedRow.name} .`}</p>
              <p></p>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => opcionSiActivar(selectedRow)}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={(e) => onHide()}
                />
              </div>
            </div>
          </div>
        ),
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Médico",
        detail: "Debe seleccionar un médico.",
        life: 3000,
      });
    }
  };

  const opcionSiActivar = (data) => {
    //e.preventDefault();

    //Validar
    if (!data || data === undefined || data === null) {
      mensajeAlerta("Turnos", "Debe seleccionar un médico !.", "error");
      return;
    }

    activarMedico({
      medico: data,
    });

    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMedicosRecetas({
        institucionId: configuracion.institucionId,
      });
    }, 2000);
    setTimeout(() => {
      setBand(true);
    }, 3000);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
    toast.current.clear();
    setSelectedRow(null);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Télefono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const mnTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">M.N.</span>
        {rowData.mn}
      </Fragment>
    );
  };

  const mpTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">M.P.</span>
        {rowData.mp}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.active}></Checkbox>
      </Fragment>
    );
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Médicos Recetario"></Tag>
      </div>
    </Fragment>
  );

  //INICIO

  if (
    medicosRecetas &&
    medicosRecetas.length > 0 &&
    medicos &&
    medicos.length > 0 &&
    band
  ) {
    setBand(false);
    let local = [];
    for (var i = 0; i < medicosRecetas.length; i++) {
      for (var j = 0; j < medicos.length; j++) {
        if (medicosRecetas[i].id === medicos[j].recetarioId) {
          local.push(medicosRecetas[i]);
        }
      }
    }
    guardarMedico(local);
  }

  //FIN

  //console.log(medicos);
  //console.log(medicosRecetas);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {medicosRecetas ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  align: "center",
                  margin: "1em",
                  marginTop: "1vw",
                }}
              >
                <Button
                  icon="pi pi-check"
                  tooltip="Activar / Desactivar"
                  className="p-button-rounded p-button-warning"
                  style={{ marginLeft: "1em" }}
                  onClick={activarClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={medico}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={100}
                      rowsPerPageOptions={[10, 20, 50, 100]}

                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="surname"
                        header="Apellido"
                        //body={apellidoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="name"
                        header="Nombre"
                        //body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="address"
                        header="Domicilio"
                        //body={domicilioTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="workPhone"
                        header="Teléfono"
                        //body={telefonoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="licenseNumber"
                        header="Matrícula"
                        //body={mnTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="licenseType"
                        header="Tipo"
                        //body={mpTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="active"
                        header="Activo"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item size={{ xs: 12, md: 12 }} />
        </Fragment>
      ) : null}
      {exit ? <Navigate to="/login" /> : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
