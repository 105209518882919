import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
//import "./example-styles.css";
//import "./styles.css";
//import { Responsive, WidthProvider } from "react-grid-layout";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
//import NewWindow from "react-new-window";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import detectZoom from "detect-zoom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import AddIcon from "@mui/icons-material/Add";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import ResumenServicio from "./ResumenServicio";
import ResumenMoviles from "./ResumenMoviles";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
//import classNames from "classnames";
import { classNames } from "primereact/utils";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HouseIcon from "@mui/icons-material/House";
import AdjustIcon from "@mui/icons-material/Adjust";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import FlagIcon from "@mui/icons-material/Flag";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import "../../../assets/css/DropDown.css";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import { MultiSelect } from "primereact/multiselect";
import { saveAs } from "file-saver";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TextareaAutosize } from "@mui/base";
import { Dropdown } from "primereact/dropdown";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Telemedicina(props) {
  //const classes = useStyles();
  //const theme = useTheme();

  const sexos = [
    { nombre: "M", dataKey: "m" },
    { nombre: "F", dataKey: "f" },
    { nombre: "O", dataKey: "o" },
  ];

  const [value, setValue] = useState();
  const [filter, setFilter] = useState(null);
  const [loop, setLoop] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [visibleDatos, setVisibleDatos] = useState(false);
  const [visibleReceta, setVisibleReceta] = useState(false);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [bandStart, setBandStart] = useState(false);
  const [confirmarApp, setConfirmarApp] = useState(false);
  const [tripulacionApp, setTripulacionApp] = useState(null);
  const [bandNuevoServicio, setBandNuevoServicio] = useState(false);
  const [first, setFirst] = useState(true);
  const toast = useRef(null);
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta, mensajeAlertaTR, mensajeAlertaTL } = alertContext;
  const [selectedColors, setSelectedColors] = useState(null);
  const [selectedZona, setSelectedZona] = useState(null);
  const [selectedMovils, setSelectedMovils] = useState(null);
  const [liberado, setLiberado] = useState(false);

  let navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, obrasSocialesMedicas, obtenerObrasSocialesRecetario } =
    configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    servicioTelemedicina,
    notas,
    obtenerServicioTelemedicina,
    obtenerNotasTelemedicina,
    guardarServicioTelemedicina,
  } = serviciosContext;

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      obtenerServicioTelemedicina({ servicioId: id });
      obtenerNotasTelemedicina({ servicioId: id });
      //obtenerObrasSocialesRecetario();
    }
  }, [id]);

  useEffect(() => {
    obtenerObrasSocialesRecetario();
  }, []);

  const exitClick = (e) => {
    navigate(`/`);
  };

  const llamadoCelular = async () => {
    return (
      <a href={"tel:+549" + servicioTelemedicina.celular}>
        {servicioTelemedicina.celular}
      </a>
    );
  };

  const confirmarRecepcion = async () => {
    let datos = {
      servicioId: servicioTelemedicina.id,
      movilId: servicioTelemedicina.derivadoId,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          "/api/servicios/confirmarrecepciontelemedicina",
        datos,
        config
      )
      .then(function (response) {
        console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Telemedicina",
          detail: "Se ha confirmado la recepción del servicio correctamente.",
          life: 3000,
        });
      });

    setTimeout(() => {
      obtenerServicioTelemedicina({ servicioId: id });
    }, 2000);
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  //console.log(obrasSocialesMedicas);

  return (
    <Fragment>
      <Toast ref={toast} />
      <Fragment>
        <h2 style={{ textAlign: "center" }}>Master Red - Telemedicina</h2>

        {!servicioTelemedicina ? (
          <h2 style={{ textAlign: "center" }}>Servicio Inexistente</h2>
        ) : null}

        {servicioTelemedicina &&
        servicioTelemedicina.estado === "PASADO" &&
        !visibleDatos &&
        !visibleReceta ? (
          <Grid container spacing={0} style={{ padding: "0" }}>
            <Grid item xs={6} md={6}>
              <Button
                type="button"
                label="Confirmar Recepción"
                icon="pi pi-check"
                style={{
                  marginLeft: "2vw",
                }}
                className="p-button-success"
                onClick={() => {
                  confirmarRecepcion();
                }}
              />
            </Grid>
          </Grid>
        ) : null}

        {servicioTelemedicina &&
        servicioTelemedicina.estado === "LLEGADA" &&
        !visibleDatos &&
        !visibleReceta ? (
          <Grid container spacing={0} style={{ padding: "0" }}>
            <Grid item xs={6} md={6}>
              <Button
                label="Datos del Paciente"
                icon="pi pi-search"
                style={{
                  marginLeft: "2vw",
                  marginRight: "2vw",
                }}
                disabled={!servicioTelemedicina}
                className="p-button-info"
                onClick={() => {
                  setVisibleDatos(true);
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <a href={"tel:+549" + servicioTelemedicina.celular}>
                <Button
                  label="Llamado al Celular"
                  icon="pi pi-phone"
                  style={{
                    marginLeft: "2vw",
                    marginRight: "2vw",
                  }}
                  disabled={!servicioTelemedicina}
                  className="p-button-info"
                />
              </a>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                label="Receta"
                icon="pi pi-align-left"
                style={{
                  marginLeft: "2vw",
                  marginRight: "2vw",
                  marginTop: "2vw",
                }}
                disabled={!servicioTelemedicina}
                className="p-button-info"
                onClick={() => {
                  if (!servicioTelemedicina.apellido && !servicioTelemedicina.nombres) {
                    let espacio = servicioTelemedicina.nombre.search(" ");
                    let caracteres = servicioTelemedicina.nombre.length;
                    const nombre = servicioTelemedicina.nombre;
                    servicioTelemedicina.apellido = nombre.substring(0, espacio);
                    servicioTelemedicina.nombres = nombre.substring(
                      espacio + 1,
                      caracteres
                    );
                  }
                  //servicioTelemedicina.nacimiento = formatDate(servicioTelemedicina.nacimiento)
                  setVisibleReceta(true);
                }}
              />
            </Grid>
          </Grid>
        ) : null}

        {visibleDatos ? (
          <Fragment>
            <h2 style={{ textAlign: "center" }}>Datos del Paciente</h2>
            <Grid container spacing={0} style={{ padding: "0" }}>
              <Grid
                item
                xs={11}
                md={11}
                style={{ marginLeft: "1vw", marginTop: "2vw" }}
              >
                <TextField
                  value={
                    servicioTelemedicina ? servicioTelemedicina.nombre : null
                  }
                  id="nombre"
                  label="Apellido y Nombre"
                  fullWidth
                  variant="standard"
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={11}
                md={11}
                style={{ marginLeft: "1vw", marginTop: "2vw" }}
              >
                <TextField
                  value={
                    servicioTelemedicina ? servicioTelemedicina.domicilio : null
                  }
                  id="domicilio"
                  label="Domicilio"
                  fullWidth
                  variant="standard"
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                style={{ marginLeft: "1vw", marginTop: "2vw" }}
              >
                <TextField
                  value={
                    servicioTelemedicina ? servicioTelemedicina.edad : null
                  }
                  id="edad"
                  label="Edad"
                  fullWidth
                  variant="standard"
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid
                item
                xs={4}
                md={3}
                style={{ marginLeft: "1vw", marginTop: "2vw" }}
              >
                <TextField
                  value={
                    servicioTelemedicina ? servicioTelemedicina.celular : null
                  }
                  id="celular"
                  label="Celular"
                  fullWidth
                  variant="standard"
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                style={{
                  marginLeft: "1vw",
                  marginRight: "1vw",
                  marginTop: "0vw",
                }}
              >
                <h4>Observaciones</h4>
                <TextareaAutosize
                  id="observaciones"
                  maxRows={5}
                  minRows={5}
                  //value={afiliado.observaciones}
                  aria-label="maximum height"
                  placeholder="Observaciones"
                  defaultValue={
                    notas && notas.length > 0 ? notas[0].nota : null
                  }
                  style={{ width: "-webkit-fill-available", marginTop: "0em" }}
                  disabled
                  /*
                              onChange={(e) =>
                                guardarAfiliado({
                                  ...afiliado,
                                  observaciones: e.target.value,
                                })
                              }
                              */
                />
              </Grid>

              <Grid item xs={12} md={12} />
              <Grid item xs={4} md={4} />
              <Grid item xs={4} md={4}>
                <Button
                  label="Volver"
                  icon="pi pi-arrow-left"
                  style={{
                    marginLeft: "2vw",
                    marginTop: "5vw",
                  }}
                  className="p-button-danger"
                  onClick={() => {
                    setVisibleDatos(false);
                  }}
                />
              </Grid>
              <Grid item xs={4} md={4} />
            </Grid>
          </Fragment>
        ) : null}

        {visibleReceta ? (
          <Fragment>
            <h2 style={{ textAlign: "center" }}>Receta</h2>
            <Grid container spacing={0} style={{ padding: "0" }}>
              <div className="flex flex-column h-12rem">
                <div className="flex pt-4 justify-content-end">
                  <Grid container spacing={0} style={{ marginTop: "0vw" }}>
                    <Grid
                      item
                      xs={11}
                      md={5}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <TextField
                        value={
                          servicioTelemedicina
                            ? servicioTelemedicina.apellido
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        id="Apellido"
                        label="Apellido"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                          guardarServicioTelemedicina({
                            ...servicioTelemedicina,
                            apellido: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={11}
                      md={5}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <TextField
                        value={
                          servicioTelemedicina
                            ? servicioTelemedicina.nombres
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        id="Nombre"
                        label="Nombre"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                          guardarServicioTelemedicina({
                            ...servicioTelemedicina,
                            nombres: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      md={2}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <TextField
                        value={
                          servicioTelemedicina
                            ? servicioTelemedicina.numeroDocumento
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        id="numeroDocumento"
                        label="D.N.I."
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                          guardarServicioTelemedicina({
                            ...servicioTelemedicina,
                            numeroDocumento: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      md={2}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <TextField
                        id="nacimiento"
                        label="Fecha Nacimiento"
                        type="date"
                        variant="standard"
                        value={
                          servicioTelemedicina
                            ? servicioTelemedicina.nacimiento
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        onChange={(e) => {
                          guardarServicioTelemedicina({
                            ...servicioTelemedicina,
                            nacimiento: e.target.value,
                          });
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      md={2}
                      style={{ marginLeft: "0vw", marginTop: "2vw" }}
                    >
                      <Dropdown
                        value={
                          servicioTelemedicina && servicioTelemedicina.sexo
                            ? sexos[
                                sexos.findIndex(
                                  (element) =>
                                    element.nombre === servicioTelemedicina.sexo
                                )
                              ]
                            : null
                        }
                        onChange={(e) => {
                          if (e.value) {
                            guardarServicioTelemedicina({
                              ...servicioTelemedicina,
                              sexo: e.value.nombre,
                            });
                          } else {
                            guardarServicioTelemedicina({
                              ...servicioTelemedicina,
                              sexo: null,
                            });
                          }
                        }}
                        options={sexos}
                        optionLabel="nombre"
                        showClear
                        placeholder="Seleccione un sexo"
                        className="w-full md:w-14rem"
                        style={{ marginLeft: "1vw", marginTop: ".75vw" }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <label htmlFor="id">Obra Social: </label>

                      <Dropdown
                        value={
                          obrasSocialesMedicas &&
                          servicioTelemedicina &&
                          servicioTelemedicina.obraSocial
                            ? obrasSocialesMedicas[
                                obrasSocialesMedicas.findIndex(
                                  (element) =>
                                    element.nombre ===
                                    servicioTelemedicina.obraSocial
                                )
                              ]
                            : null
                        }
                        onChange={(e) => {
                          if (e.value) {
                            guardarServicioTelemedicina({
                              ...servicioTelemedicina,
                              obraSocial: e.value.nombre,
                            });
                          } else {
                            guardarServicioTelemedicina({
                              ...servicioTelemedicina,
                              obraSocial: null,
                            });
                          }
                        }}
                        options={obrasSocialesMedicas}
                        optionLabel="name"
                        showClear
                        filter
                        placeholder="Seleccione obra social"
                        className="w-75"
                        style={{ marginLeft: "1vw", marginTop: ".75vw" }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <TextField
                        value={
                          servicioTelemedicina
                            ? servicioTelemedicina.numeroObraSocial
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="numeroObraSocial"
                        label="Nº Obra Social"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                          guardarServicioTelemedicina({
                            ...servicioTelemedicina,
                            numeroObraSocial: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    {/*    
                    <Grid item size={{ xs: 12, md: 8 }}>
                      <TextField
                        value={
                          servicioTelemedicina
                            ? pacienteSeleccionado.email
                            : null
                        }
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        id="email"
                        label="Correo Electrónico"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                          if (e.target.value) {
                            guardarPacienteSeleccionado({
                              ...pacienteSeleccionado,
                              email: e.target.value,
                              enviarCorreo: true,
                            });
                          } else {
                            guardarPacienteSeleccionado({
                              ...pacienteSeleccionado,
                              email: null,
                              enviarCorreo: false,
                            });
                          }
                        }}
                      />
                    </Grid>
                    */}

                    <Grid
                      item
                      xs={5}
                      md={4}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <TextField
                        value={
                          servicioTelemedicina
                            ? servicioTelemedicina.celular
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="telefono"
                        label="Celular"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                          if (e.target.value) {
                            guardarServicioTelemedicina({
                              ...servicioTelemedicina,
                              telefono: e.target.value,
                              enviarWhatsApp: true,
                            });
                          } else {
                            guardarServicioTelemedicina({
                              ...servicioTelemedicina,
                              telefono: null,
                              enviarWhatsApp: false,
                            });
                          }
                        }}
                      />
                    </Grid>

                    {/*      
                    <Grid
                      item
                      size={{ xs: 5, md: 2 }}
                      sx={{ marginRight: "0.5em" }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              servicioTelemedicina
                                ? pacienteSeleccionado.enviarCorreo
                                : null
                            }
                            style={{ marginLeft: "0.5em" }}
                            onChange={(e) =>
                              guardarPacienteSeleccionado({
                                ...pacienteSeleccionado,
                                enviarCorreo: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Enviar Correo"
                        labelPlacement="start"
                      />
                    </Grid>
                    */}

                    <Grid
                      item
                      xs={12}
                      md={2}
                      sx={{ marginRight: "0.5em" }}
                      style={{ marginLeft: "1vw", marginTop: "1vw" }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              servicioTelemedicina
                                ? servicioTelemedicina.enviarWhatsApp
                                : null
                            }
                            style={{ marginLeft: "0.5em" }}
                            onChange={(e) =>
                              guardarServicioTelemedicina({
                                ...servicioTelemedicina,
                                enviarWhatsApp: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Enviar WhatsApp"
                        labelPlacement="start"
                      />
                    </Grid>

                    <Grid item xs={12} md={12} />
                  </Grid>
                </div>
              </div>

              <Grid item xs={2} md={2} />
              <Grid item xs={4} md={4}>
                <Button
                  label="Volver"
                  icon="pi pi-arrow-left"
                  style={{
                    marginLeft: "2vw",
                    marginTop: "5vw",
                  }}
                  className="p-button-danger"
                  onClick={() => {
                    setVisibleReceta(false);
                  }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <Button
                  label="Siguiente"
                  icon="pi pi-arrow-right"
                  style={{
                    marginLeft: "2vw",
                    marginTop: "5vw",
                  }}
                  className="p-button-success"
                  onClick={() => {
                    setVisibleReceta(false);
                  }}
                />
              </Grid>
              <Grid item xs={2} md={2} />
            </Grid>
          </Fragment>
        ) : null}
      </Fragment>
    </Fragment>
  );
}
