import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ServiciosContext } from "../../context/ServiciosContext";
import { AlertContext } from "../../context/AlertContext";
import { AuthContext } from "../../context/AuthContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip as Tooltip } from "primereact/tooltip";
import "../../assets/css/SpeedDialDemo.css";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import Spinner from "./Spinner";
import { Link, Redirect } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import "../../assets/css/DialogDemo.css";
import CierreEmergencias from "./servicios/CierreEmergencias";
import CierreFuccia from "./servicios/CierreFuccia";
import ConsumoMedicamentos from "./servicios/ConsumoMedicamentos";
import TrasladosServicios from "./servicios/TrasladosServicios";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//import { selectedRowsCountSelector } from "@material-ui/data-grid";
import SearchIcon from "@mui/icons-material/Search";
import CalculateIcon from "@mui/icons-material/Calculate";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  latLngBound,
} from "react-leaflet";
import "./servicios/Map.css";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import zIndex from "@mui/material/styles/zIndex";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const PREFIX = "SpeedDial";
const classes = {
  root: `${PREFIX}-root`,
  tooltipmapa: `${PREFIX}-tooltipmapa`,
  grip: `${PREFIX}-grip`,
  textField: `${PREFIX}-textField`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`& .${classes.tooltipmapa}`]: {
    fontSize: ".7em",
    marginLeft: "-10em",
  },
  [`&.${classes.grid}`]: {
    marginLeft: "1em",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  [`&.${classes.textField}`]: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
}));

export default function SpeedDialIcon(props) {
  const toast = useRef(null);
  const toastWarm = useRef(null);
  const [open, setOpen] = useState(false);
  const [openModalEmergencia, setOpenModalEmergencia] = useState(false);
  const [openModalFuccia, setOpenModalFuccia] = useState(false);
  const [openModalMedicamentos, setOpenModalMedicamentos] = useState(false);
  const [openModalDerivado, setOpenModalDerivado] = useState(false);
  const [openModalTrasladoServicio, setOpenModalTrasladoServicio] =
    useState(false);
  const [openModalModificar, setOpenModalModificar] = useState(false);
  const [bandInicio, setBandInicio] = useState(false);
  const [bandOpcionesMotivos, setBandOpcionesMotivos] = useState(false);
  const [opcionesMotivos, guardarOpcionesMotivos] = useState([]);
  const [selectedRowTraslados, setSelectedRowTraslados] = useState(null);
  const [visibleTrasladosServicios, setVisibleTrasladosServicios] =
    useState(false);

  let today = new Date();
  let year = today.getFullYear();

  const desde = props.desde;
  //console.log(desde)

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    direcciones,
    servicioModificado,
    titular,
    despachos,
    cierreEmergencias,
    cierreFuccia,
    chipData,
    trasladoServicio,
    cierreMedicamentos,
    valorizacion,
    servicioSeleccionado,
    trasladosServicios,
    tripulaciones,
    movilesDerivados,
    guardarServicioSeleccionado,
    setDrawer,
    setOpenDrawer,
    salidaMovil,
    llegadaMovil,
    anularServicio,
    activarServicio,
    simultaneoServicio,
    apoyoServicio,
    elevarServicio,
    reclamarServicio,
    liberarMovil,
    obtenerTripulaciones,
    guardarAfiliado,
    obtenerServiciosPendientes,
    guardarCierreMedicamentos,
    guardarCierreFuccia,
    guardarCierreEmergencias,
    cerrarServicioEmergencia,
    obtenerMovilesDerivados,
    setChipData,
    guardarTrasladosServicios,
    iniciarTrasladoServicio,
    finalizarTrasladoServicio,
    obtenerTrasladoServicio,
    buscarTitular,
    modificarServicio,
    obtenerServicio,
    guardarServicioModificado,
    obtenerDirecciones,
    buscarValorizacionDerivado,
    setSelectedRow,
    obtenerTrasladosServicios,
    modificarTrasladoServicio,
    trasladoServicioNoConcretado,
  } = serviciosContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    configuracion,
    colores,
    motivosConsultas,
    almacenMovil,
    coseguroServicio,
    tiposMoviles,
    feriados,
    coseguroAbonar,
    obtenerConfiguracion,
    obtenerAlmacenMovil,
    obtenerMotivosConsultas,
    guardarCoseguroServicio,
    obtenerCoseguroServicio,
    guardarCoseguroAbonar,
    obtenerFeriados,
    obtenerTiposMoviles,
  } = configuracionContext;

  useEffect(() => {
    obtenerTripulaciones({ estado: false });
    obtenerServiciosPendientes();
    obtenerConfiguracion();
    obtenerMovilesDerivados();
    obtenerMotivosConsultas({ todos: false });
    obtenerFeriados({ year });
    obtenerTiposMoviles();
  }, []);

  /*INICIO*/
  if (!bandInicio && servicioSeleccionado) {
    setBandInicio(true);
    obtenerTrasladoServicio({ servicioId: servicioSeleccionado.id });
    guardarServicioModificado(null);
    if (servicioSeleccionado.movilId >= 1000) {
      buscarValorizacionDerivado({ movilId: servicioSeleccionado.movilId });
    }
  }

  if (
    !bandOpcionesMotivos &&
    motivosConsultas &&
    motivosConsultas.length > 0 &&
    servicioModificado &&
    configuracion
  ) {
    setBandOpcionesMotivos(true);
    let array = [];

    if (
      configuracion &&
      (configuracion.destinatario === 1 ||
        configuracion.destinatario === 3 ||
        configuracion.destinatario === 4)
    ) {
      if (
        servicioModificado.color === "VERDE" ||
        servicioModificado.color === "AMARILLO" ||
        servicioModificado.color === "ROJO"
      ) {
        array = motivosConsultas.filter(
          (item) =>
            item.colorId === 3 || item.colorId === 4 || item.colorId === 5
        );
      }
    }

    if (configuracion && configuracion.destinatario === 2) {
      if (
        servicioModificado.color === "VERDE" ||
        servicioModificado.color === "AMARILLO" ||
        servicioModificado.color === "ROJO" ||
        servicioModificado.color === "CELESTE"
      ) {
        array = motivosConsultas.filter(
          (item) =>
            item.colorId === 3 ||
            item.colorId === 4 ||
            item.colorId === 5 ||
            item.colorId === 12
        );
      }
    }

    if (servicioModificado.color === "NEGRO") {
      array = motivosConsultas.filter((item) => item.colorId === 1);
    }

    if (servicioModificado.color === "BLANCO") {
      array = motivosConsultas.filter((item) => item.colorId === 2);
    }

    if (servicioModificado.color === "AZUL") {
      array = motivosConsultas.filter((item) => item.colorId === 6);
    }

    if (configuracion && configuracion.destinatario === 1) {
      if (servicioModificado.color === "CELESTE") {
        array = motivosConsultas.filter((item) => item.colorId === 9);
      }
    }
    if (servicioModificado.color === "FUCCIA") {
      array = motivosConsultas.filter((item) => item.colorId === 7);
    }
    if (servicioModificado.color === "NARANJA") {
      array = motivosConsultas.filter((item) => item.colorId === 7);
    }

    guardarOpcionesMotivos(array);
  }

  /*FIN*/

  function formatDate(dateString) {
    let allDate = dateString.split("-");
    //var thisDate = allDate[0].split("-");
    //var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return allDate;
  }

  let timer = "";

  const timeOut = () => {
    return;
    timer = setTimeout(() => {
      guardarServicioSeleccionado(null);
      setOpenDrawer(false);
    }, 1000 * 120);
  };

  //console.log(timer);

  if (coseguroServicio !== null) {
    toast.current.show({
      severity: "info",
      summary:
        coseguroServicio.mensaje + " Consulta: " + coseguroServicio.servicio,
      detail: "Valor Coseguro: " + coseguroServicio.cartel,
      sticky: false,
      life: 5000,
    });
    guardarCoseguroServicio(null);
  }

  const itemsTomado = [
    {
      label: "Pasar  ",
      icon: "pi pi-phone",
      command: () => {
        setDrawer("moviles");
        setOpenDrawer(true);
        toast.current.show({
          severity: "info",
          summary:
            "Debe seleccionar un móvil para el indicativo " +
            servicioSeleccionado.id,
          life: 1500,
        });
        timeOut();
        //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
      },
    },
    {
      label: "Anular",
      icon: "pi pi-trash",
      command: () => {
        anular();
        timeOut();
      },
    },
    {
      label: "Elevar",
      icon: "pi pi-plus",
      command: () => {
        elevar();
        timeOut();
      },
    },
    {
      label: "Reclamado",
      icon: "pi pi-thumbs-down",
      command: () => {
        reclamar();
        timeOut();
      },
    },
    {
      label: "Modificar",
      icon: "pi pi-undo",
      command: () => {
        modificar();
        timeOut();
      },
    },
    {
      label: "Cerrar",
      icon: "pi pi-times",
      command: () => {
        guardarServicioSeleccionado(null);
        setOpenDrawer(false);
        setSelectedRow(null);
        //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
      },
    },
  ];

  const itemsPasado =
    servicioSeleccionado.movilId < 1000
      ? [
          {
            label: "Salida",
            icon: "pi pi-arrow-right",
            command: () => {
              salida();
              timeOut();
            },
          },
          {
            label: "Activar",
            icon: "pi pi-check",
            command: () => {
              activar();
              timeOut();
            },
          },
          {
            label: "Anular",
            icon: "pi pi-trash",
            command: () => {
              anular();
              timeOut();
            },
          },
          {
            label: "Liberar",
            icon: "pi pi-directions-alt",
            command: () => {
              liberar();
              timeOut();
            },
          },
          {
            label: "Elevar",
            icon: "pi pi-plus",
            command: () => {
              elevar();
              timeOut();
            },
          },
          {
            label: "Reclamado",
            icon: "pi pi-thumbs-down",
            command: () => {
              reclamar();
              timeOut();
            },
          },
          {
            label: "Modificar",
            icon: "pi pi-undo",
            command: () => {
              modificar();
              timeOut();
            },
          },
          {
            label: "Cerrar",
            icon: "pi pi-times",
            command: () => {
              guardarServicioSeleccionado(null);
              setOpenDrawer(false);
              //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            },
          },
        ]
      : [
          {
            label: "Salida",
            icon: "pi pi-arrow-right",
            command: () => {
              salida();
              timeOut();
            },
          },
          {
            label: "Final    ",
            icon: "pi pi-lock",
            command: () => {
              finalizar();
            },
          },
          {
            label: "Activar",
            icon: "pi pi-check",
            command: () => {
              activar();
              timeOut();
            },
          },
          {
            label: "Anular",
            icon: "pi pi-trash",
            command: () => {
              anular();
              timeOut();
            },
          },
          {
            label: "Liberar",
            icon: "pi pi-directions-alt",
            command: () => {
              liberar();
              timeOut();
            },
          },
          {
            label: "Elevar",
            icon: "pi pi-plus",
            command: () => {
              elevar();
              timeOut();
            },
          },
          {
            label: "Simultáneo",
            icon: "pi pi-bars",
            command: () => {
              simultaneo();
              timeOut();
            },
          },
          {
            label: "Reclamado",
            icon: "pi pi-thumbs-down",
            command: () => {
              reclamar();
              timeOut();
            },
          },
          {
            label: "Modificar",
            icon: "pi pi-undo",
            command: () => {
              modificar();
              timeOut();
            },
          },
          {
            label: "Cerrar",
            icon: "pi pi-times",
            command: () => {
              guardarServicioSeleccionado(null);
              setOpenDrawer(false);
              //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            },
          },
        ];

  const itemsSalida = [
    {
      label: "Llegada",
      icon: "pi pi-flag",
      command: () => {
        llegada();
        timeOut();
      },
    },
    {
      label: "Anular",
      icon: "pi pi-trash",
      command: () => {
        anular();
        timeOut();
      },
    },
    {
      label: "Activar",
      icon: "pi pi-check",
      command: () => {
        activar();
        timeOut();
      },
    },
    {
      label: "Liberar",
      icon: "pi pi-directions-alt",
      command: () => {
        liberar();
        timeOut();
      },
    },
    {
      label: "Elevar",
      icon: "pi pi-plus",
      command: () => {
        elevar();
        timeOut();
      },
    },
    {
      label: "Reclamado",
      icon: "pi pi-thumbs-down",
      command: () => {
        reclamar();
        timeOut();
      },
    },
    {
      label: "Modificar",
      icon: "pi pi-undo",
      command: () => {
        modificar();
        timeOut();
      },
    },
    {
      label: "Cerrar",
      icon: "pi pi-times",
      command: () => {
        guardarServicioSeleccionado(null);
        setOpenDrawer(false);

        //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
      },
    },
  ];

  const itemsLlegada =
    servicioSeleccionado.color !== "AZUL" &&
    servicioSeleccionado.color !== "CELESTE"
      ? [
          {
            label: "Final    ",
            icon: "pi pi-lock",
            command: () => {
              finalizar();
            },
          },
          {
            label: "Activar",
            icon: "pi pi-check",
            command: () => {
              activar();
              timeOut();
            },
          },
          {
            label: "Simultáneo",
            icon: "pi pi-bars",
            command: () => {
              simultaneo();
              timeOut();
            },
          },
          {
            label: "Apoyo",
            icon: "pi pi-reply",
            command: () => {
              apoyo();
              timeOut();
            },
          },
          {
            label: "Anular",
            icon: "pi pi-trash",
            command: () => {
              anular();
              timeOut();
            },
          },
          {
            label: "Modificar",
            icon: "pi pi-undo",
            command: () => {
              modificar();
              timeOut();
            },
          },
          {
            label: "Liberar",
            icon: "pi pi-directions-alt",
            command: () => {
              liberar();
              timeOut();
            },
          },
          {
            label: "Elevar  ",
            icon: "pi pi-plus",
            command: () => {
              elevar();
              timeOut();
            },
          },
          {
            label: "Traslado",
            icon: "pi pi-sort-alt",
            command: () => {
              traslado();
              timeOut();
            },
          },
          {
            label: "Cerrar  ",
            icon: "pi pi-times",
            command: () => {
              guardarServicioSeleccionado(null);
              setOpenDrawer(false);
              //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            },
          },
        ]
      : [
          {
            label: "Final    ",
            icon: "pi pi-lock",
            command: () => {
              finalizar();
            },
          },
          {
            label: "Activar",
            icon: "pi pi-check",
            command: () => {
              activar();
              timeOut();
            },
          },
          {
            label: "Simultáneo",
            icon: "pi pi-bars",
            command: () => {
              simultaneo();
              timeOut();
            },
          },
          {
            label: "Apoyo",
            icon: "pi pi-reply",
            command: () => {
              apoyo();
              timeOut();
            },
          },
          {
            label: "Anular",
            icon: "pi pi-trash",
            command: () => {
              anular();
              timeOut();
            },
          },
          {
            label: "Modificar",
            icon: "pi pi-undo",
            command: () => {
              modificar();
              timeOut();
            },
          },
          {
            label: "Liberar",
            icon: "pi pi-directions-alt",
            command: () => {
              liberar();
              timeOut();
            },
          },
          {
            label: "Elevar  ",
            icon: "pi pi-plus",
            command: () => {
              elevar();
              timeOut();
            },
          },
          {
            label: "Traslado",
            icon: "pi pi-sort-alt",
            command: () => {
              traslado();
              timeOut();
            },
          },
          {
            label: "No Concretado  ",
            icon: "pi pi-sort-alt-slash",
            command: () => {
              trasladoNoConcretado();
              timeOut();
            },
          },
          {
            label: "Cerrar  ",
            icon: "pi pi-times",
            command: () => {
              guardarServicioSeleccionado(null);
              setOpenDrawer(false);
              //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            },
          },
        ];

  const itemsTraslado = [
    {
      label: "Final    ",
      icon: "pi pi-sort-alt",
      command: () => {
        finalizarTraslado();
      },
    },
    {
      label: "Activar",
      icon: "pi pi-check",
      command: () => {
        activar();
        timeOut();
      },
    },
    {
      label: "Cerrar",
      icon: "pi pi-times",
      command: () => {
        guardarServicioSeleccionado(null);
        setOpenDrawer(false);
        //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
      },
    },
  ];

  const itemsModel = [
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        //toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        //toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
      },
    },
    {
      label: "Upload",
      icon: "pi pi-upload",
      command: () => {
        window.location.hash = "/fileupload";
      },
    },
    {
      label: "Cerrar",
      icon: "pi pi-times",
      command: () => {
        guardarServicioSeleccionado(null);
        setOpenDrawer(false);
        //toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEmergencias = () => {
    setOpenModalEmergencia(true);
  };

  const handleCloseEmergencias = () => {
    setOpenModalEmergencia(false);
  };

  const handleOpenDerivados = () => {
    setOpenModalDerivado(true);
  };

  const handleCloseDerivados = () => {
    setOpenModalDerivado(false);
  };

  const handleCloseFuccia = () => {
    setOpenModalFuccia(false);
  };

  const handleCloseTrasladoServicio = () => {
    setOpenModalTrasladoServicio(false);
  };

  const handleCloseMedicamentos = () => {
    setOpenModalMedicamentos(false);
  };

  const handleCloseModificar = () => {
    guardarServicioModificado(null);
    setSelectedRow(null);
    setOpenModalModificar(false);
  };

  const handleCloseTrasladosServicios = () => {
    setSelectedRowTraslados(null);
    setVisibleTrasladosServicios(false);
  };

  const handleClick = () => {
    if (servicioSeleccionado.movilId >= 1000) {
      /*
      if (
        !cierreEmergencias ||
        cierreEmergencias.salidaDerivado === undefined ||
        cierreEmergencias.salidaDerivado === null ||
        cierreEmergencias.salidaDerivado === ""
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "La salida del móvil es necesaria !.",
          "error"
        );
        return;
      }
      if (
        !cierreEmergencias ||
        cierreEmergencias.llegadaDerivado === undefined ||
        cierreEmergencias.llegadaDerivado === null ||
        cierreEmergencias.llegadaDerivado === ""
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "La llegada del móvil es necesaria !.",
          "error"
        );
        return;
      }

      let a_date = servicioSeleccionado.pasado.substring(0, 10);
      let a_time = servicioSeleccionado.pasado.substring(11, 16);
      var newdate = a_date.split("/").reverse().join("-");
      let newDateTime = newdate + "T" + a_time;

      let pasado = newDateTime;
      let salida = cierreEmergencias.salidaDerivado;
      let llegada = cierreEmergencias.llegadaDerivado;

      salida = salida.replace(" ", "T");
      llegada = llegada.replace(" ", "T");

      if (pasado && salida && pasado >= salida) {
        mensajeAlerta(
          "Cierre de Servicio",
          "La salida del móvil debe ser posterior a pasado  !.",
          "error"
        );
        return;
      }

      if (salida && llegada && salida >= llegada) {
        mensajeAlerta(
          "Cierre de Servicio",
          "La llegada del móvil debe ser posterior a la salida  !.",
          "error"
        );
        return;
      }
      */

      if (
        !cierreEmergencias ||
        cierreEmergencias.finalDerivado === undefined ||
        cierreEmergencias.finalDerivado === null ||
        cierreEmergencias.finalDerivado === ""
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El final del servicio es necesario !.",
          "error"
        );
        return;
      }

      let a_date = servicioSeleccionado.llegada.substring(0, 10);
      let a_time = servicioSeleccionado.llegada.substring(11, 16);
      var newdate = a_date.split("/").reverse().join("-");
      let newDateTime = newdate + "T" + a_time;

      let llegada = newDateTime;
      let final = cierreEmergencias.finalDerivado;
      llegada = llegada.replace(" ", "T");

      let date = new Date();
      let actual = new Date(date);
      actual.setHours(date.getHours() - 3);
      actual = actual.toISOString().slice(0, 16);

      if (llegada && final && llegada >= final) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El final del servicio debe ser posterior a la llegada !.",
          "error"
        );
        return;
      }

      if (final && actual && final > actual) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El final del servicio no puede ser mayor que la hora actual !.",
          "error"
        );
        return;
      }
    }

    if (
      !cierreEmergencias ||
      cierreEmergencias.diagnosticoId === undefined ||
      cierreEmergencias.diagnosticoId === null ||
      cierreEmergencias.diagnosticoId <= 0
    ) {
      mensajeAlerta(
        "Cierre de Servicio",
        "El diagnóstico es necesario !.",
        "error"
      );
      return;
    }

    if (
      configuracion.destinatario !== 4 ||
      (configuracion.destinatario === 4 &&
        servicioSeleccionado.color !== "AZUL")
    ) {
      if (
        !cierreEmergencias ||
        cierreEmergencias.coseguroAbonado === undefined ||
        cierreEmergencias.coseguroAbonado === null ||
        cierreEmergencias.coseguroAbonado < 0
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El coseguro abonado es necesario !.",
          "error"
        );
        return;
      }
    }

    if (
      configuracion &&
      configuracion.pami === servicioSeleccionado.titularId &&
      cierreEmergencias &&
      (cierreEmergencias.cierreSoloMito === undefined ||
        !cierreEmergencias.cierreSoloMito) &&
      (servicioSeleccionado.color === "VERDE" ||
        servicioSeleccionado.color === "AMARILLO" ||
        servicioSeleccionado.color === "ROJO")
    ) {
      if (
        !cierreEmergencias ||
        cierreEmergencias.diagnosticoPamiId === undefined ||
        cierreEmergencias.diagnosticoPamiId === null ||
        cierreEmergencias.diagnosticoPamiId <= 0
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El diagnóstico de PAMI es necesario !.",
          "error"
        );
        return;
      }

      if (
        !cierreEmergencias ||
        cierreEmergencias.urgenciaPamiId === undefined ||
        cierreEmergencias.urgenciaPamiId === null
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "La Urgencia de PAMI es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !cierreEmergencias ||
        cierreEmergencias.destinoPamiId === undefined ||
        cierreEmergencias.destinoPamiId === null ||
        cierreEmergencias.destinoPamiId <= 0
      ) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El destino de PAMI es necesario !.",
          "error"
        );
        return;
      }
    }

    if (servicioSeleccionado.color === "FUCCIA") {
      setOpenModalFuccia(true);
      return;
    }

    if (
      configuracion &&
      configuracion.medicamentos &&
      servicioSeleccionado.movilId < 1000
    ) {
      handleMedicamentos();
      return;
    }
    handleServicio();
  };

  const handleMedicamentos = () => {
    if (servicioSeleccionado.color === "FUCCIA") {
      if (
        cierreFuccia &&
        cierreFuccia.trabaja &&
        cierreFuccia.fechaTrabaja === ""
      ) {
        mensajeAlerta("Despachos", "Debe ingresar fecha Trabaja !.", "error");
        return;
      }
      if (
        cierreFuccia &&
        cierreFuccia.citado &&
        cierreFuccia.fechaCitado === ""
      ) {
        mensajeAlerta("Despachos", "Debe ingresar fecha Citado !.", "error");
        return;
      }
    }

    if (
      configuracion &&
      configuracion.medicamentos &&
      servicioSeleccionado.movilId < 1000
    ) {
      if (
        servicioSeleccionado.almacenId === undefined ||
        servicioSeleccionado.almacenId === null
      ) {
        toast.current.show({
          severity: "info",
          summary:
            "El móvil " +
            servicioSeleccionado.movilId +
            " no tiene asignado un almacén",
        });
      }

      guardarCierreMedicamentos({
        cantidad: 1,
      });
      setOpenModalMedicamentos(true);
    } else {
      handleServicio();
    }
  };

  const handleTrasladoServicio = () => {
    let a_date = null;
    let a_time = null;
    let newDateTime = null;
    let lat = null;
    let lon = null;

    if (trasladosServicios.length === 0) {
      a_date = servicioSeleccionado.llegada.substring(0, 10);
      a_time = servicioSeleccionado.llegada.substring(11, 16);
      var newdate = a_date.split("/").reverse().join("-");
      newDateTime = newdate + "T" + a_time;
    } else {
      let index = trasladosServicios.length - 1;
      newDateTime = trasladosServicios[index].llegada;
      lat = trasladosServicios[index].lat;
      lon = trasladosServicios[index].lon;
    }

    let salidaTraslado = trasladoServicio.salida;
    let llegada = newDateTime;

    let latKm0 = -34.60955;
    let lonKm0 = -58.38882;

    let date = new Date();
    let actual = new Date(date);
    actual.setHours(date.getHours() - 3);
    actual = actual.toISOString().slice(0, 16);

    if (!salidaTraslado) {
      toast.current.show({
        severity: "warn",
        summary: "Salida de Traslado",
        detail: `Debe indicar la salida del traslado.`,
        life: 3000,
      });
      return;
    }

    if (salidaTraslado < llegada) {
      toast.current.show({
        severity: "warn",
        summary: "Salida de Traslado",
        detail: `La Salida del traslado no puede ser menor a la llegada a domicilio.`,
        life: 3000,
      });
      return;
    }

    if (salidaTraslado > actual) {
      toast.current.show({
        severity: "warn",
        summary: "Salida de Traslado",
        detail: `La Salida del traslado no puede ser mayor que la hora actual.`,
        life: 3000,
      });
      return;
    }

    if (
      !trasladoServicio ||
      trasladoServicio.destino === undefined ||
      trasladoServicio.destino === null ||
      trasladoServicio.destino === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El destino es necesario !.",
        life: 3000,
      });
      return;
    }

    if (
      !trasladoServicio ||
      trasladoServicio.destinoGeo === undefined ||
      trasladoServicio.destinoGeo === null ||
      trasladoServicio.destinoGeo === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El destino geo es necesario !.",
        life: 3000,
      });
      return;
    }

    if (
      !trasladoServicio ||
      trasladoServicio.espera === undefined ||
      trasladoServicio.espera === null ||
      trasladoServicio.espera === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La espera es necesaria !.",
        life: 3000,
      });
      return;
    }

    iniciarTrasladoServicio({
      servicioId: servicioSeleccionado.id,
      trasladoId:
        servicioSeleccionado && servicioSeleccionado.traslados
          ? servicioSeleccionado.traslados.id
          : null,
      usuario: state.usuario.usuario,
      //salida: trasladosServicios.salida,
      destino: trasladoServicio.destinoGeo
        ? trasladoServicio.destinoGeo
        : trasladoServicio.destino,
      descripcion: trasladoServicio.descripcion,
      lat: trasladoServicio.lat,
      lon: trasladoServicio.lon,
      distancia:
        trasladosServicios.length === 0 && configuracion.destinatario === 4
          ? Number(
              distanciaKm(
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon),
                Number(latKm0),
                Number(lonKm0)
              )
            ) +
            Number(
              distanciaKm(
                Number(trasladoServicio.lat),
                Number(trasladoServicio.lon),
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon)
              )
            )
          : trasladosServicios.length === 0 && configuracion.destinatario !== 4
          ? Number(
              distanciaKm(
                Number(trasladoServicio.lat),
                Number(trasladoServicio.lon),
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon)
              )
            )
          : trasladosServicios.length > 0
          ? Number(
              distanciaKm(
                Number(trasladoServicio.lat),
                Number(trasladoServicio.lon),
                Number(lat),
                Number(lon)
              )
            )
          : null,
      color: servicioSeleccionado.color,
      salidaTraslado,
      espera: trasladoServicio.espera,
      medicoRecibe: trasladoServicio.medicoRecibe,
      retorno: trasladoServicio.retorno,
    });
    setSelectedRow(null);
    handleCloseTrasladoServicio();
    guardarServicioSeleccionado(null);
  };

  function distanciaKm(lat1, lon1, lat2, lon2) {
    if (
      lat1 === null ||
      lon1 === null ||
      lat2 === null ||
      lon2 === null ||
      lat1 === 0 ||
      lon1 === 0 ||
      lat2 === 0 ||
      lon2 === 0 ||
      lat1 === "" ||
      lon1 === "" ||
      lat2 === "" ||
      lon2 === ""
    ) {
      return 0;
    }

    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres

    //console.log(d/1000)
    return parseFloat(d / 1000 * 1.2).toFixed(2);
  }

  Number.prototype.padLeft = function (base, chr) {
    var len = String(base || 10).length - String(this).length + 1;
    return len > 0 ? new Array(len).join(chr || "0") + this : this;
  };

  const handleServicio = () => {
    /*
    if (servicioSeleccionado.movilId >= 1000) {
      var d = new Date();
      let fin =
        [
          d.getFullYear(),
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
        ].join("-") +
        " " +
        [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":");

      let llegada = cierreEmergencias.llegadaDerivado;
      llegada = llegada.replace(" ", "T");
      if (llegada && fin && llegada >= fin) {
        mensajeAlerta(
          "Cierre de Servicio",
          "El cierre del móvil debe ser posterior a la llegada  !.",
          "error"
        );
        return;
      }
    }
    */

    handleCloseMedicamentos();
    handleCloseEmergencias();
    handleCloseDerivados();
    handleCloseFuccia();

    cerrarServicioEmergencia({
      cierreEmergencias,
      cierreFuccia,
      chipData,
      usuario: state.usuario.usuario,
      id: servicioSeleccionado.id,
      movilId: servicioSeleccionado.movilId,
      tripulacionId: servicioSeleccionado.tripulacionId,
      cierreHistoriaClinica: null,
      signature1: null,
      signature2: null,
      signature3: null,
      hc: configuracion.hc,
      final: true,
      app: false,
      almacenId: servicioSeleccionado.almacenId,
      destinatario: configuracion.destinatario,
      finalDerivado: cierreEmergencias.finalDerivado,
    });
  };

  const handleModificar = () => {
    if (
      !servicioModificado ||
      servicioModificado.titularId === undefined ||
      servicioModificado.titularId === null ||
      servicioModificado.titularId.trim() === "" ||
      servicioModificado.titularId.length !== 8
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El Nº de titular es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.nombreTitular === undefined ||
      servicioModificado.nombreTitular === null ||
      servicioModificado.nombreTitular.trim() === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El nombre de titular es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.numeroDocumento === undefined ||
      servicioModificado.numeroDocumento === null ||
      servicioModificado.numeroDocumento.trim() === "" ||
      servicioModificado.numeroDocumento.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El D.N.I es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.nombre === undefined ||
      servicioModificado.nombre === null ||
      servicioModificado.nombre.trim() === "" ||
      servicioModificado.nombre.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El apellido y nombre es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.telefono === undefined ||
      servicioModificado.telefono === null ||
      servicioModificado.telefono.trim() === "" ||
      servicioModificado.telefono.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El teléfono es necesario !.",
        life: 2000,
      });
      return;
    }
    if (
      !servicioModificado ||
      servicioModificado.domicilio === undefined ||
      servicioModificado.domicilio === null ||
      servicioModificado.domicilio.trim() === "" ||
      servicioModificado.domicilio.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El domicilio es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.localidad === undefined ||
      servicioModificado.localidad === null ||
      servicioModificado.localidad.trim() === "" ||
      servicioModificado.localidad.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La localidad es necesaria !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.edad === undefined ||
      servicioModificado.edad === null ||
      servicioModificado.edad === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La edad es necesaria !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.motivoConsultaNombre === undefined ||
      servicioModificado.motivoConsultaNombre === null ||
      servicioModificado.motivoConsultaNombre.trim() === "" ||
      servicioModificado.motivoConsultaNombre.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El motivo de consulta es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      servicioModificado &&
      servicioModificado.celular !== null &&
      servicioModificado.celular !== "" &&
      servicioModificado.celular.length !== 10
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El WhatsApp debe tener 10 digitos !.",
        life: 2000,
      });
      return;
    }

    if (
      servicioModificado &&
      servicioModificado.celular !== null &&
      servicioModificado.celular !== "" &&
      servicioModificado.celular.length !== 10
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El WhatsApp debe tener 10 digitos !.",
        life: 2000,
      });
      return;
    }

    if (
      servicioModificado &&
      servicioModificado.traslados &&
      !servicioModificado.tipoId
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Tipo de móvil es necesario !.",
        life: 2000,
      });
      return;
    }

    modificarServicio({
      servicioId: servicioSeleccionado.id,
      servicioModificado,
      usuario: state.usuario.usuario,
      coseguroAbonar,
      servicioAnterior: servicioSeleccionado,
    });
    handleCloseModificar();
  };

  const handleModificarTrasladosServicios = () => {
    if (!selectedRowTraslados.salida) {
      toast.current.show({
        severity: "warn",
        summary: "Salida de Traslado",
        detail: `Debe indicar la salida del traslado.`,
        life: 3000,
      });
      return;
    }

    if (!selectedRowTraslados.llegada) {
      toast.current.show({
        severity: "warn",
        summary: "Llegada de Traslado",
        detail: `Debe indicar la llegada del traslado.`,
        life: 3000,
      });
      return;
    }

    if (selectedRowTraslados.salida > selectedRowTraslados.llegada) {
      toast.current.show({
        severity: "warn",
        summary: "Salida de Traslado",
        detail: `La Salida del traslado no puede ser mayor que la llegada.`,
        life: 3000,
      });
      return;
    }

    if (
      !selectedRowTraslados ||
      selectedRowTraslados.destino === undefined ||
      selectedRowTraslados.destino === null ||
      selectedRowTraslados.destino === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El destino es necesario !.",
        life: 3000,
      });
      return;
    }

    if (
      !selectedRowTraslados ||
      selectedRowTraslados.distancia === undefined ||
      selectedRowTraslados.distancia === null ||
      selectedRowTraslados.distancia === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La distancia es necesaria !.",
        life: 3000,
      });
      return;
    }

    if (
      !selectedRowTraslados ||
      selectedRowTraslados.espera === undefined ||
      selectedRowTraslados.espera === null ||
      selectedRowTraslados.espera === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La espera es necesaria !.",
        life: 3000,
      });
      return;
    }

    modificarTrasladoServicio({
      id: servicioSeleccionado.id,
      servicioId: selectedRowTraslados.id,
      destino: selectedRowTraslados.destinoGeo
        ? selectedRowTraslados.destinoGeo
        : selectedRowTraslados.destino,
      lat: selectedRowTraslados.lat,
      lon: selectedRowTraslados.lon,
      distancia: selectedRowTraslados.distancia,
      salida: selectedRowTraslados.salida,
      llegada: selectedRowTraslados.llegada,
      espera: selectedRowTraslados.espera,
      medicoRecibe: selectedRowTraslados.medicoRecibe,
    });
    handleCloseTrasladosServicios();
    setTimeout(() => {
      obtenerTrasladosServicios({ servicioId: servicioSeleccionado.id });
    }, 1000);
  };

  const salida = () => {
    let tripula = null;
    let bandSalida = false;
    let salida = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < despachos.length; i++) {
        if (despachos[i].movilId === servicioSeleccionado.movilId) {
          if (despachos[i].estado.substring(0, 6) === "PASADO") {
            bandSalida = true;
          } else {
            bandSalida = false;
            break;
          }
        }
      }

      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.movilId === tripulaciones[i].movilId) {
          tripula = tripulaciones[i];
          break;
        }
      }

      if (bandSalida) {
        toastWarm.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Confirma salida del móvil ${tripula.movilId} al indicativo ${servicioSeleccionado.id} ?`}</h4>
                <p>{`Tripulacion : ${tripula.medico} - ${tripula.chofer} - ${tripula.enfermero}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) =>
                      opcionSiSalida(
                        tripula.id,
                        servicioSeleccionado.id,
                        servicioSeleccionado.pasado,
                        servicioSeleccionado.color,
                        servicioSeleccionado.movilId,
                      )
                    }
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Móviles",
          detail: `El móvil ${servicioSeleccionado.movilId} todavía esta asistiendo otro servicio.`,
          life: 3000,
        });
      }
    }

    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }

      if (tripula !== null) {
        toastWarm.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Confirma salida del móvil ${tripula.id} al indicativo ${servicioSeleccionado.id} ?`}</h4>

                <Grid item xs={6} md={12} className={classes.grid}>
                  <TextField
                    id="salida"
                    label="Salida"
                    //value={llegada}
                    fullWidth
                    type="datetime-local"
                    size="small"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //className={classes.textField}
                    //onKeyDown={handleEnter}
                    //value={coseguroAbonar}
                    onChange={(e) => (salida = e.target.value)}
                  />
                </Grid>

                <p>{`Tripulacion : ${tripula.nombre} - ${tripula.detalle}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) => {
                      let a_date = servicioSeleccionado.pasado.substring(0, 10);
                      let a_time = servicioSeleccionado.pasado.substring(
                        11,
                        16
                      );
                      var newdate = a_date.split("/").reverse().join("-");
                      let newDateTime = newdate + "T" + a_time;

                      let date = new Date();
                      let actual = new Date(date);
                      actual.setHours(date.getHours() - 3);
                      actual = actual.toISOString().slice(0, 16);

                      if (!salida) {
                        toast.current.show({
                          severity: "info",
                          summary: "Salida",
                          detail: `Debe indicar la salida del móvil.`,
                          life: 3000,
                        });
                        return;
                      }

                      if (salida < newDateTime) {
                        toast.current.show({
                          severity: "info",
                          summary: "Salida",
                          detail: `Salida no puede ser menor a pasado.`,
                          life: 3000,
                        });
                        return;
                      }

                      if (salida > actual) {
                        toast.current.show({
                          severity: "info",
                          summary: "Salida",
                          detail: `Salida no puede ser mayor que la hora actual.`,
                          life: 3000,
                        });
                        return;
                      }

                      opcionSiSalida(
                        tripula.id,
                        servicioSeleccionado.id,
                        servicioSeleccionado.pasado,
                        servicioSeleccionado.color,
                        servicioSeleccionado.movilId,
                        salida
                      );
                    }}
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Móviles",
          detail: `El móvil esta asistiendo otro servicio.`,
          life: 3000,
        });
      }
    }
  };

  const llegada = () => {
    let tripula = null;
    let llegada = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }

      if (tripula !== null) {
        toastWarm.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Confirma llegada del móvil ${tripula.movilId} al indicativo ${servicioSeleccionado.id} ?`}</h4>
                <p>{`Tripulacion : ${tripula.medico} - ${tripula.chofer} - ${tripula.enfermero}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) =>
                      opcionSiLlegada(
                        tripula.id,
                        servicioSeleccionado.id,
                        servicioSeleccionado.tomado,
                        0,
                        servicioSeleccionado.color
                      )
                    }
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      }
    }

    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }

      if (tripula !== null) {
        // Valorizacion del servicio

        let valorServicio = 0;

        if (valorizacion !== null) {
          let fueraZona = false;
          let nocturno = false;
          let pediatrico = false;
          let sabDomFer = false;

          if (servicioSeleccionado.fueraZona) {
            fueraZona = true;
          }

          if (
            tripula.edadPediatrico &&
            servicioSeleccionado.edad <= tripula.edadPediatrico
          ) {
            pediatrico = true;
          }

          var d = new Date(); // for now
          let day = d.getDate();
          let month = d.getMonth() + 1;
          let year = d.getFullYear();
          var h = today.getHours();
          var m = today.getMinutes();
          var s = today.getSeconds();

          //let datetext = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();

          if (d.getDay() === 0 || d.getDay() === 6) {
            sabDomFer = true;
          }

          for (var i = 0; i < feriados.length; i++) {
            let f = feriados[i].fecha;
            let df = formatDate(f);
            if (
              Number(day) === Number(df[2]) &&
              Number(month) === Number(df[1]) &&
              Number(year) === Number(df[0])
            ) {
              sabDomFer = true;
            }
          }

          let t1 = h + ":" + m + ":" + s;

          let nd = tripula.nocturnoDesde;
          let nh = tripula.nocturnoHasta;

          let str1 = t1.split(":");
          let str2 = nd.split(":");
          let str3 = nh.split(":");

          let totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[2]);
          let totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[2]);
          let totalSeconds3 = parseInt(str3[0] * 3600 + str3[1] * 60 + str3[2]);

          if (totalSeconds1 > totalSeconds2 || totalSeconds1 < totalSeconds3) {
            nocturno = true;
          }

          //Inicio Valorizacion

          //VERDE

          if (servicioSeleccionado.color === "VERDE") {
            if (pediatrico && nocturno && sabDomFer && fueraZona) {
              valorServicio = valorizacion.verdePNSdfFz
                ? valorizacion.verdePNSdfFz
                : 0;
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePNSdf
                  ? valorizacion.verdePNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePNFz
                  ? valorizacion.verdePNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePSdfFz
                  ? valorizacion.verdePSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePSdf
                  ? valorizacion.verdePSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePFz
                  ? valorizacion.verdePFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNSdfFz
                  ? valorizacion.verdeNSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNSdf
                  ? valorizacion.verdeNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNFz
                  ? valorizacion.verdeNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdfFz
                  ? valorizacion.verdeSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePNSdf
                  ? valorizacion.verdePNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePSdf
                  ? valorizacion.verdePSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNSdf
                  ? valorizacion.verdeNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePNFz
                  ? valorizacion.verdePNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePFz
                  ? valorizacion.verdePFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNFz
                  ? valorizacion.verdeNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && !nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePSdfFz
                  ? valorizacion.verdePSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePSdf
                  ? valorizacion.verdePSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePFz
                  ? valorizacion.verdePFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdfFz
                  ? valorizacion.verdeSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePSdf
                  ? valorizacion.verdePSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdePFz
                  ? valorizacion.verdePFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNSdfFz
                  ? valorizacion.verdeNSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNSdf
                  ? valorizacion.verdeNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNFz
                  ? valorizacion.verdeNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdfFz
                  ? valorizacion.verdeSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNSdf
                  ? valorizacion.verdeNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeNFz
                  ? valorizacion.verdeNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdfFz
                  ? valorizacion.verdeSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeSdf
                  ? valorizacion.verdeSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }

            if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.verde ? valorizacion.verde : 0;
              }
            }
          }

          //AMARILLO

          if (servicioSeleccionado.color === "AMARILLO") {
            if (pediatrico && nocturno && sabDomFer && fueraZona) {
              valorServicio = valorizacion.amarilloPNSdfFz
                ? valorizacion.amarilloPNSdfFz
                : 0;
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPNSdf
                  ? valorizacion.amarilloPNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPNFz
                  ? valorizacion.amarilloPNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPN
                  ? valorizacion.amarilloPN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPSdfFz
                  ? valorizacion.amarilloPSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPSdf
                  ? valorizacion.amarilloPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPFz
                  ? valorizacion.amarilloPFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNSdfFz
                  ? valorizacion.amarilloNSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNSdf
                  ? valorizacion.amarilloNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNFz
                  ? valorizacion.amarilloNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdfFz
                  ? valorizacion.amarilloSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPNSdf
                  ? valorizacion.amarilloPNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPN
                  ? valorizacion.amarilloPN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPSdf
                  ? valorizacion.amarilloPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNSdf
                  ? valorizacion.amarilloNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPNFz
                  ? valorizacion.amarilloPNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPN
                  ? valorizacion.amarilloPN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPFz
                  ? valorizacion.amarilloPFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNFz
                  ? valorizacion.amarilloNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPN
                  ? valorizacion.amarilloPN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && !nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPSdfFz
                  ? valorizacion.amarilloPSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPSdf
                  ? valorizacion.amarilloPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPFz
                  ? valorizacion.amarilloPFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdfFz
                  ? valorizacion.amarilloSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPSdf
                  ? valorizacion.amarilloPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloPFz
                  ? valorizacion.amarilloPFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloP
                  ? valorizacion.amarilloP
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNSdfFz
                  ? valorizacion.amarilloNSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNSdf
                  ? valorizacion.amarilloNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNFz
                  ? valorizacion.amarilloNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdfFz
                  ? valorizacion.amarilloSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNSdf
                  ? valorizacion.amarilloNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloNFz
                  ? valorizacion.amarilloNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloN
                  ? valorizacion.amarilloN
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdfFz
                  ? valorizacion.amarilloSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloSdf
                  ? valorizacion.amarilloSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarilloFz
                  ? valorizacion.amarilloFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }

            if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.amarillo
                  ? valorizacion.amarillo
                  : 0;
              }
            }
          }

          //ROJO

          if (servicioSeleccionado.color === "ROJO") {
            if (pediatrico && nocturno && sabDomFer && fueraZona) {
              valorServicio = valorizacion.rojoPNSdfFz
                ? valorizacion.rojoPNSdfFz
                : 0;
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPNSdf
                  ? valorizacion.rojoPNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPNFz
                  ? valorizacion.rojoPNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPSdfFz
                  ? valorizacion.rojoPSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPSdf
                  ? valorizacion.rojoPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNSdfFz
                  ? valorizacion.rojoNSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNSdf
                  ? valorizacion.rojoNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdfFz
                  ? valorizacion.rojoSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPNSdf
                  ? valorizacion.rojoPNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPSdf
                  ? valorizacion.rojoPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNSdf
                  ? valorizacion.rojoNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPNFz
                  ? valorizacion.rojoPNFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && !nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPSdfFz
                  ? valorizacion.rojoPSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPSdf
                  ? valorizacion.rojoPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdfFz
                  ? valorizacion.rojoSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPSdf
                  ? valorizacion.rojoPSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNSdfFz
                  ? valorizacion.rojoNSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNSdf
                  ? valorizacion.rojoNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdfFz
                  ? valorizacion.rojoSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNSdf
                  ? valorizacion.rojoNSdf
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdfFz
                  ? valorizacion.rojoSdfFz
                  : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
              }
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }

            if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
              if (valorServicio === 0) {
                valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
              }
            }
          }
        }

        //Fin Valorizacion

        toastWarm.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Confirma llegada del móvil ${tripula.id} al indicativo ${servicioSeleccionado.id} ?`}</h4>

                <Grid item xs={6} md={12} className={classes.grid}>
                  <TextField
                    id="llegada"
                    label="Llegada"
                    //value={llegada}
                    fullWidth
                    type="datetime-local"
                    size="small"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //className={classes.textField}
                    //onKeyDown={handleEnter}
                    //value={coseguroAbonar}
                    onChange={(e) => (llegada = e.target.value)}
                  />
                </Grid>

                <p>{`Tripulacion : ${tripula.nombre} - ${tripula.detalle}`}</p>
              </div>

              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) => {
                      let a_date = servicioSeleccionado.salida.substring(0, 10);
                      let a_time = servicioSeleccionado.salida.substring(
                        11,
                        16
                      );
                      var newdate = a_date.split("/").reverse().join("-");
                      let newDateTime = newdate + "T" + a_time;

                      let date = new Date();
                      let actual = new Date(date);
                      actual.setHours(date.getHours() - 3);
                      actual = actual.toISOString().slice(0, 16);

                      if (!llegada) {
                        toast.current.show({
                          severity: "info",
                          summary: "LLegada",
                          detail: `Debe indicar la llegada del móvil.`,
                          life: 3000,
                        });
                        return;
                      }

                      if (llegada <= newDateTime) {
                        toast.current.show({
                          severity: "info",
                          summary: "LLegada",
                          detail: `Llegada no puede ser menor o igual a salida.`,
                          life: 3000,
                        });
                        return;
                      }

                      if (llegada > actual) {
                        toast.current.show({
                          severity: "info",
                          summary: "LLegada",
                          detail: `Llegada no puede ser mayor que la hora actual.`,
                          life: 3000,
                        });
                        return;
                      }

                      opcionSiLlegada(
                        tripula.id,
                        servicioSeleccionado.id,
                        servicioSeleccionado.tomado,
                        valorServicio,
                        servicioSeleccionado.color,
                        llegada
                      );
                    }}
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={(e) => {
                      opcionNo();
                    }}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Móviles",
          detail: `El móvil esta asistiendo otro servicio.`,
          life: 3000,
        });
      }
    }
  };

  const trasladoNoConcretado = async () => {
    if (!servicioSeleccionado.traslados) {
      toast.current.show({
        severity: "error",
        summary: "No Concretado",
        detail: `No hay traslados iniciados.`,
        life: 3000,
      });
      return;
    }

    toastWarm.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Confirma como NO CONCRETADO el traslado del indicativo ${servicioSeleccionado.id} ?`}</h4>
          </div>
          <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => {
                  opcionSiNoConcretado(servicioSeleccionado.traslados.id);
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={opcionNo}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const finalizarTraslado = async () => {
    if (servicioSeleccionado.trasladoServicio === true && trasladoServicio) {
      let tripula = null;
      let llegada = null;

      if (servicioSeleccionado.movilId < 1000) {
        for (var i = 0; i < tripulaciones.length; i++) {
          if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
            tripula = tripulaciones[i];
            break;
          }
        }

        if (tripula !== null) {
          toastWarm.current.show({
            severity: "warn",
            sticky: true,
            content: (
              <div
                className="p-flex p-flex-column"
                style={{ flex: "1", marginLeft: "1em" }}
              >
                <div className="p-text-center">
                  <i
                    className="pi pi-exclamation-triangle"
                    style={{ fontSize: "3rem" }}
                  ></i>
                  <h4>{`Confirma Llegada del traslado a ${trasladoServicio.destino} con el móvil ${tripula.movilId} ?`}</h4>
                  <p>{`Tripulacion : ${tripula.medico} - ${tripula.chofer} - ${tripula.enfermero}`}</p>
                </div>
                <div className="p-grid p-fluid">
                  <div className="p-col-6">
                    <Button
                      type="button"
                      label="Si"
                      className="p-button-success"
                      onClick={(e) =>
                        opcionSiFinalizarTraslado(servicioSeleccionado)
                      }
                    />
                  </div>
                  <div className="p-col-6">
                    <Button
                      type="button"
                      label="No"
                      className="p-button-danger"
                      style={{ marginTop: "1em" }}
                      onClick={opcionNo}
                    />
                  </div>
                </div>
              </div>
            ),
          });
        }
      }
      if (servicioSeleccionado.movilId >= 1000) {
        for (var i = 0; i < movilesDerivados.length; i++) {
          if (
            servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
          ) {
            tripula = movilesDerivados[i];
            break;
          }
        }

        if (tripula !== null) {
          toastWarm.current.show({
            severity: "warn",
            sticky: true,
            content: (
              <div
                className="p-flex p-flex-column"
                style={{ flex: "1", marginLeft: "1em" }}
              >
                <div className="p-text-center">
                  <i
                    className="pi pi-exclamation-triangle"
                    style={{ fontSize: "3rem" }}
                  ></i>
                  <h4>{`Confirma llegada del traslado a ${trasladoServicio.destino} con el móvil ${tripula.id} ?`}</h4>

                  <Grid item xs={12} md={12} className={classes.grid}>
                    <TextField
                      id="llegada"
                      label="Llegada"
                      //value={llegada}
                      fullWidth
                      type="datetime-local"
                      size="small"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //className={classes.textField}
                      //onKeyDown={handleEnter}
                      //value={coseguroAbonar}
                      onChange={(e) => (llegada = e.target.value)}
                    />
                  </Grid>
                  <p>{`Tripulacion : ${tripula.nombre} - ${tripula.detalle}`}</p>
                </div>
                <div className="p-grid p-fluid">
                  <div className="p-col-6">
                    <Button
                      type="button"
                      label="Si"
                      className="p-button-success"
                      onClick={(e) => {
                        let a_date = trasladoServicio.salida.substring(0, 10);
                        let a_time = trasladoServicio.salida.substring(11, 16);
                        var newdate = a_date.split("/").reverse().join("-");
                        let newDateTime = newdate + "T" + a_time;

                        let date = new Date();
                        let actual = new Date(date);
                        actual.setHours(date.getHours() - 3);
                        actual = actual.toISOString().slice(0, 16);

                        if (!llegada) {
                          toast.current.show({
                            severity: "info",
                            summary: "LLegada",
                            detail: `Debe indicar la llegada del traslado.`,
                            life: 3000,
                          });
                          return;
                        }

                        if (llegada <= newDateTime) {
                          toast.current.show({
                            severity: "info",
                            summary: "LLegada",
                            detail: `Llegada del traslado no puede ser menor o igual a salida.`,
                            life: 3000,
                          });
                          return;
                        }

                        if (llegada > actual) {
                          toast.current.show({
                            severity: "info",
                            summary: "LLegada",
                            detail: `Llegada del traslado no puede ser mayor que la hora actual.`,
                            life: 3000,
                          });
                          return;
                        }

                        opcionSiFinalizarTraslado(
                          servicioSeleccionado,
                          llegada
                        );
                      }}
                    />
                  </div>
                  <div className="p-col-6">
                    <Button
                      type="button"
                      label="No"
                      className="p-button-danger"
                      style={{ marginTop: "1em" }}
                      onClick={opcionNo}
                    />
                  </div>
                </div>
              </div>
            ),
          });
        } else {
          toast.current.show({
            severity: "info",
            summary: "Móviles",
            detail: `El móvil esta asistiendo otro servicio.`,
            life: 3000,
          });
        }
      }
    }
  };

  const simultaneo = () => {
    let tripula = null;
    let cantidad = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma simultáneos del indicativo ${servicioSeleccionado.id} ?`}</h4>
              <Grid item xs={6} md={12} className={classes.grid}>
                <TextField
                  id="cantidad"
                  label="Cantidad"
                  fullWidth
                  variant="standard"
                  size="small"
                  type="number"
                  //className={classes.textField}
                  //onKeyDown={handleEnter}
                  //value={coseguroAbonar}
                  onChange={(e) => (cantidad = e.target.value)}
                />
              </Grid>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiSimultaneo(
                      servicioSeleccionado.id,
                      cantidad,
                      tripula,
                      servicioSeleccionado.movilId
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma simultáneo del indicativo ${servicioSeleccionado.id} ?`}</h4>
              <Grid item xs={6} md={12} className={classes.grid}>
                <TextField
                  id="cantidad"
                  label="Cantidad"
                  fullWidth
                  size="small"
                  type="number"
                  //className={classes.textField}
                  //onKeyDown={handleEnter}
                  //value={coseguroAbonar}
                  onChange={(e) => (cantidad = e.target.value)}
                />
              </Grid>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiSimultaneo(
                      servicioSeleccionado.id,
                      cantidad,
                      tripula,
                      tripula.id
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const apoyo = () => {
    let tripula = null;
    let cantidad = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma apoyo de móvil del indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiApoyo(
                      servicioSeleccionado.id,
                      tripula,
                      servicioSeleccionado.movilId
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma apoyo de móvil del indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiApoyo(servicioSeleccionado.id, tripula, tripula.id)
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const finalizar = () => {
    let tripula = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }

      //console.log(tripula)

      if (tripula !== null) {
        toastWarm.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Confirma cierre del indicativo ${servicioSeleccionado.id} con el móvil ${tripula.movilId} ?`}</h4>
                <p>{`Tripulacion : ${tripula.medico} - ${tripula.chofer} - ${tripula.enfermero}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) =>
                      opcionSiFinalizar(
                        tripula,
                        servicioSeleccionado,
                        servicioSeleccionado.movilId
                      )
                    }
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        if (!servicioSeleccionado.tripulacionId) {
          toast.current.show({
            severity: "info",
            summary: "No hay móvil asignado en el servicio.",
            life: 3000,
          });
          return;
        }

        const id = tripulaciones.find(
          (obj) => obj.id === servicioSeleccionado.tripulacionId
        ).servicioId;

        toast.current.show({
          severity: "info",
          summary:
            "El móvil " +
            servicioSeleccionado.movilId +
            " esta atendiendo el id " +
            id,
          life: 2500,
        });
      }
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }

      if (
        configuracion &&
        configuracion.pami === servicioSeleccionado.titularId &&
        servicioSeleccionado.estado !== "LLEGADA"
      ) {
        toast.current.show({
          severity: "info",
          summary: "Los servicios de PAMI hay que asignarlos en tiempo real.",
          life: 3000,
        });
        return;
      }

      if (tripula !== null) {
        toastWarm.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Confirma cierre del indicativo ${servicioSeleccionado.id} con el móvil ${tripula.id} ?`}</h4>
                <p>{`Móvil : ${tripula.nombre} - ${tripula.detalle}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) =>
                      opcionSiFinalizar(
                        tripula,
                        servicioSeleccionado,
                        tripula.id
                      )
                    }
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        const id = movilesDerivados.find(
          (obj) => obj.id === servicioSeleccionado.movilId
        ).derivadoServicioId;
        toast.current.show({
          severity: "info",
          summary:
            "El móvil " +
            servicioSeleccionado.movilId +
            " esta atendiendo el id " +
            id,
          life: 2500,
        });
      }
    }
  };

  const activar = () => {
    let tripula = null;
    let motivo = null;

    if (servicioSeleccionado.movilId < 1000) {
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma activar indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiActivar(
                      servicioSeleccionado.id,
                      servicioSeleccionado.tripulacionId,
                      servicioSeleccionado.movilId
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma activar indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiActivar(
                      servicioSeleccionado.id,
                      servicioSeleccionado.movilId,
                      servicioSeleccionado.movilId
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const anular = () => {
    let tripula = null;
    let motivo = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.movilId === tripulaciones[i].movilId) {
          tripula = tripulaciones[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma anulación del indicativo ${servicioSeleccionado.id} ?`}</h4>
              <Grid item xs={6} md={12} className={classes.grid}>
                <TextField
                  id="motivo"
                  label="Motivo"
                  fullWidth
                  size="small"
                  variant="standard"
                  //className={classes.textField}
                  //onKeyDown={handleEnter}
                  //value={coseguroAbonar}
                  onChange={(e) => (motivo = e.target.value)}
                />
              </Grid>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiAnular(
                      servicioSeleccionado.id,
                      motivo,
                      tripula,
                      servicioSeleccionado.movilId
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma anulación del indicativo ${servicioSeleccionado.id} ?`}</h4>
              <Grid item xs={6} md={12} className={classes.grid}>
                <TextField
                  id="motivo"
                  label="Motivo"
                  fullWidth
                  size="small"
                  //className={classes.textField}
                  //onKeyDown={handleEnter}
                  //value={coseguroAbonar}
                  onChange={(e) => (motivo = e.target.value)}
                />
              </Grid>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiAnular(
                      servicioSeleccionado.id,
                      motivo,
                      tripula,
                      tripula.id
                    )
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const modificar = () => {
    obtenerServicio({ servicioId: servicioSeleccionado.id });
    obtenerTrasladosServicios({ servicioId: servicioSeleccionado.id });
    setOpenModalModificar(true);
  };

  const motivoConsulta = () => {
    buscarTitular({ titularId: servicioSeleccionado.titularId });
    setOpenModalModificar(true);
  };

  const elevar = () => {
    let tripula = null;
    let motivo = null;

    if (
      servicioSeleccionado.color !== "VERDE" &&
      servicioSeleccionado.color !== "AMARILLO"
    ) {
      toast.current.show({
        severity: "info",
        summary: "Solo se pueden elevar servicios verdes y amarillos.",
        life: 2500,
      });
      return;
    }

    let color;
    if (servicioSeleccionado.color === "VERDE") {
      color = "AMARILLO";
    }
    if (servicioSeleccionado.color === "AMARILLO") {
      color = "ROJO";
    }

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma elevar el indicativo ${servicioSeleccionado.id} a color ${color} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiElevar(servicioSeleccionado.id, color)
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma elevar el indicativo ${servicioSeleccionado.id} a color ${color} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) =>
                    opcionSiElevar(servicioSeleccionado.id, color)
                  }
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const reclamar = () => {
    let tripula = null;
    let motivo = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma reclamo del indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => opcionSiReclamar(servicioSeleccionado.id)}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }
      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma reclamo del indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => opcionSiReclamar(servicioSeleccionado.id)}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const traslado = () => {
    guardarTrasladosServicios(null);
    setOpenModalTrasladoServicio(true);
  };

  const liberar = () => {
    let tripula = null;

    if (servicioSeleccionado.movilId < 1000) {
      for (var i = 0; i < tripulaciones.length; i++) {
        if (servicioSeleccionado.id === tripulaciones[i].servicioId) {
          tripula = tripulaciones[i];
          break;
        }
      }

      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma liberar el móvil ${servicioSeleccionado.movilId} del indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    if (tripula !== null) {
                      opcionSiLiberar(
                        servicioSeleccionado.id,
                        tripula.id,
                        servicioSeleccionado.movilId
                      );
                    } else {
                      opcionSiLiberar(
                        servicioSeleccionado.id,
                        null,
                        servicioSeleccionado.movilId
                      );
                    }
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
    if (servicioSeleccionado.movilId >= 1000) {
      for (var i = 0; i < movilesDerivados.length; i++) {
        if (
          servicioSeleccionado.id === movilesDerivados[i].derivadoServicioId
        ) {
          tripula = movilesDerivados[i];
          break;
        }
      }

      toastWarm.current.show({
        severity: "warn",
        sticky: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Confirma liberar el móvil ${servicioSeleccionado.movilId} del indicativo ${servicioSeleccionado.id} ?`}</h4>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="Si"
                  className="p-button-success"
                  onClick={(e) => {
                    if (tripula !== null) {
                      opcionSiLiberar(
                        servicioSeleccionado.id,
                        tripula.id,
                        tripula.id
                      );
                    } else {
                      opcionSiLiberar(
                        servicioSeleccionado.id,
                        null,
                        servicioSeleccionado.movilId
                      );
                    }
                  }}
                />
              </div>
              <div className="p-col-6">
                <Button
                  type="button"
                  label="No"
                  className="p-button-danger"
                  style={{ marginTop: "1em" }}
                  onClick={opcionNo}
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  const opcionSiSalida = (tripulacion, servicio, pasado, color, movilId, salida) => {
    salidaMovil({
      tripulacionId: tripulacion,
      servicioId: servicio,
      pasado: pasado,
      usuario: state.usuario.usuario,
      color,
      movilId,
      salida,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiLlegada = (
    tripulacion,
    servicio,
    tomado,
    valorServicio,
    color,
    llegada
  ) => {
    llegadaMovil({
      tripulacionId: tripulacion,
      servicioId: servicio,
      tomado: tomado,
      usuario: state.usuario.usuario,
      valorServicio,
      color,
      llegada,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiFinalizar = (tripulacion, servicio, movil) => {
    toastWarm.current.clear();
    //Inicializo Dialogs
    if (servicio.color === "FUCCIA") {
      guardarCierreFuccia({
        nsj: false,
        js: false,
        j: false,
        int: false,
        trabaja: false,
        fechaTrabaja: "",
        citado: false,
        fechaCitado: "",
      });
    } else {
      guardarCierreFuccia(null);
    }

    if (movil < 1000) {
      guardarCierreEmergencias(null);
    }
    if (movil >= 1000) {
      if (
        servicioSeleccionado.salida !== null ||
        servicioSeleccionado.llegada !== null
      ) {
        //console.log(servicioSeleccionado)

        guardarCierreEmergencias({
          ...cierreEmergencias,
          //salidaDerivado: formatDateTime(servicioSeleccionado.salida),
          //llegadaDerivado: formatDateTime(servicioSeleccionado.llegada),
          diagnosticoId: null,
          diagnosticoNombre: null,
          detalleDiagnostico: null,
          coseguroAbonado: "0",
        });
      } else {
        guardarCierreEmergencias(null);
      }
    }

    setChipData([]);
    if (movil < 1000) {
      handleOpenEmergencias();
    } else {
      handleOpenDerivados();
    }
    //guardarServicioSeleccionado(null);
  };

  const opcionSiAnular = (servicio, motivo, tripula, movil) => {
    if (motivo === null) {
      toast.current.show({
        severity: "error",
        summary: "Anular Servicio",
        detail: `Debe ingresar el motivo.`,
        life: 3000,
      });
      return;
    }
    anularServicio({
      servicioId: servicio,
      usuario: state.usuario.usuario,
      motivo: motivo,
      tripulacionId: tripula ? tripula.id : null,
      movilId: movil ? movil : null,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiSimultaneo = (servicio, cantidad, tripula, movil) => {
    if (cantidad === null || cantidad === 0) {
      toast.current.show({
        severity: "error",
        summary: "Simultáneo Servicio",
        detail: `Debe ingresar cantidad.`,
        life: 3000,
      });
      return;
    }
    simultaneoServicio({
      servicioId: servicio,
      usuario: state.usuario.usuario,
      cantidad: cantidad,
      tripulacionId: tripula ? tripula.id : null,
      movilId: movil ? movil : null,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiApoyo = (servicio, tripula, movil) => {
    apoyoServicio({
      servicioId: servicio,
      usuario: state.usuario.usuario,
      tripulacionId: tripula ? tripula.id : null,
      movilId: movil ? movil : null,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiActivar = (servicio, tripula, movil) => {
    activarServicio({
      servicioId: servicio,
      usuario: state.usuario.usuario,
      tripulacionId: tripula ? tripula : null,
      movilId: movil ? movil : null,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiElevar = (servicio, color) => {
    elevarServicio({
      servicioId: servicio,
      usuario: state.usuario.usuario,
      color: color,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiReclamar = (servicio) => {
    reclamarServicio({
      servicioId: servicio,
      usuario: state.usuario.usuario,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiFinalizarTraslado = (servicio, llegada) => {
    finalizarTrasladoServicio({
      servicioId: servicio.id,
      trasladoServicioId: trasladoServicio.id,
      destino: trasladoServicio.destino,
      usuario: state.usuario.usuario,
      color: servicio.color,
      llegada,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiNoConcretado = (servicio) => {
    trasladoServicioNoConcretado({
      servicioId: servicio,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  const opcionSiLiberar = (servicio, tripulacion, movil) => {
    liberarMovil({
      servicioId: servicio,
      usuario: state.usuario.usuario,
      tripulacionId: tripulacion,
      movilId: movil,
    });
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setSelectedRow(null);
  };

  function opcionNo() {
    toastWarm.current.clear();
    guardarServicioSeleccionado(null);
    setOpenDrawer(false);
  }

  function obtenerValorDerivado() {}

  const renderHeaderEmergencias = () => {
    return (
      <div>
        <Tag value="Cierre del Servicio"></Tag>
      </div>
    );
  };

  const renderFooterEmergencias = () => {
    return (
      <div>
        <Button
          label={
            servicioSeleccionado.color === "FUCCIA" ||
            (configuracion && configuracion.medicamentos)
              ? "Avanzar"
              : "Confirmar"
          }
          icon="pi pi-check"
          onClick={() => handleClick()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleCloseEmergencias()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderDerivados = () => {
    return (
      <div>
        <Tag value="Cierre del Servicio Derivado"></Tag>
      </div>
    );
  };

  const renderFooterDerivados = () => {
    return (
      <div>
        <Button
          label={
            servicioSeleccionado.color === "FUCCIA" ? "Avanzar" : "Confirmar"
          }
          icon="pi pi-check"
          onClick={() => handleClick()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleCloseDerivados()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderFuccia = () => {
    return (
      <div>
        <Tag value="Cierre Servicio Laboral"></Tag>
      </div>
    );
  };

  const renderFooterFuccia = () => {
    return (
      <div>
        <Button
          label={
            configuracion &&
            configuracion.medicamentos &&
            servicioSeleccionado.movilId < 1000
              ? "Avanzar"
              : "Confirmar"
          }
          icon="pi pi-check"
          onClick={() => handleMedicamentos()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleCloseFuccia()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderTrasladoServicio = () => {
    return (
      <div>
        <Tag value="Traslado en Servicio"></Tag>
      </div>
    );
  };

  const renderFooterTrasladoServicio = () => {
    return (
      <div>
        <Button
          label={"Confirmar"}
          icon="pi pi-check"
          onClick={() => handleTrasladoServicio()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleCloseTrasladoServicio()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderMedicamentos = () => {
    return (
      <div>
        <Tag value="Consumo de Medicamentos"></Tag>
      </div>
    );
  };

  const renderFooterMedicamentos = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleServicio()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => handleCloseMedicamentos()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderModificar = () => {
    return (
      <div>
        <Tag
          value={
            servicioModificado && servicioModificado.id
              ? "Modificaciones del Servicio " + servicioModificado.id
              : "Modificaciones del Servicio"
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterModificar = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleModificar()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => handleCloseModificar()}
          className="p-button-text"
        />
        <Button
          id="clickTitular"
          label="click"
          icon="pi pi-times"
          onClick={() => guardarTitular()}
          className="p-button-text"
          style={{ visibility: "hidden" }}
        />
      </div>
    );
  };

  const renderHeaderMapa = () => {
    return (
      <div>
        <Tag value="Ubicación en el Mapa"></Tag>
      </div>
    );
  };

  const renderFooterMapa = () => {
    return (
      <div>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleClose()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderTrasladosServicios = () => {
    return (
      <div>
        <Tag value="Módificacion del Traslado"></Tag>
      </div>
    );
  };

  const renderFooterTrasladosServicios = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleModificarTrasladosServicios()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Salir"
          icon="pi pi-times"
          onClick={() => handleCloseTrasladosServicios()}
          className="p-button-text"
        />
      </div>
    );
  };

  const displayCoseguro = (value, grupo) => {
    //console.log(afiliado, value)
    let index = motivosConsultas.findIndex((item) => item.id === value);
    let servicioId = motivosConsultas[index].servicioId;
    let servicioNombre = motivosConsultas[index].nombre;

    let afiliado = {
      grupo_id: grupo,
    };

    obtenerCoseguroServicio({
      servicio: servicioId,
      afiliado: afiliado,
      titular: titular,
      nombre: servicioNombre,
    });
  };

  function formatDateTime(dateString) {
    var allDate = dateString;
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("/");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("-");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "titularId":
          campo = document.getElementById("dni");
          campo.focus();
          break;
        case "dni":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "nombre":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("coseguro");
          campo.focus();
          break;
        case "coseguro":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("celular");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("videollamada");
          campo.focus();
          break;
        case "videollamada":
          campo = document.getElementById("observaciones");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "dni":
          campo = document.getElementById("titularId");
          campo.focus();
          break;
        case "nombre":
          campo = document.getElementById("dni");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("coseguro");
          campo.focus();
          break;
        case "coseguro":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
        case "videollamada":
          campo = document.getElementById("celular");
          campo.focus();
          break;
        case "observaciones":
          campo = document.getElementById("videollamada");
          campo.focus();
          break;
      }
    }
  }

  function clearLocalidad() {
    guardarServicioModificado({
      ...servicioModificado,
      localidad: "",
    });
  }

  const handleInputBlur = (event) => {
    if (event.relatedTarget.id === "provincia") {
      return;
    }

    /*
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    setTimeout(() => {
      close.click();
    }, 100);
    */

    clearLocalidad();
    obtenerDirecciones({
      domicilio: servicioModificado.domicilio.toLowerCase(),
      provincia: servicioModificado.provincia.toLowerCase(),
    });
  };

  const handleTitularBlur = (event) => {
    const tit = event.target.value.padStart(8, 0);
    buscarTitular({ titularId: tit });
    setTimeout(() => {
      let elem = document.getElementById("clickTitular");
      elem.click();
    }, 1000);
  };

  const guardarTitular = () => {
    guardarServicioModificado({
      ...servicioModificado,
      titularId: titular.id_titular ? titular.id_titular : null,
      nombreTitular: titular.nombre_razonsocial
        ? titular.nombre_razonsocial
        : null,
      afiliadoIdTitular: titular.id ? titular.id : null,
      grupoId: titular.id_grupo ? titular.id_grupo : null,
      planId: titular.id_plan_facturacion ? titular.id_plan_facturacion : null,
    });
  };

  function PopupExample(e) {
    if (
      !servicioModificado ||
      servicioModificado.lat === undefined ||
      servicioModificado.lat === null ||
      servicioModificado.lon === undefined ||
      servicioModificado.lon === null
    ) {
      return;
    }

    let position = [];
    position.push(servicioModificado.lat);
    position.push(servicioModificado.lon);

    return (
      <MapContainer
        center={position}
        zoom={15}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}></Marker>
      </MapContainer>
    );
  }

  const handleInputBlurDestino = (event) => {
    obtenerDirecciones({
      domicilio:
        servicioModificado.destino !== undefined
          ? servicioModificado.destino.toLowerCase()
          : "",
      provincia:
        servicioModificado.provincia !== undefined
          ? servicioModificado.provincia.toLowerCase()
          : "",
    });
  };

  const handleInputBlurDestinoTraslado = (event) => {
    obtenerDirecciones({
      domicilio:
        selectedRowTraslados.destino !== undefined
          ? selectedRowTraslados.destino.toLowerCase()
          : "",
      provincia:
        selectedRowTraslados.provincia !== undefined
          ? selectedRowTraslados.provincia.toLowerCase()
          : "",
    });
  };

  const salidaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Salida</span>
        {formatDateTime(rowData.salida)}
      </Fragment>
    );
  };

  const llegadaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {formatDateTime(rowData.llegada)}
      </Fragment>
    );
  };

  const distanciaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Distancia</span>
        {rowData.distancia}
      </Fragment>
    );
  };

  const esperaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Espera min.</span>
        {rowData.espera}
      </Fragment>
    );
  };
  const medicoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Médico</span>
        {rowData.medicoRecibe}
      </Fragment>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const editTrasladoServicio = (data) => {
    let a_date = data.salida.substring(0, 10);
    let a_time = data.salida.substring(11, 16);
    var newdate = a_date.split("/").reverse().join("-");
    let newDateSalida = newdate + "T" + a_time;

    a_date = data.llegada.substring(0, 10);
    a_time = data.llegada.substring(11, 16);
    newdate = a_date.split("/").reverse().join("-");
    let newDateLlegada = newdate + "T" + a_time;

    setSelectedRowTraslados({
      id: data.id,
      salida: newDateSalida,
      llegada: newDateLlegada,
      destino: data.destino,
      distancia: data.distancia,
      medicoRecibe: data.medicoRecibe,
      retorno: false,
      provincia: "Buenos Aires",
      lat: data.lat,
      lon: data.lon,
      espera: data.espera,
    });
  };

  const handleClickCalcularDistancia = () => {
    let index = trasladosServicios.findIndex(
      (element) => element.id === selectedRowTraslados.id
    );

    let lat1 = null;
    let lon1 = null;
    let lat2 = null;
    let lon2 = null;
    let latKm0 = -34.60955;
    let lonKm0 = -58.38882;

    if (index === 0) {
      lat1 = selectedRowTraslados.lat;
      lon1 = selectedRowTraslados.lon;
      lat2 = servicioSeleccionado.lat;
      lon2 = servicioSeleccionado.lon;
    } else {
      lat1 = selectedRowTraslados.lat;
      lon1 = selectedRowTraslados.lon;
      lat2 = trasladosServicios[index - 1].lat;
      lon2 = trasladosServicios[index - 1].lon;
    }

    setSelectedRowTraslados({
      ...selectedRowTraslados,
      distancia:
        index === 0 && configuracion.destinatario === 4
          ? Number(
              distanciaKm(
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon),
                Number(latKm0),
                Number(lonKm0)
              )
            ) +
            Number(
              distanciaKm(
                Number(lat1),
                Number(lon1),
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon)
              )
            )
          : index === 0 && configuracion.destinatario !== 4
          ? Number(
              distanciaKm(
                Number(lat1),
                Number(lon1),
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon)
              )
            )
          : index > 0
          ? Number(
              distanciaKm(
                Number(lat1),
                Number(lon1),
                Number(lat2),
                Number(lon2)
              )
            )
          : null,
    });
  };

  //console.log(trasladosServicios);

  return (
    <Root className={classes.root}>
      <Fragment>
        {tripulaciones &&
        servicioSeleccionado &&
        motivosConsultas &&
        colores ? (
          <Fragment>
            <CssBaseline />
            <Toast ref={toast} position="top-right" />
            <Toast ref={toastWarm} position="top-center" />
            <div
              className={
                desde === "despachos"
                  ? "speeddial-tooltip-demo speeddial spedddial"
                  : "speeddial-tooltip-demo speeddialmapa spedddial"
              }
            >
              <Tooltip
                target=".speeddial-tooltip-demo .speeddial-left .p-speeddial-action"
                className="tooltip-speeddial-despachos"
              />
              <SpeedDial
                model={
                  servicioSeleccionado.estado.substring(0, 6) === "TOMADO"
                    ? itemsTomado
                    : servicioSeleccionado.estado.substring(0, 6) === "PASADO"
                    ? itemsPasado
                    : servicioSeleccionado.estado.substring(0, 6) === "SALIDA"
                    ? itemsSalida
                    : servicioSeleccionado.estado.substring(0, 7) === "LLEGADA"
                    ? itemsLlegada
                    : servicioSeleccionado.estado.substring(0, 8) === "TRASLADO"
                    ? itemsTraslado
                    : null
                }
                direction="down"
                buttonClassName="p-button-success"
                style={{
                  zIndex: 10000,
                  float: "right",
                  position: "inherit",
                  marginRight: ".5em",
                  marginLeft: ".5em",
                }}
                className="speeddial-left"
              />
            </div>
            <div className="dialog-demo">
              <div className="card">
                <Dialog
                  header={renderHeaderEmergencias()}
                  visible={openModalEmergencia}
                  closable={false}
                  footer={renderFooterEmergencias()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="left"
                >
                  <CierreEmergencias />

                  <br />
                </Dialog>
                <Dialog
                  header={renderHeaderFuccia()}
                  visible={openModalFuccia}
                  closable={false}
                  footer={renderFooterFuccia()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="bottom"
                >
                  <CierreFuccia />

                  <br />
                </Dialog>
                <Dialog
                  header={renderHeaderMedicamentos()}
                  visible={openModalMedicamentos}
                  closable={false}
                  footer={renderFooterMedicamentos()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="right"
                >
                  <ConsumoMedicamentos />

                  <br />
                </Dialog>
                <Dialog
                  header={renderHeaderDerivados()}
                  visible={openModalDerivado}
                  closable={false}
                  footer={renderFooterDerivados()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="left"
                >
                  <CierreEmergencias />
                  <br />
                </Dialog>
                <Dialog
                  header={renderHeaderTrasladoServicio()}
                  visible={openModalTrasladoServicio}
                  closable={false}
                  footer={renderFooterTrasladoServicio()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="top"
                >
                  <TrasladosServicios />
                  <br />
                </Dialog>

                <Dialog
                  header={renderHeaderModificar()}
                  visible={openModalModificar}
                  closable={false}
                  footer={renderFooterModificar()}
                  breakpoints={{ "960px": "95vw" }}
                  style={{ width: "95vw", paddingBottom: "0" }}
                  position="center"
                >
                  <Grid
                    className={classes.root}
                    container
                    spacing={1}
                    style={{ padding: "2vw" }}
                  >
                    {servicioModificado && trasladosServicios ? (
                      <Fragment>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="titularId"
                            label="Titular Afiliado"
                            value={
                              servicioModificado
                                ? servicioModificado.titularId
                                : null
                            }
                            required
                            fullWidth
                            variant="standard"
                            onBlur={(e) => handleTitularBlur(e)}
                            size="small"
                            className={classes.textField}
                            //disabled={afiliadoCartera ? true : false}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                titularId: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="nombreTitular"
                            label="Nombre Titular"
                            value={
                              servicioModificado
                                ? servicioModificado.nombreTitular
                                : null
                            }
                            required
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            //disabled={afiliadoCartera ? true : false}
                            onKeyDown={handleEnter}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <NumericFormat
                            {...props}
                            id="dni"
                            label="D.N.I."
                            customInput={TextField}
                            format="########"
                            type="numeric"
                            value={
                              servicioModificado
                                ? servicioModificado.numeroDocumento
                                : null
                            }
                            required
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            //disabled={afiliadoCartera ? true : false}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                numeroDocumento: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="nombre"
                            label="Apellido y Nombre"
                            value={
                              servicioModificado
                                ? servicioModificado.nombre
                                : null
                            }
                            required
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            //disabled={afiliadoCartera ? true : false}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                nombre: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <NumericFormat
                            {...props}
                            id="telefono"
                            label="Teléfono"
                            customInput={TextField}
                            format="####################"
                            type="numeric"
                            value={
                              servicioModificado
                                ? servicioModificado.telefono
                                : null
                            }
                            required
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                telefono: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <TextField
                            id="provincia"
                            label="Provincia"
                            value={
                              servicioModificado
                                ? servicioModificado.provincia
                                : null
                            }
                            required
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                provincia: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <TextField
                            id="domicilio"
                            label="Dirección"
                            value={
                              servicioModificado
                                ? servicioModificado.domicilio
                                : null
                            }
                            fullWidth
                            variant="standard"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onBlur={(e) => handleInputBlur(e)}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                domicilio: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            id="domicilioGeo"
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={direcciones}
                            getOptionLabel={(option) => option.label}
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Dirección Geolocalizada"
                                variant="standard"
                              />
                            )}
                            onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                guardarServicioModificado({
                                  ...servicioModificado,
                                  domicilioGeo: value.label,
                                  localidadGeo: value.localidad.toUpperCase(),
                                  localidad:
                                    value.localidad !== ""
                                      ? value.localidad.toUpperCase() +
                                        "-" +
                                        value.provincia.toUpperCase()
                                      : value.provincia.toUpperCase(),
                                  lat: value.lat,
                                  lon: value.lon,
                                });
                              } else {
                                guardarServicioModificado({
                                  ...servicioModificado,
                                  domicilioGeo: null,
                                  localidadGeo: null,
                                  lat: null,
                                  lon: null,
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={4} md={1}>
                          <IconButton
                            type="submit"
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={(e) => handleClickOpen()}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <TextField
                            id="entre"
                            label="Entre calles"
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                entreCalles: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            id="localidad"
                            label="Localidad"
                            value={
                              servicioModificado
                                ? servicioModificado.localidad
                                : null
                            }
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            //disabled={afiliadoCartera ? true : false}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                localidad: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={1}
                          style={{ marginTop: "0.25em" }}
                        >
                          <NumericFormat
                            {...props}
                            id="edad"
                            label="Edad"
                            customInput={TextField}
                            format="###"
                            type="numeric"
                            value={
                              servicioModificado
                                ? servicioModificado.edad
                                : null
                            }
                            fullWidth
                            variant="standard"
                            size="small"
                            required
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                edad: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={9}>
                          <Autocomplete
                            id="motivoConsulta"
                            fullWidth
                            required
                            disablePortal
                            debug
                            noOptionsText={"No hay opciones"}
                            options={opcionesMotivos}
                            //classes={classes }
                            defaultValue={
                              servicioModificado &&
                              servicioModificado.motivoConsultaId !== undefined
                                ? motivosConsultas[
                                    motivosConsultas.findIndex(
                                      (item) =>
                                        item.id ===
                                        servicioModificado.motivoConsultaId
                                    )
                                  ]
                                : null
                            }
                            getOptionLabel={(option) => option.nombre}
                            renderOption={(props, option) => {
                              const { nombre, codColor } = option;
                              return (
                                <li
                                  {...props}
                                  style={{
                                    backgroundColor: codColor,
                                    color:
                                      codColor === "#FFFF00" ||
                                      codColor === "#FFFFFF" ||
                                      codColor === "#00FFFF"
                                        ? "#000000"
                                        : "#FFFFFF",
                                  }}
                                >
                                  {nombre}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Motivo de consulta"
                                variant="standard"
                              />
                            )}
                            onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                //console.log(value);
                                guardarServicioModificado({
                                  ...servicioModificado,
                                  motivoConsultaId: value.id,
                                  motivoConsultaNombre: value.nombre,
                                  motivoConsultaCodigoColor: value.codColor,
                                  motivoConsultaColor: value.color,
                                });
                                guardarCoseguroServicio(null);
                                //displayCoseguro(value.id);
                              } else {
                                guardarServicioModificado({
                                  ...servicioModificado,
                                  motivoConsultaId: null,
                                  motivoConsultaNombre: null,
                                  motivoConsultaCodigoColor: null,
                                  motivoConsultaColor: null,
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} md={1} className={classes.grid}>
                          <Button
                            size="normal"
                            variant="outlined"
                            style={{
                              marginTop: "0.5em",
                              backgroundColor:
                                servicioModificado.motivoConsultaCodigoColor
                                  ? servicioModificado.motivoConsultaCodigoColor
                                  : "#FFF",
                              color:
                                servicioModificado.motivoConsultaColor ===
                                  "AMARILLO" ||
                                servicioModificado.motivoConsultaColor ===
                                  "CELESTE" ||
                                servicioModificado.motivoConsultaColor ===
                                  "BLANCO"
                                  ? "#000000"
                                  : "#FFFFFF",
                            }}
                          >
                            {servicioModificado &&
                            servicioModificado.motivoConsultaColor !== null
                              ? servicioModificado.motivoConsultaColor
                              : "Color"}
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="operadorEmisor"
                            label="Operador emisor"
                            fullWidth
                            variant="standard"
                            size="small"
                            value={
                              servicioModificado
                                ? servicioModificado.operadorEmisor
                                : null
                            }
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                operadorEmisor: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="indicativoEmisor"
                            label="Indicativo emisor"
                            fullWidth
                            variant="standard"
                            size="small"
                            value={
                              servicioModificado
                                ? servicioModificado.indicativoEmisor
                                : null
                            }
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                indicativoEmisor: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="obraSocial"
                            label="Obra social/Convenio"
                            fullWidth
                            variant="standard"
                            size="small"
                            value={
                              servicioModificado
                                ? servicioModificado.obraSocial
                                : null
                            }
                            className={classes.textField}
                            InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                obraSocial: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={6} md={3}>
                          <TextField
                            id="nroObraSocial"
                            label="Nro. Obra social/Convenio"
                            fullWidth
                            variant="standard"
                            size="small"
                            value={
                              servicioModificado
                                ? servicioModificado.nroObraSocial
                                : null
                            }
                            className={classes.textField}
                            InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                nroObraSocial: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={6} md={2}>
                          <TextField
                            id="coseguro"
                            label="Coseguros"
                            fullWidth
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            onKeyDown={handleEnter}
                            value={
                              servicioModificado
                                ? servicioModificado.coseguroAbonar
                                : null
                            }
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                coseguroAbonar: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={1} style={{ paddingLeft: 0 }}>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                id="covid"
                                color="primary"
                                checked={
                                  servicioModificado
                                    ? servicioModificado.covid
                                    : false
                                }
                                onKeyDown={handleEnter}
                                onChange={(e) =>
                                  guardarServicioModificado({
                                    ...servicioModificado,
                                    covid: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Covid"
                            labelPlacement="start"
                          />
                        </Grid>

                        <Grid item xs={12} md={2} style={{ paddingLeft: 0 }}>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                id="fueraZona"
                                color="primary"
                                checked={
                                  servicioModificado
                                    ? servicioModificado.fueraZona
                                    : false
                                }
                                onKeyDown={handleEnter}
                                onChange={(e) =>
                                  guardarServicioModificado({
                                    ...servicioModificado,
                                    fueraZona: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Fuera de Zona"
                            labelPlacement="start"
                          />
                        </Grid>

                        <Grid item xs={12} md={2} style={{ paddingLeft: 0 }}>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                id="videollamada"
                                color="primary"
                                checked={
                                  servicioModificado
                                    ? servicioModificado.videollamada
                                    : false
                                }
                                onKeyDown={handleEnter}
                                onChange={(e) =>
                                  guardarServicioModificado({
                                    ...servicioModificado,
                                    videollamada: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Videollamada"
                            labelPlacement="start"
                          />
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <TextField
                            id="celular"
                            label="WhatsApp Videollamada"
                            value={
                              servicioModificado
                                ? servicioModificado.celular
                                : null
                            }
                            required
                            fullWidth
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                celular: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                id="rederivado"
                                color="primary"
                                checked={
                                  afiliado
                                    ? servicioModificado.rederivado
                                    : false
                                }
                                onKeyDown={handleEnter}
                                onChange={(e) =>
                                  guardarServicioModificado({
                                    ...servicioModificado,
                                    rederivado: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Rederivado"
                            labelPlacement="start"
                          />
                        </Grid>

                        <Grid item xs={12} md={1.5}>
                          <TextField
                            id="indicativoRederivado"
                            label="Ind. Rederivado"
                            value={
                              servicioModificado
                                ? servicioModificado.indicativoRederivado
                                : null
                            }
                            fullWidth
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              guardarServicioModificado({
                                ...servicioModificado,
                                indicativoRederivado: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        {servicioModificado &&
                        servicioModificado.traslados !== undefined &&
                        servicioModificado.traslados !== null ? (
                          <Fragment>
                            <Grid item xs={12} md={12} />

                            <div>
                              <Tag value="Datos del Traslado"></Tag>
                            </div>

                            <Grid item xs={12} md={12} />

                            <Grid item xs={12} md={5}>
                              <TextField
                                id="destinoAzul"
                                label="Destino"
                                value={
                                  servicioModificado
                                    ? servicioModificado.destino
                                    : null
                                }
                                fullWidth
                                variant="standard"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                //className={classes.textField}
                                onKeyDown={handleEnter}
                                onBlur={(e) => handleInputBlurDestino(e)}
                                onChange={(e) =>
                                  guardarServicioModificado({
                                    ...servicioModificado,
                                    destino: e.target.value,
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Autocomplete
                                id="destinoGeoAzul"
                                fullWidth
                                disablePortal
                                noOptionsText={"No hay opciones"}
                                options={direcciones}
                                getOptionLabel={(option) => option.label}
                                openOnFocus={true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Destino Geolocalizado"
                                    variant="standard"
                                  />
                                )}
                                //onKeyDown={handleEnter}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarServicioModificado({
                                      ...servicioModificado,
                                      destinoGeo: value.label,
                                      lat: value.lat,
                                      lon: value.lon,
                                    });
                                  } else {
                                    guardarServicioModificado({
                                      ...servicioModificado,
                                      destinoGeo: null,
                                      lat: null,
                                      lon: null,
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={4} md={1}>
                              <IconButton
                                type="submit"
                                //className={classes.iconButton}
                                aria-label="search"
                                onClick={(e) => handleClickOpen()}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Grid>

                            <div className="dialog-demo">
                              <Dialog
                                header={renderHeaderMapa()}
                                visible={open}
                                footer={renderFooterMapa()}
                                closable={false}
                                style={{ zIndex: 5000 }}
                              >
                                {PopupExample()}
                              </Dialog>
                            </div>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ marginTop: ".5em", marginBottom: "1em" }}
                            >
                              <Autocomplete
                                id="tiposMoviles"
                                fullWidth
                                disablePortal
                                required
                                options={tiposMoviles}
                                //onKeyDown={handleEnter}
                                //inputValue={tiposMoviles[1].nombre}
                                value={
                                  servicioModificado &&
                                  servicioModificado.tipoId
                                    ? tiposMoviles[
                                        tiposMoviles.findIndex(
                                          (tipo) =>
                                            tipo.id ===
                                            servicioModificado.tipoId
                                        )
                                      ]
                                    : null
                                }
                                //defaultValue={tiposMoviles[1]}
                                getOptionLabel={(option) => option.nombre}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Tipo de móvil"
                                    variant="standard"
                                  />
                                )}
                                //onKeyDown={handleEnter}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarServicioModificado({
                                      ...servicioModificado,
                                      tipoId: value.id,
                                    });
                                  } else {
                                    guardarServicioModificado({
                                      ...servicioModificado,
                                      tipoId: null,
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={6} md={2} style={{ paddingLeft: 0 }}>
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Checkbox
                                    id="retorno"
                                    color="primary"
                                    checked={
                                      servicioModificado
                                        ? servicioModificado.retorno
                                        : false
                                    }
                                    onKeyDown={handleEnter}
                                    onChange={(e) =>
                                      guardarServicioModificado({
                                        ...servicioModificado,
                                        retorno: e.target.checked,
                                      })
                                    }
                                  />
                                }
                                label="Retorno"
                                labelPlacement="start"
                              />
                            </Grid>

                            <Grid item xs={6} md={2} style={{ paddingLeft: 0 }}>
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Checkbox
                                    id="noconcretado"
                                    color="primary"
                                    checked={
                                      servicioModificado
                                        ? servicioModificado.noconcretado
                                        : false
                                    }
                                    onKeyDown={handleEnter}
                                    onChange={(e) =>
                                      guardarServicioModificado({
                                        ...servicioModificado,
                                        noconcretado: e.target.checked,
                                      })
                                    }
                                  />
                                }
                                label="No Concretado"
                                labelPlacement="start"
                              />
                            </Grid>

                            <Grid item xs={12} md={12} />

                            <div>
                              <Tag value="Traslados del Servicio"></Tag>
                            </div>

                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                align: "center",
                                marginTop: "0em",
                                paddingTop: "0em",
                              }}
                            >
                              <div className="datatable-responsive-demo pdatatable">
                                <div className="card">
                                  <DataTable
                                    value={trasladosServicios}
                                    selection={selectedRowTraslados}
                                    onSelectionChange={(e) => {
                                      editTrasladoServicio(e.value);
                                      setVisibleTrasladosServicios(true);
                                    }}
                                    selectionMode="single"
                                    showGridlines
                                    dataKey="id"
                                    //header={header}
                                    className="p-datatable-sm p-datatable-responsive-demo pdatatable"
                                    //tableStyle={{ fontSize: "xx-small", marginTop: "4.5vw" }}
                                    //globalFilter={globalFilter}
                                    emptyMessage="No hay datos."
                                    paginator
                                    size="small"
                                    //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={100}
                                    rowsPerPageOptions={[10, 20]}
                                    //paginatorLeft={paginatorLeft}
                                    //paginatorRight={paginatorRight}
                                  >
                                    <Column
                                      field="id"
                                      header="ID"
                                      style={{ display: "none" }}
                                    ></Column>
                                    <Column
                                      field="destino"
                                      header="Destino"
                                      //className="colColor"
                                      //body={colorBodyTemplate}
                                    ></Column>
                                    <Column
                                      field="salida"
                                      header="Salida"
                                      //className="colHorarios"
                                      body={salidaBodyTemplate}
                                    ></Column>
                                    <Column
                                      field="llegada"
                                      header="Llegada"
                                      //className="colHorarios"
                                      body={llegadaBodyTemplate}
                                    ></Column>
                                    <Column
                                      field="distancia"
                                      header="Distancia Km."
                                      //className="colHorarios"
                                      body={distanciaBodyTemplate}
                                    ></Column>
                                    <Column
                                      field="espera"
                                      header="Espera Min."
                                      //className="colHorarios"
                                      body={esperaBodyTemplate}
                                    ></Column>
                                    <Column
                                      field="medicoRecibe"
                                      header="Médico"
                                      //className="colHorarios"
                                      body={medicoBodyTemplate}
                                    ></Column>
                                  </DataTable>
                                </div>
                              </div>
                            </Grid>
                          </Fragment>
                        ) : null}
                      </Fragment>
                    ) : null}
                  </Grid>
                  <br />
                </Dialog>
              </div>
            </div>
            <div className="dialog-demo">
              <div className="card">
                <Dialog
                  header={renderHeaderMapa()}
                  visible={open}
                  footer={renderFooterMapa()}
                  baseZIndex={5000}
                  closable={false}
                >
                  {PopupExample()}

                  <br />
                </Dialog>
              </div>
            </div>

            <Dialog
              header={renderHeaderTrasladosServicios()}
              visible={visibleTrasladosServicios}
              footer={renderFooterTrasladosServicios()}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "50vw", paddingBottom: "0" }}
              closable={false}
            >
              {selectedRowTraslados ? (
                <Grid
                  container
                  spacing={3}
                  style={{ padding: "0", marginTop: "1vw" }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="salida"
                      label="Salida"
                      type="datetime-local"
                      //noOptionsText={"No hay opciones"}
                      value={
                        selectedRowTraslados
                          ? selectedRowTraslados.salida
                          : null
                      }
                      className={classes.textField}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          salida: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="llegada"
                      label="Llegada"
                      type="datetime-local"
                      //noOptionsText={"No hay opciones"}
                      value={
                        selectedRowTraslados
                          ? selectedRowTraslados.llegada
                          : null
                      }
                      className={classes.textField}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          llegada: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="provincia"
                      label="Provincia"
                      value={
                        selectedRowTraslados
                          ? selectedRowTraslados.provincia
                          : "Buenos Aires"
                      }
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      className={classes.textField}
                      onKeyDown={handleEnter}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          provincia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="destino"
                      label="Destino"
                      value={
                        selectedRowTraslados
                          ? selectedRowTraslados.destino
                          : null
                      }
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textField}
                      onKeyDown={handleEnter}
                      onBlur={(e) => handleInputBlurDestinoTraslado(e)}
                      onChange={(e) =>
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          destino: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <Autocomplete
                      id="destinoGeo"
                      fullWidth
                      disablePortal
                      noOptionsText={"No hay opciones"}
                      options={direcciones}
                      getOptionLabel={(option) => option.label}
                      openOnFocus={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Destino Geolocalizado"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          setSelectedRowTraslados({
                            ...selectedRowTraslados,
                            destinoGeo: value.label,
                            lat: value.lat,
                            lon: value.lon,
                          });
                        } else {
                          setSelectedRowTraslados({
                            ...selectedRowTraslados,
                            destinoGeo: null,
                            lat: null,
                            lon: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} md={1}>
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={(e) => handleClickOpen()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Grid>

                  <div className="dialog-demo">
                    <Dialog
                      header={renderHeaderMapa()}
                      visible={open}
                      footer={renderFooterMapa()}
                      closable={false}
                    >
                      {PopupExample()}
                    </Dialog>
                  </div>

                  <Grid item xs={12} md={4}>
                    <NumericFormat
                      {...props}
                      id="distancia"
                      label="Distancia en Km."
                      customInput={TextField}
                      format="###"
                      type="numeric"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        selectedRowTraslados
                          ? selectedRowTraslados.distancia
                          : 0
                      }
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          distancia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={4} md={1}>
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={(e) => handleClickCalcularDistancia()}
                    >
                      <CalculateIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <NumericFormat
                      {...props}
                      id="espera"
                      label="Espera en minutos"
                      customInput={TextField}
                      format="###"
                      type="numeric"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        selectedRowTraslados ? selectedRowTraslados.espera : 0
                      }
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          espera: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <TextField
                      id="medicoRecibe"
                      label="Médico Recibe"
                      value={
                        selectedRowTraslados
                          ? selectedRowTraslados.medicoRecibe
                          : null
                      }
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textField}
                      onKeyDown={handleEnter}
                      //onBlur={(e) => handleInputBlur(e)}
                      onChange={(e) =>
                        setSelectedRowTraslados({
                          ...selectedRowTraslados,
                          medicoRecibe: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}></Grid>
                </Grid>
              ) : null}
            </Dialog>

            {/*
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openModalEmergencia}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModalEmergencia}>
                <Grid container spacing={2} style={{ padding: "0" }}>
                  <Grid item xs={12} md={6} className={classes.grid}>
                    <div className={classes.paper}>
                      <Typography component="h2" variant="h6" align="center" style={{ color: "#3F51B5" }}>
                        Cierre del servicio
                      </Typography>
                      <CierreEmergencias />
                    </div>
                  </Grid>
                </Grid>
              </Fade>
            </Modal>
          </div>
            */}
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Fragment>
    </Root>
  );
}
