import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "../Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputMask } from "primereact/inputmask";

export default function ProcesosPami(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleProceso, setVisibleProceso] = useState(true);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [repetirPeriodo, setRepetirPeriodo] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [valor, guardarValor] = useState("cuota");
  const [fijo, setFijo] = useState(null);
  const toast = useRef(null);
  const acCobrador = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    statusAcceso,
    listadoExcel,
    empresas,
    configuracion,
    obtenerAcceso,
    tiposComprobantes,
    obtenerModulos,
    obtenerCobradores,
    obtenerEmpresas,
    obtenerTiposComprobantes,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const { procesos, procesoPami } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ProcesosPami",
      });
    }
  }, [state.id]);

  useEffect(() => {
    if (configuracion && configuracion.pami) {
      guardarProceso({
        titularId: configuracion.pami,
      });
    }
  }, [configuracion]);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
    exitClick();
  };

  const aceptarProceso = async (e) => {
    e.preventDefault();
    //Validar

    if (configuracion && !configuracion.pami) {
      mensajeAlerta(
        "Procesos",
        "No hay asociado un afiliado de PAMI !.",
        "error"
      );
      return;
    }

    if (
      !proceso ||
      proceso.periodo === undefined ||
      proceso.periodo === null ||
      proceso.periodo === 0 ||
      proceso.periodo === ""
    ) {
      mensajeAlerta("Procesos", "El período es necesario !.", "error");
      return;
    }
    confirm1();
  };

  const confirm1 = () => {
    confirmDialog({
      message: "Esta seguro de iniciar el proceso de exportación",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      accept: accept1,
    });
  };

  const accept1 = async () => {
    await procesoPami({
      proceso,
      usuario: state.usuario.usuario,
    }).then((data) => {
      //console.log(procesos);
      let elem = document.getElementById("generarProceso");
      elem.click();
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Exportación de PAMI",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag value="Exportar PAMI"></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarProceso}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateReves(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return year + month + day;
  }

  function formatDateIso(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + ";" + newTime;
  }

  const generar = async () => {
    if (!procesos || procesos.length === 0) {
      return;
    }

    const doc = new jsPDF();

    /*
    var blob = new Blob([], { type: "text/plain;charset=utf-8" });
    for (var i = 0; i < 10; i++) {
      blob = new Blob([blob, " " + i], { type: "text/plain" });
    }

    saveAs(blob, "hello_world.txt");

    return;
    */

    let cuitEmpresa = "30-62504744-3";
    let periodo =
      proceso.periodo.substring(0, 2) + "-" + proceso.periodo.substring(3, 5);

    let blobArchivo = new Blob([], { type: "text/plain;charset=utf-8" });

    blobArchivo = new Blob([blobArchivo, "CABECERA" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [
        blobArchivo,
        cuitEmpresa +
        ";;;" +
        periodo +
        ";GERSHUNDAIT;2;UP30625047443;66479" +
        "\n",
      ],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "RED" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [
        blobArchivo,
        cuitEmpresa +
        ";;;0;EMSR;GERSHUNDAIT;0;HIPÓLITO YRIGOYEN;4881;;;;01142252800" +
        "\n",
      ],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "PROFESIONAL" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [
        blobArchivo,
        ";;;0;MIGUEL A.LENNARD;10;58587;220999;DNI;11757346;;HIPÓLITO YRIGOYEN;4881;;;;" +
        "\n",
      ],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "PRESTADOR" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [
        blobArchivo,
        ";30-62504744-3;;;0;;;2;;0;directorio@emersur.com;03/03/2020;;;;0;0;0;GERSHUNDAIT;HIPÓLITO YRIGOYEN;4881;;;;01142252800;" +
        "\n",
      ],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob(
      [blobArchivo, "REL_PROFESIONALESXPRESTADOR" + "\n"],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob(
      [blobArchivo, ";" + cuitEmpresa + ";58587;0;0;" + "\n"],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "BOCA_ATENCION" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [
        blobArchivo,
        ";" +
        cuitEmpresa +
        ";;0;2;10;HIPÓLITO YRIGOYEN;4881;;;;01142252800" +
        "\n",
      ],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "REL_MODULOSXPRESTADOR" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [blobArchivo, ";" + cuitEmpresa + ";;0;40;" + "\n"],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "REL_PRESTADORESXRED" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob(
      [blobArchivo, cuitEmpresa + ";30-62504744-3;;0;0;" + "\n"],
      { type: "text/plain;charset=utf-8" }
    );

    blobArchivo = new Blob([blobArchivo, "BENEFICIO" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    for (var i = 0; i < procesos.length; i++) {
      if (procesos[i].beneficiariospami) {
        blobArchivo = new Blob(
          [
            blobArchivo,
            ";;;" +
            (procesos[i].nroObraSocial.length === 14
              ? procesos[i].nroObraSocial.substring(0, 12)
              : procesos[i].nroObraSocial.length === 13
                ? "0" + procesos[i].nroObraSocial.substring(0, 11)
                : "") +
            ";;;1;01/01/1900" +
            "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      } else {
        blobArchivo = new Blob(
          [
            blobArchivo,
            ";;;" +
            (procesos[i].nroObraSocial ? procesos[i].nroObraSocial : "") +
            " ID Servicio: " +
            procesos[i].id +
            " NO ENCONTRADO EN PADRON" +
            "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      }
    }

    let afil = [];
    blobArchivo = new Blob([blobArchivo, "AFILIADO" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    for (var i = 0; i < procesos.length; i++) {
      if (procesos[i].beneficiariospami) {
        const index = afil.findIndex(
          (item) => item === procesos[i].beneficiariospami.beneficiario
        );

        if (index === -1) {
          afil.push(procesos[i].beneficiariospami.beneficiario);

          blobArchivo = new Blob(
            [
              blobArchivo,
              procesos[i].beneficiariospami.nombre.trim() +
              ";" +
              procesos[i].beneficiariospami.tipodoc +
              ";" +
              procesos[i].beneficiariospami.numerodoc +
              ";;;;;;;;;;" +
              procesos[i].beneficiariospami.fechanac +
              ";" +
              procesos[i].beneficiariospami.sexo +
              ";;;" +
              (procesos[i].nroObraSocial.length === 14
                ? procesos[i].nroObraSocial.substring(0, 12) +
                ";" +
                procesos[i].nroObraSocial.substring(12, 14)
                : procesos[i].nroObraSocial.length === 13
                  ? "0" +
                  procesos[i].nroObraSocial.substring(0, 11) +
                  ";" +
                  procesos[i].nroObraSocial.substring(11, 13)
                  : "") +
              ";;;;;;;;" +
              "\n",
            ],
            { type: "text/plain;charset=utf-8" }
          );
        }
      } else {
        blobArchivo = new Blob(
          [
            blobArchivo,
            ";;;" +
            (procesos[i].nroObraSocial ? procesos[i].nroObraSocial : "") +
            " ID Servicio: " +
            procesos[i].id +
            " NO ENCONTRADO EN PADRON" +
            "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      }
    }

    blobArchivo = new Blob([blobArchivo, "PRESTACIONES" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    blobArchivo = new Blob([blobArchivo, "TRASLADO" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    for (var i = 0; i < procesos.length; i++) {

      if (procesos[i].beneficiariospami) {
        blobArchivo = new Blob(
          [
            blobArchivo,
            cuitEmpresa +
            ";;;0;0;0;2;" +
            (procesos[i].nroObraSocial.length === 14
              ? procesos[i].nroObraSocial.substring(0, 12) +
              ";" +
              procesos[i].nroObraSocial.substring(12, 14)
              : procesos[i].nroObraSocial.length === 13
                ? "0" +
                procesos[i].nroObraSocial.substring(0, 11) +
                ";" +
                procesos[i].nroObraSocial.substring(11, 13)
                : "") +
            ";" +
            formatDateIso(procesos[i].fpasado) +
            ";" +
            formatDateIso(procesos[i].fsalida) +
            ";" +
            formatDateIso(procesos[i].fllegada) +
            ";1;" +
            procesos[i].domicilio.substring(0, 60) +
            ";1;" +
            (procesos[i].color === "VERDE"
              ? "444444"
              : procesos[i].color === "AMARILLO"
                ? "440105"
                : procesos[i].color === "ROJO"
                  ? "440101"
                  : procesos[i].color === "AZUL"
                    ? "440203"
                    : "") +
            ";" +
            formatDateIso(procesos[i].ffin) +
            ";" +
            (procesos[i].color !== "AZUL" ? "1" : "4") +
            ";" +
            (procesos[i].color !== "AZUL"
              ? procesos[i].domicilio.substring(0, 10) + ";0,00"
              : procesos[i].trasladosserviciospami &&
                procesos[i].trasladosserviciospami.length > 0
                ? procesos[i].trasladosserviciospami[0].destino.substring(
                  0,
                  10
                ) +
                ";" +
                (procesos[i].trasladosserviciospami[0].distancia < 1000
                  ? procesos[i].trasladosserviciospami[0].distancia
                  : "0,00")
                : procesos[i].domicilio.substring(0, 10) + ";0,00") +
            ";" +
            (procesos[i].diagnosticos &&
              procesos[i].diagnosticos.estadopami &&
              procesos[i].diagnosticos.estadopami.toUpperCase() === "M"
              ? "muerto"
              : "vivo") +
            ";1;" +
            (procesos[i].diagnosticos && procesos[i].diagnosticos.diagnosticopami
              ? procesos[i].diagnosticos.diagnosticopami.trim()
              : "Z51") +
            ";;;1;" +
            "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      } else {
        blobArchivo = new Blob(
          [
            blobArchivo,
            ";;;" +
            (procesos[i].nroObraSocial ? procesos[i].nroObraSocial : "") +
            " ID Servicio: " +
            procesos[i].id +
            " NO ENCONTRADO EN PADRON" +
            "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      }
    }
    blobArchivo = new Blob([blobArchivo, "FIN TRASLADO" + "\n"], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blobArchivo, "Pami_" + periodo);
  };

  //console.log(procesos);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderProceso()}
            visible={visibleProceso}
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={4}>
                <label htmlFor="periodo">Período</label>
                <InputMask
                  id="periodo"
                  mask="99/99"
                  value={proceso ? proceso.periodo : null}
                  placeholder="99/99"
                  size={4}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      periodo: e.value,
                    })
                  }
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                ></InputMask>
              </Grid>
              <button
                id="generarProceso"
                type="button"
                onClick={(e) => {
                  generar();
                }}
                style={{ display: "none" }}
              >
                Generar Proceso
              </button>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}

      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
